import React, {
    // InputHTMLAttributes,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import ReactInputMask, { Props as MaskInputProps } from 'react-input-mask';
import { Container, InputContainer, Error } from './styles';

interface InputProps extends MaskInputProps {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    hideLabel?: boolean;
}

const MaskInput: React.FC<InputProps> = ({
    name,
    icon: Icon,
    placeholder,
    readOnly = false,
    hideLabel = false,
    ...props
}) => {
    const inputRef = useRef<any>(null);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleChange = useCallback((e: any) => {
        setInputValue(e.target.value.replace(/_/gi, ''));
    }, []);

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);
    return (
        <Container>
            {!hideLabel && <label htmlFor={name}>{placeholder}</label>}
            <InputContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
                isDisabled={readOnly}
            >
                {Icon && <Icon size={20} />}

                <ReactInputMask
                    value={inputValue}
                    onChange={handleChange}
                    ref={inputRef}
                    id={name}
                    {...props}
                    maskChar=""
                    readOnly={readOnly}
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                />
            </InputContainer>

            {error && (
                <Error>
                    <FiAlertCircle color="#c53030" size={18} />
                    <span>{error}</span>
                </Error>
            )}
        </Container>
    );
};

export default MaskInput;
