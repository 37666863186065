import React, {
    InputHTMLAttributes,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, InputContainer, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    hideLabel?: boolean;
}

const Input: React.FC<InputProps> = ({
    name,
    icon: Icon,
    placeholder,
    hideLabel = false,
    readOnly = false,
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [inputValue, setInputValue] = useState();

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    const handleValue = useCallback((v) => {
        const regexDate = new RegExp(
            '^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([0-9][0-9])[:]([0-9][0-9])[:]([0-9][0-9])Z{0,1}$',
        );
        if (regexDate.test(v)) {
            return v.split('T')[0];
        }
        return v;
    }, []);

    return (
        <Container>
            {!hideLabel && <label htmlFor={name}>{placeholder}</label>}

            <InputContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
                isDisabled={false}
            >
                {Icon && <Icon size={20} />}
                {defaultValue !== '' ? (
                    <input
                        defaultValue={handleValue(inputValue)}
                        ref={inputRef}
                        id={name}
                        {...props}
                        placeholder={hideLabel ? placeholder : ''}
                        readOnly={readOnly}
                        onFocus={handleInputFocused}
                        onBlur={handleInputBlur}
                    />
                ) : (
                    <input
                        ref={inputRef}
                        id={name}
                        {...props}
                        placeholder={hideLabel ? placeholder : ''}
                        readOnly={readOnly}
                        onFocus={handleInputFocused}
                        onBlur={handleInputBlur}
                    />
                )}
            </InputContainer>

            {error && (
                <Error>
                    {props.type !== 'date' && (
                        <FiAlertCircle color="#c53030" size={18} />
                    )}
                    <span>{error}</span>
                </Error>
            )}
        </Container>
    );
};

export default Input;
