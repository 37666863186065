import styled from 'styled-components';

export const Container = styled.div`

  ul.pagination li a {
    color: #007a51;
  }
  
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #007a51;
  }

  .page-item.active .page-link {
    background-color: #007a51;
    border-color: #007a51;
  }
  
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: #007a51;
  }
  
  .page-selection {
    color: #007a51;
  }

  `;