import styled, { css } from 'styled-components';
import BSContainer from 'react-bootstrap/Container';
import BSCard from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import BSImage from 'react-bootstrap/Image';
import { colors } from '../../styles/variables';

import Button from '../../components/Button';

export const Container = styled(BSContainer)`
    margin-top: 24px;
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const List = styled(Table)`
    td, th {
        padding: .50rem;
        vertical-align: center !important;
        border-top: 1px solid #dee2e6;
    }
`;

export const Header = styled.thead``;

export const GridItem = styled(Col)`
    .inner {
        box-shadow: 2px 2px 5px #00000090;
        display: flex;
        flex-direction: column;
        min-height: 150px;
    }
`;
export const Content = styled.tbody``;

export const Image = styled(BSImage)`
    max-height: 100px;
    min-height: 100px;
    align-self: center;
`;

export const Title = styled.h5`
    padding: 8px 24px;
    color: ${colors.light};
    background: ${colors.primary};
`;

export const Description = styled.p`
    margin: 16px 24px 8px;
    min-height: 64px;
`;
export const Options = styled.div`
    display: flex;
`;

export const OptionButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & + & {
        margin-left: 8px;
    }

    svg {
        margin-right: 8px;
    }
`;

interface TagProps {
    open: boolean;
}
export const Tag = styled.span<TagProps>`
    border-radius: 4px;
    padding: 8px;
    color: ${colors.light};
    ${(props) =>
        props.open
            ? css`
                  background: ${colors.success};
              `
            : css`
                  background: ${colors.danger};
              `}
    position: absolute;
    right: 24px;
    top: 24px;
`;
