import React, { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiTrash, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';
import Swal from 'sweetalert2';

import { colors } from '../../styles/variables';

import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';

interface ProfissaoProps {
    id: number;
    nome: string;
    descricao: string;
}

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/profissoes',
        active: true,
    },
];

const Profissao: React.FC = () => {
    const [profissoes, setProfissoes] = useState<ProfissaoProps[]>(
        [] as ProfissaoProps[],
    );

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const { addToast } = useToast();
    const getProfissoes = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api.get(`/api/v1/painel/profissao`);
            const { data } = response;
            console.log(data);
            setProfissoes(data.data);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    'Não conseguimos exibir as telas, tente novamente!',
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    useEffect(() => {
        getProfissoes();
    }, [getProfissoes]);

    const handleDeleteProfissao = useCallback((idProfissao) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Deseja realmente realizar esta operação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Fechar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/api/v1/painel/profissao/${idProfissao}`);
                    getProfissoes();
                    addToast({
                        type: 'success',
                        title: 'Operação realizada com sucesso!',
                    });
                } catch {
                    addToast({
                        type: 'error',
                        title: 'Ooops! Algo deu errado!',
                        description: 'Erro ao excluir a profissão',
                    });
                }
            }
        });
    }, [addToast]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            {loading ? (
                <Loading />
            ) : (
                <Card>
                    <Card.Header>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col md={10}>
                                    <h5>Listagem de Profissões</h5>
                                </Col>
                                <Col md={2}>
                                    <Options>
                                        <OptionButton
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() =>
                                                history.push('/profissoes/new')
                                            }
                                        >
                                            <FiPlus color={colors.light} />
                                            Adicionar
                                        </OptionButton>
                                    </Options>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <List responsive>
                            <Header>
                                <tr>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Opções</th>
                                </tr>
                            </Header>
                            <Content>
                                {profissoes.map((profissao) => (
                                    <tr key={profissao.id}>
                                        <td>{profissao.nome}</td>
                                        <td>{profissao.descricao}</td>
                                        <td style={{ width: '24%' }}>
                                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Options>
                                                        <OptionButton
                                                            type="button"
                                                            color={colors.primary}
                                                            textcolor={colors.light}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/profissoes/${profissao.id}/edit`,
                                                                )
                                                            }
                                                        >
                                                            <FiEdit
                                                                color={colors.light}
                                                            />
                                                            Editar
                                                        </OptionButton>
                                                    </Options>
                                                </div>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.danger}
                                                        textcolor={colors.light}
                                                        onClick={() => handleDeleteProfissao(profissao.id)}
                                                    >
                                                        <FiTrash
                                                            color={colors.light}
                                                        />
                                                        Excluir

                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

export default Profissao;
