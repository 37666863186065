import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FiPlus, FiEdit } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import IVagas from '../../interfaces/IVagas';
import Breadcrumb from '../../components/Breadcrumb';
import Input from '../../components/Input';
import Button from '../../components/Button';
import PaginationComponent from '../../components/PaginationComponent';
import PaginationProps from '../../interfaces/PaginationProps';
import { colors } from '../../styles/variables';
import { Container, Card, CheckBox } from '../../styles/components';
import Select, { SelectProps } from '../../components/SelectInput';
import AsyncReactSelectInput from '../../components/AsyncReactSelect';
import { List } from './styles';
import ReactSelectInput from '../../components/ReactSelect';
import { MdCancel } from 'react-icons/md';
import Swal from 'sweetalert2';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/vagas',
        active: true,
    },
];

interface PreloaProps {
    beneficiarioId: number;
    beneficiarioNome: string;
}

interface PreloaPropsProfi {
    id: number;
    nome: string;
}

const Vagas: React.FC = () => {
    const { addToast } = useToast();
    const history = useHistory();
    const [vagas, setVagas] = useState<IVagas[]>([]);
    const [faturas, setFaturas] = useState<number[]>([]);
    const [profissoes, setProfissoes] = useState<SelectProps[]>([]);
    const [paginationData, setPaginationData] = useState<PaginationProps>();
    const [sendFaturas, setSendFaturas] = useState(false);

    const [codigoAux, setCodigoAux] = useState('');
    const [tituloAux, setTituloAux] = useState('');
    const [profissaoAux, setProfissaoAux] = useState('');
    const [dtInicioAux, setDtInicioAux] = useState('');
    const [dtFimAux, setDtFimAux] = useState('');
    const [beneficiarioAux, setBeneficiarioAux] = useState('');
    const [unidadeAux, setUnidadeAux] = useState('');

    const [unidades, setUnidades] = useState<SelectProps[]>([]);

    const handleSendFaturas = useCallback(() => {
        if (faturas.length === 0) {
            addToast({
                type: 'error',
                title: 'Selecione pelo menos uma vaga!',
            });
            return;
        }
        setSendFaturas(true);
    }, [faturas, addToast]);

    const handleGetVagas = useCallback(
        async (
            page = 1,
            event = { unidade: unidadeAux, codigo: codigoAux, titulo: tituloAux, profissao: profissaoAux, dtInicio: dtInicioAux, dtFim: dtFimAux, beneficiario: beneficiarioAux },
        ) => {
            try {
                const { unidade, codigo, titulo, profissao, dtInicio, dtFim, beneficiario } = event;

                setUnidadeAux(unidade);
                setCodigoAux(codigo);
                setTituloAux(titulo);
                setProfissaoAux(profissao);
                setDtInicioAux(dtInicio);
                setDtFimAux(dtFim);
                setBeneficiarioAux(beneficiario);

                console.log(event);
                const { data } = await api.get('/api/v1/painel/vaga', {
                    params: {
                        unidade,
                        codigo,
                        titulo,
                        profissao,
                        dtInicio,
                        dtFim,
                        beneficiario,
                        pagina: page,
                    },
                });
                setVagas(data.data.vagas);
                setPaginationData({
                    page: data.data.paginaAtual,
                    perPage: data.data.quantidadeTotalPagina,
                    total: data.data.quantidadeTotal,
                    lastPage:
                        data.data.quantidadeTotal /
                        data.data.quantidadeTotalPagina,
                });
            } catch {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: 'Erro ao bsucar dados',
                });
            }
        },
        [addToast, unidadeAux, codigoAux, tituloAux, profissaoAux, dtInicioAux, dtFimAux, beneficiarioAux],
    );

    const handleSetFaturas = useCallback(
        (e: number) => {
            const findFatura = faturas.findIndex((fatura) => fatura === e);
            if (findFatura < 0) {
                setFaturas((old) => [...old, e]);
            } else {
                setFaturas((old) => old.filter((fatura) => fatura !== e));
            }
        },
        [faturas],
    );

    const handleEnviarFaturamento = useCallback(async ({ dataVencimento }) => {
        try {
            if (faturas.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Selecione pelo menos uma vaga!',
                });
                return;
            }
            await api.post(`/api/v1/painel/faturamento/`, {
                vagasIds: faturas,
                dataVencimento,
            });
            addToast({
                type: 'success',
                title: 'Fatura gerada com sucesso!',
            });
            setSendFaturas(false);
            handleGetVagas();
        } catch (err) {

            const { errors } = err.response.data;

            errors.forEach((err: string) => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: err,
                });
            });


        }
    }, [addToast, faturas, handleGetVagas]);

    const handleGetProfissoes = useCallback(async () => {
        try {
            const { data } = await api.get(`/api/v1/painel/profissao`);
            setProfissoes(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    const handleGetUnidades = useCallback(async () => {
        try {
            const { data } = await api.get(`api/v1/painel/unidade/usuario`);
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );

            setUnidadeAux("teste");
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    useEffect(() => {

        api.get('api/v1/painel/unidade/usuario').then(({ data }) => {
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );
            //setUnidadeAux("unidades[0].label");
            console.log(data.data, 'data');
            console.log(unidades, 'unidades');

            handleGetProfissoes();
            handleGetVagas();
        });

    }, [handleGetProfissoes, handleGetVagas]);

    const handleGetBeneficiario = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/vaga/beneficiarios?beneficiarioNome=${inputValue}`,
                );
                const dados = data.data;

                return dados.beneficiarios.map((item: PreloaProps) => ({
                    label: item.beneficiarioNome,
                    value: item.beneficiarioNome,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    const handleGetProfissoesSelect = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/profissao`,
                );
                const dados = data.data;

                return dados.map((item: PreloaPropsProfi) => ({
                    label: item.nome,
                    value: item.nome,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar as profissoes',
                });
            }
        },
        [addToast],
    );

    const handleCancelVaga = useCallback(
        (vagaId) => {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Este processo não poderá ser desfeito',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: colors.primary,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, cancelar!',
                cancelButtonText: 'Fechar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.delete(`/api/v1/painel/vaga/${vagaId}`);

                        setVagas(old => [...old.filter(item => item.id !== vagaId)]);
                        // setVagas((old) =>
                        //     [...old].map((object) => {
                        //         if (object.id === vagaId) {
                        //             return {
                        //                 ...object
                        //             };
                        //         }
                        //         return object;
                        //     }),
                        // );
                        addToast({
                            type: 'success',
                            title: 'vaga cancelada com sucesso!',
                        });
                    } catch {
                        addToast({
                            type: 'error',
                            title: 'Ooops! Algo deu errado!',
                            description: 'Erro ao cancelar vaga',
                        });
                    }
                }
            });
        },
        [addToast],
    );

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col md={10}>
                                <h5>Vagas</h5>
                            </Col>
                            <Col md={2}>
                                <Button
                                    type="button"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                    onClick={() => history.push('/vagas/new')}
                                >
                                    <FiPlus color={colors.light} />
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetVagas(1, e)
                            }
                        >
                            <Row>
                                <Col md={3}>
                                    <Input
                                        name="codigo"
                                        type="text"
                                        placeholder="Código"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Input
                                        name="titulo"
                                        type="text"
                                        placeholder="Titulo"
                                    />
                                </Col>

                                <Col md={3}>
                                    <ReactSelectInput
                                        isMulti={false}
                                        hideLabel={false}
                                        name="unidade"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                    {/* <Select
                                        defaultOpt="Selecione a Unidade"
                                        name="unidade"
                                        type="text"
                                        placeholder="Unidade"
                                        options={unidades}
                                    /> */}
                                </Col>
                                <Col md={3}>
                                    <AsyncReactSelectInput
                                        name="profissao"
                                        type="text"
                                        placeholder="Profissões"
                                        loadOptions={handleGetProfissoesSelect}
                                        defaultOptions
                                        route="/api/v1/painel/profissao"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Profissão'
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <AsyncReactSelectInput
                                        name="beneficiario"
                                        type="text"
                                        placeholder="Beneficiario"
                                        loadOptions={handleGetBeneficiario}
                                        defaultOptions
                                        route="/api/v1/painel/vaga/beneficiarios"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Beneficiario'
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Input
                                        name="dtInicio"
                                        type="date"
                                        placeholder="Data de início"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Input
                                        name="dtFim"
                                        type="date"
                                        placeholder="Data final"
                                    />
                                </Col>

                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Row className="mt-3">
                            {sendFaturas ? (
                                <Col md={5}>
                                    <Form
                                        onSubmit={handleEnviarFaturamento}
                                    >
                                        <Row>
                                            <Col md={8}>
                                                <Input
                                                    name="dataVencimento"
                                                    type="date"
                                                    placeholder="Vencimento"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Button
                                                    type="submit"
                                                    color={colors.primary}
                                                    textcolor={colors.light}
                                                    style={{ marginTop: '38px' }}
                                                >
                                                    Enviar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            ) : (
                                <Col md={2}>
                                    <Button
                                        type="button"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                        onClick={handleSendFaturas}
                                    >
                                        Gerar Fatura(s)
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Card.Header>
                <Card.Body>
                    {vagas.length > 0 ? (
                        <List responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Titulo</th>
                                    <th>Profissão</th>
                                    <th>Unidade</th>
                                    <th>Status</th>
                                    <th></th>
                                    <th>Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vagas.map((vaga) => (
                                    <tr key={vaga.id}>
                                        <td className="d-flex">
                                            <CheckBox>
                                                <input
                                                    type="checkbox"
                                                    name={vaga.titulo}
                                                    disabled={
                                                        !(
                                                            vaga.faturaAgenda
                                                                .length === 0
                                                        )
                                                    }
                                                    checked={
                                                        !!faturas.find(
                                                            (fatura) =>
                                                                fatura ===
                                                                vaga.id,
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleSetFaturas(
                                                            vaga.id,
                                                        )
                                                    }
                                                />
                                                <span className="checkmark" />
                                            </CheckBox>

                                            {vaga.codigo}
                                        </td>
                                        <td>{vaga.titulo}</td>
                                        <td>{vaga.profissao.nome}</td>
                                        <td>{vaga.unidade.nome}</td>
                                        <td>{vaga.vagaStatus.nome}</td>
                                        <td style={{ width: '10%' }}>
                                            {vaga.vagaStatus.id === 1 && (
                                                <Button
                                                    type="button"
                                                    color={colors.danger}
                                                    textcolor={colors.light}

                                                    onClick={() =>
                                                        handleCancelVaga(
                                                            vaga.id,
                                                        )
                                                    }
                                                >
                                                    <MdCancel
                                                        color={colors.light}
                                                    />
                                                    Cancelar
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <Button
                                                type="button"
                                                color={colors.primary}

                                                textcolor={colors.light}
                                                onClick={() =>
                                                    history.push(
                                                        `/vagas/${vaga.id}/edit`,
                                                    )
                                                }
                                            >
                                                <FiEdit color={colors.light} />
                                                Editar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </List>
                    ) : (
                        <h5 className="text-center">Nada encontrado</h5>
                    )}
                    <div className="d-flex justify-content-center">
                        {paginationData && (
                            <PaginationComponent
                                currentPage={paginationData.page}
                                totalPages={paginationData.perPage}
                                perPage={10}
                                onChangePage={handleGetVagas}
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Vagas;
