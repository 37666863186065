import React, { useCallback, useEffect, useState } from "react";
import Role from "./components/Role";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import Loading from "../../components/Loading";
import { Col, Row } from "react-bootstrap";
import { Container, Card } from '../../styles/components';
import Breadcrumb from './../../components/Breadcrumb/index';
import Button from "../../components/Button";
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { colors } from "../../styles/variables";

interface Permission {
  concedido: boolean
  nome: string
  permissoes: any[]
}
type TypeParams = {
  groupId: string;
};

const Roles = () => {
  const { groupId } = useParams<TypeParams>()
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>()

  const { addToast } = useToast();

  const breadcrumbItens = [
    {
      title: 'Permissões',
      link: `/permissoes/${groupId}`,
      active: true,
    },
  ];

  const handleTogglePermission: any = (permissoes: Permission, positionFunction: number, positionPermission: number) => {
    let auxPermissions = permissions
    auxPermissions.funcoes[positionFunction].permissoes[positionPermission].concedido = permissoes.concedido
    setPermissions(auxPermissions)
  }

  const getGroup = useCallback(async (groupId: string) => {
    return await api.get(`/api/v1/painel/permissao/grupo-acesso/${groupId}`)
  }, [groupId])

  const handleUpdatePermissions = async (permissions: Permission[]) => {
    setLoading(true)
    try {
      const response = await api.put(`/api/v1/painel/permissao/${groupId}`, permissions)

      if (response.status === 200) {
        addToast({ type: 'success', title: 'Permissões atualizadas com sucesso.', })
        history.push('/grupo-acesso')
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível atualizar as permissões',
        description: 'Entre em contato com o admistrador'
      });
    }

    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    const getAccessGroupData = async () => {
      try {
        const accessGroupData = await getGroup(groupId)
        if (accessGroupData.status === 200) {
          setPermissions(accessGroupData.data.data)
        }
      } catch (error) {
        history.push('/permissoes')
      }

    }
    getAccessGroupData()
    setLoading(false)
    return () => { }
  }, [])

  return (
    <Container>
      <Breadcrumb itens={breadcrumbItens} />
      <Card>
        <Card.Header>
          <div style={{ width: '100%' }}>
            <Row>
              <Col md={10}>
                <h3>Atualizar Permissões</h3>
                {" "}
                <h5>{permissions?.nome}</h5>
              </Col>
            </Row>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? <Loading /> :
            permissions?.funcoes.map((role: Permission, index: number) => {
              return (
                <Role
                  key={role.nome}
                  position={index}
                  name={role.nome}
                  permissions={role.permissoes}
                  onChangePermissions={handleTogglePermission}
                />
              );
            })
          }
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '24px' }}>
            <Button
              style={{ width: '200px' }}
              color={colors.primary}
              textcolor={colors.light}
              onClick={() => handleUpdatePermissions(permissions.funcoes)}
            >
              Atualizar Permissões
            </Button>
          </div>
        </Card.Body>

      </Card>
    </Container >
  );
};

export default Roles;
