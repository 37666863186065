import React, {
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ICliente from '../../../interfaces/IClienteProps';

export interface ModalHandles {
    handleShowModal: () => void;
}

interface ModalProps {
    profissional: ICliente;
    onDeleteProfissional: (id: number, descricaoExclusao: string) => void;
}

const ModalConfirmDelete: React.RefForwardingComponent<
    ModalHandles,
    ModalProps
> = ({ onDeleteProfissional, profissional }, ref) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);

    const handleShowModal = useCallback(() => {
        setShow((old) => !old);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModal,
        };
    });

    const handleSubmit = useCallback(
        async (data: { observacao: string }) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    observacao: Yup.string().required('Campo obrigatório'),
                });

                await schema.validate(data, { abortEarly: false });
                onDeleteProfissional(profissional.id, data.observacao)
                setShow(false);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao ativar/desativar',
                        description:
                            'Ocorreu um erro ao ativar/desativar, tente novamente',
                    });
                }
            }
        },
        [addToast, onDeleteProfissional, profissional.id],
    );

    return (
        <Modal show={show} onHide={handleShowModal} size="lg">
            <Modal.Header closeButton>
                <h5>Confirmar {profissional.isAtivo ? 'Desativação' : 'Ativação'}</h5>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Input
                                name="observacao"
                                type="text"
                                placeholder="Observação"
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Button
                                type="submit"
                                color={colors.danger}
                                textcolor={colors.light}
                            >
                                {profissional.isAtivo ? 'Desativar' : 'Ativar'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default forwardRef(ModalConfirmDelete);
