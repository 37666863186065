import styled from "styled-components";
import { colors } from "../../styles/variables";
import * as React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



export const GhostButton = styled.button`
    max-width: min-content;
    border: none;
    background-color: transparent;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 2px;
    background-color: 'none';
    border-radius: 2px;
    padding: 4px;

    &:hover{
        svg{
            stroke: ${colors.primary};
        }
    }

    svg{
        /* stroke: #fff; */
    }

`
