import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (err: any) => {
        if (err.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(err);
    },
);

api.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default api;
