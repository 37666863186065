import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FiCheck } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/SelectInput';
import { Container, Card, Form } from './styles';
import { colors } from '../../../styles/variables';
import FileUpload from '../FileUpload';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/banner',
        active: false,
    },
    {
        title: 'Novo Banner',
        link: '/banner/new',
        active: true,
    },
];

interface CreateBannerFormData {
    nome: string;
    descricao: string;
    imagePath: string;
    isAtivo: boolean;
    arquivo: File;
    extensao: string;
}

const CreateBanner: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const [banner, setBanner] = useState({});
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [file, setFile] = useState<File>()

    useEffect(() => {
        async function getBanner(): Promise<void> {
            try {
                const response = await api.get(`/api/v1/painel/banner/${id}`);
                setBanner(response.data.data);
            } catch (error) {
                addToast({
                    title: 'Ooops!',
                    description:
                        'Alguma coisa deu errado ao tentar buscar o banner!',
                    type: 'error',
                });
            }
        }

        if (id) {
            getBanner();
        }
    }, [addToast, id]);

    const handleSubmit = useCallback(
        async (data: CreateBannerFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome é obrigatório'),
                    descricao: Yup.string().required('Descrição é obrigatório'),
                    imagePath: Yup.string().required('Link da imagem é obrigatório'),
                });

                await schema.validate(data, { abortEarly: false });

                try {
                    const formData: FormData = new FormData
                    formData?.append("arquivo", file as Blob)
                    const response = await api.post('/api/v1/public/arquivo/ler-arquivo', formData, { headers: { "Content-Type": "multipart/form-data" } })

                    data.arquivo = response.data.data.arquivo;
                    data.extensao = response.data.data.extensao;

                } catch (error) {
                    console.error(error);
                    throw error;
                }

                if (id) {
                    await api.put(`/api/v1/painel/banner/${id}`, { ...data, id: Number(id) });
                } else {
                    await api.post('/api/v1/painel/banner', data);
                }
                addToast({
                    type: 'success',
                    title: `Banner ${id ? 'Atualizado' : 'Cadastrado'
                        } com sucesso`,
                });

                history.goBack();
            } catch (err) {
                console.log(err);
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err?.response) {
                    const { errors } = err.response.data;

                    errors.forEach((err: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: err,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description: 'Ocorreu um erro ao cadastrar o banner',
                    });
                }
            }
        },
        [addToast, history, id, file],
    );
    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <h5>{id ? 'Atualizar dados' : 'Cadastrar Novo Banner'}</h5>
                </Card.Header>
                <Card.Body>
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={banner}
                    >
                        <Row>
                            <Col lg={6}>
                                <Input
                                    type="text"
                                    name="nome"
                                    placeholder="Nome"
                                />
                            </Col>
                            <Col lg={6}>
                                <Input
                                    name="descricao"
                                    type="text"
                                    placeholder="Descrição"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Input
                                    name="imagePath"
                                    type="text"
                                    placeholder="Link da imagem"
                                />
                            </Col>
                            <Col lg={6}>
                                <Select
                                    defaultOpt=""
                                    name="isAtivo"
                                    type="text"
                                    placeholder="Status"
                                    options={[
                                        { label: 'Ativo', value: true },
                                        {
                                            label: 'Inativo',
                                            value: false,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <h5 className="mt-5">Anexar imagem</h5>
                        <Row>
                            <Col lg={12}>
                                <div className='mx-3'>
                                    <FileUpload
                                        key={id}
                                        onFileUpload={(file) => setFile(file)}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button
                                    type="submit"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                >
                                    <FiCheck />
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default CreateBanner;
