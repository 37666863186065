import React, { useEffect, useLayoutEffect } from 'react'
import { useState, useCallback } from 'react';
import api from './../../services/api';
import { Card, Modal } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import Button from './../Button/index';
import { colors } from '../../styles/variables';
import { useToast } from '../../hooks/toast';

interface userComunicados {
  ativa: boolean;
  conteudo: string;
  id: number;
  titulo: string;
  idLogEnvio: number;
}

const CheckAnnouncement = ({ children }: { children: JSX.Element }) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [announcement, setAnnouncement] = useState<userComunicados[]>([]);
  const [announcementAccepted, setAnnouncementAccepted] = useState(1);
  // const [announcementTotal, setAnnouncementTotal] = useState(0);

  const handlePendingAnnouncements = useCallback(async () => {
    if (user?.login) {
      try {
        const response = await api.get(`/api/v1/painel/comunicado/comunicados-pendentes?email=${user.login}`);

        if (response.status === 200) {
          setAnnouncement(response.data.data);
        }
      } catch (error) {
        addToast({ type: 'error', title: 'Erro ao buscar comunicados', description: `${error.message}` });
      }
    }
  }, [addToast, user?.login])

  const handleConfirmReading = useCallback(async (id: number) => {
    try {
      try {
        const response = await api.put(`/api/v1/painel/comunicado/marcar-leitura/${id}`);
        if (response.status === 200) {
          addToast({ type: 'success', title: 'Leitura confirmada', description: '' });
          handlePendingAnnouncements();
          setAnnouncementAccepted(prevState => prevState + 1);
        }
      } catch (error) {
        addToast({ type: 'error', title: 'Erro ao confirmar', description: '' });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'Erro ao confirmar', description: '' });
    }
  }, [addToast, handlePendingAnnouncements])

  useEffect(() => {
    if (user && user.login) {
      handlePendingAnnouncements();
      // if (announcementTotal === 0){
      //     setAnnouncementTotal(announcement.length)
      // }
    }
  }, [user, handlePendingAnnouncements]);

  return (
    <>
      {(announcement === undefined || announcement?.length === 0) ?
        <div>{children}</div>
        :
        <Modal size='xl' show={true}>
          <Modal.Header>
            <Modal.Title>
              Declare que leu e Aceite para continuar
              <br />
              {/* <small>{announcementAccepted} de {announcementTotal} aceitos</small> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div key={announcement[0].id} style={{ paddingBottom: '16px' }} >
              <p style={{ fontWeight: 700 }}>{announcement[0].titulo}</p>
              <p>{announcement[0].conteudo}</p>
              <Button color={colors.primary} textcolor={colors.light} onClick={() => handleConfirmReading(announcement[0].idLogEnvio)}>
                Confirmar leitura
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default CheckAnnouncement;
