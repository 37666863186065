import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import { AiFillEye } from 'react-icons/ai';
import { RiFileList3Line } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { MdCancel } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import IFatura from '../../interfaces/IFatura';
import PaginationProps from '../../interfaces/PaginationProps';
import { useToast } from '../../hooks/toast';
import { colors } from '../../styles/variables';
import formatValue from '../../helpers/formatValue';
import api from '../../services/api';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Breadcrumb from '../../components/Breadcrumb';
import { Container, Card } from '../../styles/components';
import PaginationComponent from '../../components/PaginationComponent';
import AsyncReactSelectInput from '../../components/AsyncReactSelect';
import { List } from './styles';
import { SelectProps } from '../../components/SelectInput';
import ReactSelectInput from '../../components/ReactSelect';

const breadcrumbItens = [
    {
        title: 'Faturas',
        link: '/faturas',
        active: true,
    },
];

interface PreloaProps {
    id: number;
    nome: string;
}

const Faturas: React.FC = () => {
    const history = useHistory();
    const { addToast } = useToast();
    const [faturas, setFaturas] = useState<IFatura[]>([]);
    const [paginationData, setPaginationData] = useState<PaginationProps>();

    const [codigoAux, setCodigoAux] = useState('');
    const [responsavelIdAux, setResponsavelIdAux] = useState('');
    const [dtInicioAux, setDtInicioAux] = useState('');
    const [dtFimAux, setDtFimAux] = useState('');
    const [beneficiarioAux, setBeneficiarioAux] = useState('');
    const [unidadeAux, setUnidadeAux] = useState('');

    const [unidades, setUnidades] = useState<SelectProps[]>([]);

    const segundaVia = React.createRef();

    const handleSendTicket = useCallback(
        async (faturaId) => {
            try {
                await api.post(
                    `/api/v1/painel/faturamento/enviar`,
                    {},
                    {
                        params: {
                            faturaId,
                        },
                    },
                );
                addToast({
                    type: 'success',
                    title: 'Boleto enviado com sucesso!',
                });
            } catch {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: 'Erro ao enviar boleto',
                });
            }
        },
        [addToast],
    );

    const handleCancelTicket = useCallback(
        (faturaId) => {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Este processo não poderá ser desfeito',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: colors.primary,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, cancelar!',
                cancelButtonText: 'Fechar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.delete(`/api/v1/painel/faturamento/${faturaId}`);
                        setFaturas((old) =>
                            [...old].map((object) => {
                                if (object.id === faturaId) {
                                    return {
                                        ...object,
                                        status: 'Cancelada',
                                    };
                                }
                                return object;
                            }),
                        );
                        addToast({
                            type: 'success',
                            title: 'Fatura cancelada com sucesso!',
                        });
                    } catch {
                        addToast({
                            type: 'error',
                            title: 'Ooops! Algo deu errado!',
                            description: 'Erro ao cancelar fatura',
                        });
                    }
                }
            });
        },
        [addToast],
    );

    const handleSegundaVia = useCallback(
        (faturaId) => {

            Swal.fire({
                title: 'Login Form',
                html: `<input type="date" id="dataVencimento" class="" placeholder="data de vencimento" value="" style="padding:8px 15px"/>`,
                showCancelButton: true,
                confirmButtonColor: colors.primary,
                cancelButtonColor: '#d33',
                confirmButtonText: 'GERAR',
                cancelButtonText: 'FECHAR',
                focusConfirm: false,
                preConfirm: () => {
                    let swal = Swal.getPopup() as HTMLElement;
                    const dataVencimento = swal.querySelector('#dataVencimento') as HTMLInputElement
                    console.log(dataVencimento.value);
                    // //     Swal.fire(`
                    // //   Login: ${login}
                    // //   Password: ${password}
                    // `.trim())
                    if (!dataVencimento.value) {
                        Swal.showValidationMessage(`Digie a data de vencimento da 2° via.`)
                    }
                    return { dataVencimento: dataVencimento }
                }
            }).then(async (result) => {
                if (result.isConfirmed) {

                    var dataVencimento = result.value?.dataVencimento.value;

                    try {
                        await api.post(
                            `/api/v1/painel/faturamento/segunda-via/${faturaId}`,
                            {
                                faturaId,
                                dataVencimento,
                            },
                        );
                        addToast({
                            type: 'success',
                            title: 'Segunda via solicitada com sucesso!',
                        });
                    } catch {
                        addToast({
                            type: 'error',
                            title: 'Ooops! Algo deu errado!',
                            description: 'Erro ao gerar a segunda via',
                        });
                    }
                }
            })

        },
        [addToast],
    );

    const handleGetFaturamento = useCallback(
        async (
            page = 1,
            event = { unidade: unidadeAux, codigo: codigoAux, responsavelId: responsavelIdAux, dtInicio: dtInicioAux, dtFim: dtFimAux, beneficiario: beneficiarioAux },
        ) => {
            try {
                const { unidade, codigo, responsavelId, dtInicio, dtFim, beneficiario } = event;

                setUnidadeAux(unidadeAux);
                setCodigoAux(codigoAux);
                setResponsavelIdAux(responsavelId);
                setDtInicioAux(dtInicio);
                setDtFimAux(dtFim);
                setBeneficiarioAux(beneficiario);

                const { data } = await api.get('/api/v1/painel/faturamento', {
                    params: {
                        pagina: page,
                        unidade,
                        codigo,
                        responsavelId,
                        dtInicio,
                        dtFim,
                        beneficiario
                    },
                });
                const dados = data.data.faturas;
                const faturasFormatted = dados.map((fatura: IFatura) => ({
                    ...fatura,
                    valorFormatted: formatValue(fatura.valorTotal),
                    dataFormatted: format(
                        parseISO(fatura.dataCriacao),
                        'dd/MM/yyyy',
                    ),
                }));
                setPaginationData({
                    page: data.data.paginaAtual,
                    perPage: data.data.quantidadeTotalPagina,
                    total: data.data.quantidadeTotal,
                    lastPage:
                        data.data.quantidadeTotal /
                        data.data.quantidadeTotalPagina,
                });
                console.log(dados);
                setFaturas(faturasFormatted);
            } catch {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: 'Error ao bsucar dados',
                });
            }
        },
        [addToast, codigoAux, responsavelIdAux, dtInicioAux, dtFimAux, beneficiarioAux],
    );

    const handleGetUnidades = useCallback(async () => {
        try {
            const { data } = await api.get(`api/v1/painel/unidade/usuario`);
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );

            setUnidadeAux("teste");
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    const handleVisualizazrEmNovaAba = (faturaId: number) => {
        const win = window.open(`/faturas/${faturaId}/show`, '_blank');
        win?.focus();
    }

    useEffect(() => {
        handleGetUnidades();
        handleGetFaturamento();
    }, [handleGetUnidades, handleGetFaturamento]);

    const handleGetResponsaveis = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/cliente/responsaveis?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.responsaveis.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    const handleGetBeneficiario = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/vaga/beneficiarios?beneficiarioNome=${inputValue}`,
                );
                const dados = data.data;

                return dados.beneficiarios.map((item: any) => ({
                    label: item.beneficiarioNome,
                    value: item.beneficiarioNome,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />

            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col md={10}>
                                <h3>Faturas</h3>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetFaturamento(1, e)
                            }
                        >
                            <Row>
                                <Col md={4}>
                                    <Input
                                        name="codigo"
                                        type="text"
                                        placeholder="Código"
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="responsavelId"
                                        type="text"
                                        placeholder="Responsável"
                                        loadOptions={handleGetResponsaveis}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/responsavel"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Responsável'
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="beneficiario"
                                        type="text"
                                        placeholder="Beneficiario"
                                        loadOptions={handleGetBeneficiario}
                                        defaultOptions
                                        route="/api/v1/painel/vaga/beneficiarios"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Beneficiario'
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti={false}
                                        hideLabel={false}
                                        name="unidade"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        name="dtInicio"
                                        type="date"
                                        placeholder="Data de início"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        name="dtFim"
                                        type="date"
                                        placeholder="Data final"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card.Header>
                <Card.Body>
                    {faturas.length > 0 && (
                        <List responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                    <th>Data criação</th>
                                    <th>Criada por:</th>
                                    <th>Ações</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {faturas.map((fatura) => (
                                    <tr key={fatura.id}>
                                        <td>{fatura.codigo}</td>
                                        <td>{fatura.valorFormatted}</td>
                                        <td>{fatura.status}</td>
                                        <td>{fatura.dataFormatted}</td>
                                        <td>{fatura.funcionario?.nome}</td>
                                        <td style={{ width: '8%' }}>
                                            {fatura.status !== 'Cancelada' && (
                                                <Button
                                                    type="button"
                                                    color={colors.info}
                                                    textcolor={colors.light}

                                                    onClick={() =>
                                                        handleSegundaVia(
                                                            fatura.id,
                                                        )
                                                    }
                                                >

                                                    2° Via
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            {fatura.status !== 'Cancelada' && (
                                                <Button
                                                    type="button"
                                                    color={colors.danger}
                                                    textcolor={colors.light}

                                                    onClick={() =>
                                                        handleCancelTicket(
                                                            fatura.id,
                                                        )
                                                    }
                                                >
                                                    <MdCancel
                                                        color={colors.light}
                                                    />
                                                    Cancelar
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.primary}
                                                        textcolor={colors.light}
                                                        title="Enviar boleto"
                                                        onClick={() =>
                                                            handleSendTicket(fatura.id)
                                                        }
                                                    >
                                                        <RiFileList3Line
                                                            color={colors.light}
                                                        />
                                                        Enviar
                                                    </Button>
                                                </div>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        title="Visualizar em nova aba"
                                                        color={colors.secondary}
                                                        textcolor={colors.dark}

                                                        onClick={() =>
                                                            handleVisualizazrEmNovaAba(
                                                                fatura.id,
                                                            )
                                                        }
                                                    >
                                                        <AiFillEye
                                                            color={colors.light}
                                                        />
                                                        Visualizar
                                                    </Button>
                                                </div>
                                                {/* <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.info}
                                                        textcolor={colors.light}

                                                        onClick={() =>
                                                            history.push(
                                                                `/faturas/${fatura.id}/show`,
                                                            )
                                                        }
                                                    >
                                                        <AiFillEye
                                                            color={colors.light}
                                                        />
                                                        Visualizar
                                                    </Button>


                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </List>
                    )}
                    <div className="d-flex justify-content-center">
                        {paginationData && (
                            <PaginationComponent
                                currentPage={paginationData.page}
                                totalPages={paginationData.perPage}
                                perPage={10}
                                onChangePage={handleGetFaturamento}
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Faturas;
