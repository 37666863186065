
import React from 'react';

const Forbidden: React.FC = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
      <p style={{fontWeight: 700}}>Acesso não permitido</p>
    </div>
  )
};

export default Forbidden;
