import React, { useEffect, useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Container, InputContainer, Error } from './styles';

interface InputProps extends NumberFormatProps {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    percentage?: boolean;
}

const CurrencyInput: React.FC<InputProps> = ({
    name,
    icon: Icon,
    placeholder,
    readOnly = false,
    percentage = false,
    ...props
}) => {
    const inputRef = useRef<any>(null);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'state.value',
        });
    }, [fieldName, registerField]);

    const currencyFormatter = useCallback((value) => {
        if (!Number(value)) return '';

        const amount = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value / 100);

        return `${amount}`;
    }, []);

    return (
        <Container>
            <label htmlFor={name}>{placeholder}</label>
            <InputContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
                isDisabled={readOnly}
            >
                {Icon && <Icon size={20} />}
                <NumberFormat
                    defaultValue={defaultValue}
                    ref={inputRef}
                    id={name}
                    {...props}
                    suffix={'%'}
                    readOnly={readOnly}
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    format={percentage ? v => Number(v) + "%" : currencyFormatter}
                />
            </InputContainer>

            {error && (
                <Error>
                    <FiAlertCircle color="#c53030" size={18} />
                    <span>{error}</span>
                </Error>
            )}
        </Container>
    );
};

export default CurrencyInput;
