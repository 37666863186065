import React, { Fragment } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { IRelatorio } from '../../../interfaces/IRelatorio';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 0',
        borderTop: '1px solid #ccc',
        marginTop: '3px',
    },
    name: {
        width: '70%',
    },
    th: {
        width: '40%',
    },
});

interface RowProps {
    relatorios: IRelatorio[];
}

export const Row = ({ relatorios }: RowProps) => {
    const rows = relatorios.map( item =>
        <View style={styles.row} key={item.cpf}>
            <Text style={styles.name}>{item.nome}</Text>
            <Text style={styles.th}>{item.cpf}</Text>
            <Text style={styles.th}>{item.valorFormatado}</Text>
        </View>
    )

    return (
        <Fragment>
            <View style={styles.row}>
                <Text style={styles.name}>Nome</Text>
                <Text style={styles.th}>CPF</Text>
                <Text style={styles.th}>Valor</Text>
            </View>
            {rows}
        </Fragment>
    )
}
