import styled, { css } from 'styled-components';
import Table from 'react-bootstrap/Table';

export const List = styled(Table)`
    td, th {
        padding: .50rem;
        vertical-align: center !important;
        border-top: 1px solid #dee2e6;
    }
`;
