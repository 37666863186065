import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPlus, FiEdit } from 'react-icons/fi';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useToast } from '../../../hooks/toast';
import IBeneficiario from '../../../interfaces/IBeneficiario';
import Breadcrumb from '../../../components/Breadcrumb';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import PaginationComponent from '../../../components/PaginationComponent';
import PaginationProps from '../../../interfaces/PaginationProps';
import { colors } from '../../../styles/variables';
import api from '../../../services/api';
import { Container, Card } from '../../../styles/components';
import {
    List
} from './styles';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/clientes/beneficiarios',
        active: true,
    },
];

const Beneficiarios: React.FC = () => {
    const history = useHistory();
    const { addToast } = useToast();
    const [paginationData, setPaginationData] = useState<PaginationProps>();
    const [nome, setNome] = useState('');
    const [beneficiarios, setBeneficiarios] = useState<IBeneficiario[]>([]);

    const handleGetBeneficiarios = useCallback(
        async (page = 1) => {
            try {
                // const { nome } = event;

                const { data } = await api.get('/api/v1/painel/cliente/beneficiarios', {
                    params: {
                        pagina: page,
                        nome,
                    },
                });
                setBeneficiarios(data.data.beneficiarios);
                setPaginationData({
                    page: data.data.paginaAtual,
                    perPage: data.data.quantidadeTotalPagina,
                    total: data.data.quantidadeTotal,
                    lastPage:
                        data.data.quantidadeTotal /
                        data.data.quantidadeTotalPagina,
                });

            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os beneficiários',
                });
            }
        },
        [addToast, nome],
    );

    useEffect(() => {
        handleGetBeneficiarios();
    }, [handleGetBeneficiarios]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col md={10}>
                                <h3>Listagem de Beneficiários</h3>
                            </Col>
                            <Col md={2}>
                                <Button
                                    type="button"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                    onClick={() =>
                                        history.push(
                                            '/clientes/beneficiarios/new',
                                        )
                                    }
                                >
                                    <FiPlus color={colors.light} />
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetBeneficiarios(1)
                            }
                        >
                            <Row>
                                <Col md={10}>
                                    <Input
                                        name="nome"
                                        type="text"
                                        hideLabel
                                        onChange={(e) => setNome(e.currentTarget.value)}
                                        placeholder="Nome"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '8px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card.Header>
                <Card.Body>
                    <List responsive>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Unidade</th>
                                <th>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {beneficiarios.map((beneficiario) => (
                                <tr key={beneficiario.id}>
                                    <td>{beneficiario.nome}</td>
                                    <td>{beneficiario.responsavel.unidades.map((u) => (
                                        <div>{u.nome}</div>
                                    ))}</td>
                                    <td style={{ width: '14%' }}>
                                        <Button
                                            type="submit"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() =>
                                                history.push(
                                                    `/clientes/beneficiarios/${beneficiario.id}/edit`,
                                                )
                                            }
                                        >
                                            <FiEdit />
                                            Editar
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </List>
                    <div className="d-flex justify-content-center">
                        {paginationData && (
                            <PaginationComponent
                                currentPage={paginationData.page}
                                totalPages={paginationData.perPage}
                                perPage={10}
                                onChangePage={handleGetBeneficiarios}
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Beneficiarios;
