import React, { useRef, useEffect, useState, useCallback } from 'react';

import AsyncSelect from 'react-select/async';
import { useField } from '@unform/core';
import { Container, SelectContainer, Error } from './styles';
import api from '../../services/api';

interface Props {
    name: string;
    loadOptions(inputValue: string): Promise<any>;
    route: string;
    type: string;
    placeholder: string;
    defaultOptions: boolean;
    noOptionsMessage: () => string;
    loadingMessage: () => string;
    isMulti?: boolean;
    isDisabled?: boolean;
}

const AsyncReactSelectInput: React.FC<Props> = ({
    name,
    loadOptions,
    route,
    isDisabled,
    ...rest
}) => {
    const selectRef = useRef<any>(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [selectValue, setSelectValue] = useState<any>();
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!selectRef.current?.value);
    }, []);

    useEffect(() => {
        if (defaultValue) {
            api.get(`${route}/${defaultValue}`).then(({ data }) =>
                setSelectValue({ value: defaultValue, label: data.data.nome }),
            );
        }
    }, [defaultValue, route]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref.select.state.value) {
                        return [];
                    }
                    return ref.select.state.value.map(
                        (option: any) => option.value,
                    );
                }
                if (!ref.select.state.value) {
                    return defaultValue;
                }
                return ref.select.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti, defaultValue]);

    type MyOptionType = {
        label: string;
        value: string;
    };

    type IsMulti = false;
    // const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
    //     control: (provided, state) => {
    //         return {
    //             ...provided,
    //             borderWidth: '1px',
    //             padding: '3px 0',
    //             borderStyle: 'solid',
    //             background: '#fff',
    //             borderRadius: '5px',
    //             '&:hover': {
    //                 border: '1px solid #dde3f0',
    //             },
    //             boxShadow: `0 0 0 2px ${
    //                 state.isFocused
    //                     ? transparentize(0.7, colors.primary)
    //                     : error
    //                     ? transparentize(0.7, colors.danger)
    //                     : 'transparent'
    //             }`,
    //             borderColor: state.isFocused
    //                 ? colors.primary
    //                 : error
    //                 ? colors.danger
    //                 : '#dde3f0',
    //         };
    //     },
    // };

    return (
        <Container>
            <span id="span-label">{rest.placeholder}</span>
            <SelectContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
            >
                <AsyncSelect
                    // styles={selectStyle}
                    loadOptions={loadOptions}
                    ref={selectRef}
                    cacheOptions
                    defaultValue={selectValue}
                    // onChange={(s: any) => setSelectValue(s.label)}
                    value={selectValue}
                    onChange={(v) => setSelectValue(v)}
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    classNamePrefix="react-select"
                    isDisabled={isDisabled}
                    {...rest}
                />
            </SelectContainer>
            {error && (
                <Error>
                    <span>{error}</span>
                </Error>
            )}
        </Container>
    );
};
export default AsyncReactSelectInput;
