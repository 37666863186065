import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';

import { FiCheck, FiUser, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select, { SelectProps } from '../../../components/SelectInput';

import { Container, Card, Form } from './styles';
import { colors } from '../../../styles/variables';
import ReactSelectInput from '../../../components/ReactSelect';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/funcionarios',
        active: false,
    },
    {
        title: 'Editar Funcionario',
        link: '/funcionarios/edit',
        active: true,
    },
];

const EditFuncionario: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const { id } = useParams<{ id: string }>();
    const [unidades, setUnidades] = useState<SelectProps[]>([]);
    const [funcionario, setFuncionario] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getPreLoad(): Promise<void> {
            try {

                api.get('api/v1/painel/unidade/ativas').then(({ data }) => {
                    setUnidades(
                        data.data.map((item: any) => ({
                            label: item.nome,
                            value: item.id,
                        })),
                    );
                });

            } catch (error) {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description:
                        'Não conseguimos carregar os dados, atualize a pagina!',
                });
            }
        }
        getPreLoad();
    }, [addToast]);

    useEffect(() => {
        if (id) {
            setLoading(true);

            api.get(`/api/v1/painel/funcionario/${id}`).then(({ data }) => {
                const tmpFuncionario = data.data;

                tmpFuncionario.unidadesIds = tmpFuncionario.unidades.map(
                    (ap: { id: number; nome: string }) => ({
                        value: ap.id,
                        label: ap.nome,
                    }),
                );

                setFuncionario(tmpFuncionario);

                setLoading(false);
            });
        }
    }, [id]);

    const history = useHistory();

    const handleSubmit = useCallback(
        async (data) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome é obrigatório'),
                    email: Yup.string()
                        .email('Email inválido!')
                        .required('Email é obrigatório'),
                    password: Yup.string()
                        .required('Senha obrigatória')
                        .min(6, 'A senha precisa ter 6 caracteres.')
                        .max(6, 'A senha precisa ter 6 caracteres.'),
                    confirmPassword: Yup.string()
                        .oneOf(
                            [Yup.ref('password'), undefined],
                            'As senhas devem ser iguais',
                        )
                        .min(
                            6,
                            'A confirmação da senha precisa ter 6 caracteres.',
                        )
                        .max(
                            6,
                            'A confirmação da senha precisa ter 6 caracteres.',
                        ),
                    unidadesIds: Yup.number().required('Unidade é obrigatório'),
                });


                await schema.validate(data, { abortEarly: false });

                data.id = Number(id);

                await api.put(`/api/v1/painel/funcionario/${id}`, data);

                addToast({
                    type: 'success',
                    title: 'Funcionario Cadastrado com sucesso',
                });
                history.goBack();
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err.response) {
                    const { errors } = err.response.data;

                    errors.forEach((err: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: err,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar o profissional',
                    });
                }
            }
        },
        [addToast, history],
    );
    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <h2>Editar Funcionário</h2>
                </Card.Header>
                <Card.Body>
                    {!loading && (
                        <Form ref={formRef} initialData={funcionario} onSubmit={handleSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <Input
                                        icon={FiUser}
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiMail}
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Input
                                        icon={FiLock}
                                        name="password"
                                        type="password"
                                        placeholder="Senha"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Input
                                        icon={FiLock}
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirme a senha"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <ReactSelectInput
                                        isMulti
                                        name="unidadesIds"
                                        placeholder="Unidades"
                                        options={unidades}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 4, offset: 4 }}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                    >
                                        <FiCheck />
                                        Cadastrar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default EditFuncionario;
