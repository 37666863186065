import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useParams } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { colors } from '../../styles/variables';
import { Container, Content, Background, AnimationContainer } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface ResetPasswordRequest {
    code: string;
    email: string;
    newPassword: string;
    confirmPassword: string;
}

const ResetPassword: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const history = useHistory();
    const { code } = useParams<{ code: string }>();
    const { email } = useParams<{ email: string }>();

    const handleSubmit = useCallback(
        async (data: ResetPasswordRequest) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    newPassword: Yup.string()
                        .required('Nova senha obrigatória')
                        .min(6, 'Nova senha precisa ter 6 caracteres.')
                        .max(6, 'Nova senha precisa ter 6 caracteres.'),
                    confirmPassword: Yup.string()
                        .required('Confirmar senha obrigatória')
                        .min(6, 'Confirmar senha precisa ter 6 caracteres.')
                        .max(6, 'Confirmar senha precisa ter 6 caracteres.'),
                });

                await schema.validate(data, { abortEarly: false });

                data.code = code;
                data.email = email;

                await api.post('/api/v1/painel/auth/reset-senha', data);

                addToast({
                    type: 'success',
                    title: 'Sucesso',
                    description: 'Senha alterada com sucesso.',
                });

                history.push('/');
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    formRef.current?.setErrors(errors);
                } else if (err?.response) {
                    const { errors } = err.response.data;
                    errors.forEach((err: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro',
                            description: err,
                        });
                    });
                }
            }
        },
        [addToast, history],
    );
    return (
        <Container fluid>
            <Content>
                <AnimationContainer>
                    <img src={logoImg} alt="DeliveryPlus" />
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h2>Criar nova senha</h2>
                        <Input
                            icon={FiLock}
                            name="newPassword"
                            type="password"
                            placeholder="Nova senha"
                        />
                        <Input
                            icon={FiLock}
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirmar senha"
                        />
                        <Button
                            style={{ marginTop: '16px' }}
                            type="submit"
                            color={colors.primary}
                            textcolor={colors.secondary}
                        >
                            Salvar
                        </Button>
                        {
                            <a
                                onClick={() => history.push('/')}
                                href="javascript:void()"
                            >
                                Voltar
                            </a>
                        }
                    </Form>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default ResetPassword;
