import React, {
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import Button from '../../../components/Button';
import { ICurso } from '../../../interfaces/ICurso';

export interface ModalCursoHandles {
    handleShowModal: () => void;
}

interface ModalProps {
    setCurso: (curso: ICurso) => void;
    curso?: ICurso;
}

const ModalCurso: React.RefForwardingComponent<ModalCursoHandles,ModalProps> =
({ setCurso, curso }, ref) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);

    const handleShowModal = useCallback(() => {
        setShow(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShow(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModal,
        };
    });

    const handleSubmit = useCallback(
        async (data: ICurso) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    nomeDoCurso: Yup.string().required('Nome do curso é obrigatório'),
                    instituicao: Yup.string().required('Instituição é obrigatório'),
                    cargaHoraria: Yup.string().required('Carga horária é obrigatório'),
                    dataConclusao: Yup.string().required('Data Conslusão é obrigatório'),
                });

                const dataToSend = parseObjectTypes(data, [
                    'nomeDoCurso',
                    'instituicao',
                    'cargaHoraria',
                    'descricao',
                    'dataConclusao'
                ]);

                await schema.validate(dataToSend, { abortEarly: false });

                dataToSend.profissionalCursoId = curso?.profissionalCursoId;
                setCurso(dataToSend);
                setShow(false);

                addToast({
                    type: 'success',
                    title: 'Curso adicionado com sucesso',
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar, tente novamente',
                    });
                }
            }
        },
        [addToast, setCurso, curso],
    );

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <h5>Adicionar Curso</h5>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} initialData={curso} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Input
                                name="nomeDoCurso"
                                type="text"
                                placeholder="Nome do curso"
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                name="instituicao"
                                type="text"
                                placeholder="Instituição"
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                name="cargaHoraria"
                                type="number"
                                placeholder="Carga horária (em horas)"
                            />
                        </Col>
                        <Col md={6}>
                            <MaskInput
                                mask="99/9999"
                                name="dataConclusao"
                                type="text"
                                placeholder="Data de Conclusão (MM/AAAA)"
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                name="descricao"
                                type="text"
                                placeholder="Descrição"
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Button
                                type="submit"
                                color={colors.primary}
                                textcolor={colors.light}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default forwardRef(ModalCurso);
