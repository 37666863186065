import React from 'react';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { IRelatorio } from '../../interfaces/IRelatorio';
import { Row } from './Row'

interface PdfProps {
    relatorios: IRelatorio[];
}

const styles = StyleSheet.create({
    page: {
        fontSize: 11,
        flexDirection: 'column',
        padding: '50px',
    },
  });

export const Pdf = ({ relatorios }: PdfProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Row relatorios={relatorios} />
            </Page>
        </Document>
    );
}
