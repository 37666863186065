import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 80vh;

    div {
        height: 100%;

        div{
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                height: 200px;
            }

            h1 {
                text-align: start;
                font-weight: 600;
            }
        }
    }
`;
