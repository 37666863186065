import { createGlobalStyle } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import { colors } from './variables';

export default createGlobalStyle`

    * {
        margin:0;
        padding:0;
        box-sizing: border-box;
        outline:0;
    }
    body{
        background:${colors.light};
        color:${colors.dark};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }



    body,input ,p, button{
       font-size:13px !important;

       //font-family: 'IBM Plex Sans', sans-serif;
       font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    }

    h1,h2,h3,h4,h5,h6,strong{
        font-weight:500;
    }

    button{
        cursor:pointer;
    }

    button{
        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    td, th {
        padding: .50rem;
        vertical-align: center !important;
        border-top: 1px solid #dee2e6;
    }

`;
