import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useEffect } from 'react';

interface FileUploadProps {
    onFileUpload: ({ file, typeFile }: { file: File, typeFile: string, typeFileId: string }) => void;
    typeFile: string
    typeFileId: string
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload, typeFile, typeFileId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onDrop = useCallback((acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ".txt,.png,.jpg,.jpeg,.pdf", // ou o tipo de arquivo que você deseja permitir
        multiple: false, // permitir upload de apenas um arquivo
    });

    useEffect(() => {
        selectedFile && onFileUpload({ file: selectedFile, typeFile, typeFileId })
    }, [selectedFile])

    return (
        <div
            {...getRootProps()}
            className={isDragActive ? "drag-active" : ""}
            style={{
                border: "2px dashed #ccc",
                borderRadius: "8px",
                padding: "20px",
                margin: "10px",
                backgroundColor: "#f9f9f9",
                cursor: "pointer"
            }}
        >
            <input {...getInputProps({ accept: ".txt,.png,.jpg,.jpeg,.pdf" })} />
            {isDragActive ? (
                <p>Solte o arquivo aqui ...</p>
            ) : (
                <p style={{ marginBottom: "10px" }}>
                    Arraste e solte o arquivo aqui ou clique para selecionar {typeFile}
                </p>
            )}
            {selectedFile && (
                <p style={{ fontWeight: "bold" }}>
                    Arquivo selecionado: {selectedFile.name}
                </p>
            )}
        </div>


    );
}

export default FileUpload;
