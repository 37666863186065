import React from 'react';
import { Container, RoleContent, RoleHead, RoleName } from './styles';
import Permission from './Permission';

export interface Permission {
    nome: string;
    permissoes: any[];
    concedido: boolean;
}

interface RoleProps {
    name: string;
    permissions: Permission[];
    position: number;
    onChangePermissions: (permissao: Permission, index: number) => void;
}

const Role = (props: RoleProps) => {
    const { permissions, onChangePermissions, position } = props;

    return (
        <Container>
            <RoleHead>
                <RoleName>{props.name}</RoleName>
            </RoleHead>
            <RoleContent>
                {permissions.map(({ nome, concedido }, indexPermission) => {
                    return (
                        <Permission
                            key={nome}
                            positionFunction={props.position}
                            positionPermission={indexPermission}
                            name={nome}
                            checked={concedido}
                            onChange={onChangePermissions}
                        />
                    );
                })}
            </RoleContent>
        </Container>
    );
};

export default Role;
