import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FiList, FiCheck, FiAlignCenter } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Card, Form } from './styles';
import { colors } from '../../../styles/variables';

interface CreateCategoryFormData {
    nome: string;
}

const NewProfissao: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [profissao, setProfissao] = useState({});
    const { id } = useParams<{ id: string }>();
    const { addToast } = useToast();
    const history = useHistory();

    useEffect(() => {
        async function getProfissao(): Promise<void> {
            try {
                const response = await api.get(`/api/v1/painel/profissao/${id}`);

                setProfissao(response.data.data);
            } catch (error) {
                addToast({
                    title: 'Ooops!',
                    description:
                        'Alguma coisa deu errado ao tentar buscar a profissão!',
                    type: 'error',
                });
            }
        }

        if (id) {
            getProfissao();
        }
    }, [addToast, id]);

    const handleSubmit = useCallback(
        async (data: CreateCategoryFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome é obrigatório'),
                    descricao: Yup.string().required('Descrição é obrigatório'),
                });

                await schema.validate(data, { abortEarly: false });

                if (id) {
                    await api.put(`/api/v1/painel/profissao/${id}`, {
                        ...data,
                        id: Number(id),
                    });
                } else {
                    await api.post('/api/v1/painel/profissao', data);
                }

                addToast({
                    type: 'success',
                    title: `Profissão ${id ? 'Atualizada' : 'Cadastrada'
                        } com sucesso`,
                });
                history.goBack();
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err.response) {
                    const { errors } = err.response.data;

                    errors.forEach((error: string) => {
                        addToast({
                            type: 'error',
                            title: `Erro ao ${id ? 'atualizar' : 'cadastrar'}`,
                            description: error,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: `Erro ao ${id ? 'atualizar' : 'cadastrar'}`,
                        description:
                            'Ocorreu um erro ao cadastrar o profissional',
                    });
                }
            }
        },
        [addToast, history, id],
    );
    return (
        <Container fluid>
            <Breadcrumb
                itens={[
                    {
                        title: 'Profissoes',
                        link: '/profissoes',
                        active: false,
                    },
                    {
                        title: id ? 'Atualizar dados' : 'Nova Profissão',
                        link: id ? `/profissoes/${id}/edit` : '/profissoes/new',
                        active: true,
                    },
                ]}
            />
            <Card>
                <Card.Header>
                    <h2>
                        {id ? 'Atualizar dados' : 'Cadastrar Nova Profissão'}
                    </h2>
                </Card.Header>
                <Card.Body>
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={profissao}
                    >
                        <Row>
                            <Col lg={6}>
                                <Input
                                    icon={FiList}
                                    name="nome"
                                    type="text"
                                    placeholder="Nome da Profissão"
                                />
                            </Col>
                            <Col lg={6}>
                                <Input
                                    icon={FiAlignCenter}
                                    name="descricao"
                                    type="text"
                                    placeholder="Descrição da Profissão"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button
                                    type="submit"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                >
                                    <FiCheck />
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default NewProfissao;
