import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';

import cep from 'cep-promise';
import {
    FiCheck, FiUser, FiMail, FiLock,
    FiMapPin
} from 'react-icons/fi';
import { FormHandles, Scope } from '@unform/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import MaskInput from '../../../components/MaskInput';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select, { SelectProps } from '../../../components/SelectInput';

import { Container, Card, Form } from './styles';
import { colors } from '../../../styles/variables';
import CurrencyInput from '../../../components/CurrencyInput';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/unidades',
        active: false,
    },
    {
        title: 'Nova Unidade',
        link: '/unidades/new',
        active: true,
    },
];


interface SelectPropsBank {
    label: string;
    value: string | number;
    numero: number;
}
interface CreateUnidadeFormData {
    cnpj: string;
    razaoSocial: string;
    nome: string;
    email: string;
    endereco: string;
    iuguContaId: string;
    iuguApiToken: string;
    isAtivo: boolean;
}

const CreateUnidade: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const [unidade, setUnidade] = useState({});
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [bancos, setBancos] = useState<SelectPropsBank[]>([]);
    const [contas, setContas] = useState<SelectProps[]>([]);


    useEffect(() => {
        async function getUnidade(): Promise<void> {
            try {
                const response = await api.get(`/api/v1/painel/unidade/${id}`);
                console.log(response.data);
                setUnidade(response.data.data);
            } catch (error) {
                addToast({
                    title: 'Ooops!',
                    description:
                        'Alguma coisa deu errado ao tentar buscar a unidade!',
                    type: 'error',
                });
            }
        }

        async function getPreLoad() {
            const { data } = await api.get('/api/v1/painel/unidade/preload');
            const dados = data.data;

            setBancos(
                dados.bancos.map((item: any) => ({
                    label: item.nome,
                    value: item.numero,
                    key: item.id,
                })),
            );

            setContas(
                dados.contas.map((item: any) => ({
                    label: item,
                    value: item,
                })),
            );
        }


        getPreLoad();

        if (id) {
            getUnidade();
        }


    }, [addToast, id]);

    const handleCepSearch = useCallback(async (e) => {
        const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
        console.log(formRef, 'e');


        if (cepValue.length === 8) {
            console.log(cepValue);
            //const searchCep = async () => {
            try {
                const response = await cep(cepValue);
                console.log(response);
                if (response) {
                    formRef.current?.setFieldValue(
                        `endereco.logradouro`,
                        response.street,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.bairro`,
                        response.neighborhood,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.localidade`,
                        response.city,
                    );
                    formRef.current?.setFieldValue(`endereco.uf`, response.state);
                }
            } catch (err) {
                switch (err.type) {
                    case "service_error":
                        addToast({
                            type: 'error',
                            title: 'CEP não encontrado',
                        });
                        break;
                    case "validation_error":
                        addToast({
                            type: 'error',
                            title: 'CEP possui um formato inválido',
                        });
                        break;
                    default:
                    // addToast({
                    //     type: 'error',
                    //     title: err,
                    // });
                    //setSearch(err);
                }

                formRef.current?.setFieldValue(
                    `endereco.logradouro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.bairro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.localidade`, null,
                );
                formRef.current?.setFieldValue(`endereco.uf`, null);
            }
        }
    }, []);


    const handleSubmit = useCallback(
        async (data: any) => {
            try {
                formRef.current?.setErrors({});

                const enderecoSchema = Yup.object().shape({
                    cep: Yup.string()
                        .required('CEP é obrigatório')
                        .min(8, 'CEP precisa ter pelo menos 8 números')
                        .max(8, 'CEP precisa ter no máximo 8 números'),
                    logradouro: Yup.string().required(
                        'Logradouro da profissional é obrigatório',
                    ),
                    complemento: Yup.string(),
                    bairro: Yup.string().required('Bairro é obrigatório'),
                    uf: Yup.string().required('UF é obrigatório'),
                    localidade: Yup.string().required('Cidade é obrigatório'),
                    numero: Yup.number()
                        .typeError('Digite o número')
                        .required('Número é obrigatório'),
                });

                const bancoSchema = Yup.object().shape({
                    numero: Yup.string().required('Número é obrigatório'),
                    agencia: Yup.string().required('Agência é obrigatório'),
                    operacao: Yup.string(),
                    conta: Yup.string().required('Conta é obrigatório'),
                });

                if (id) {

                    const schema = Yup.object().shape({
                        cnpj: Yup.string().required('O CNPJ é obrigatório'),
                        razaoSocial: Yup.string().required(
                            'A razão social é obrigatório',
                        ),
                        nome: Yup.string().required('O nome é obrigatório'),
                        taxaRoyalties: Yup.string().required('A Taxa de Royalties é obrigatório'),
                        email: Yup.string()
                            .email('Email inválido!')
                            .required('Email é obrigatório'),

                        endereco: enderecoSchema,
                        banco: bancoSchema,

                    });

                    const findBanco = bancos.find(
                        (banco) => banco.value === data.banco.numero,
                    );
                    console.log(bancos, 'bancos');
                    data.banco.nome = findBanco?.label;
                    console.log(data, 'data');
                    await schema.validate(data, { abortEarly: false });

                    await api.put(`/api/v1/painel/unidade/${id}`, {
                        ...data,
                        id: Number(id),
                    });
                } else {


                    const schema = Yup.object().shape({
                        cnpj: Yup.string().required('O CNPJ é obrigatório'),
                        razaoSocial: Yup.string().required(
                            'A razão social é obrigatório',
                        ),
                        nome: Yup.string().required('O nome é obrigatório'),
                        email: Yup.string()
                            .email('Email inválido!')
                            .required('Email é obrigatório'),
                        // iuguContaId: Yup.string().required(
                        //     'A conta id da Iugu é obrigatório',
                        // ),
                        // iuguApiToken: Yup.string().required(
                        //     'A apikey da Iugu é obrigatório',
                        // ),
                        // blipAuthorization: Yup.string().required(
                        //     'O campo Authorization Blib é obrigatório',
                        // ),
                        taxaRoyalties: Yup.string().required('A Taxa de Royalties é obrigatório'),
                        endereco: enderecoSchema,
                        banco: bancoSchema,

                    });

                    const findBanco = bancos.find(
                        (banco) => banco.value === data.banco.numero,
                    );

                    data.banco.nome = findBanco?.label;
                    data.taxaRoyalties = data.taxaRoyalties.replace("%", "");

                    console.log(data, 'data');
                    await schema.validate(data, { abortEarly: false });

                    await api.post('/api/v1/painel/unidade', data);
                }

                addToast({
                    type: 'success',
                    title: `Unidade ${id ? 'Atualizada' : 'Cadastrada'
                        } com sucesso`,
                });

                // const { cnpj, razaoSocial, nome, email, endereco, iuguContaId, iuguApiToken } = data;
                // await api.post('/api/v1/painel/unidade', {
                //     cnpj, razaoSocial, nome, email, endereco, iuguContaId, iuguApiToken
                // });
                // addToast({
                //     type: 'success',
                //     title: 'Usuario Cadastrado com sucesso',
                // });
                history.goBack();
            } catch (err) {
                console.log(err);
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err?.response) {
                    const { errors } = err.response.data;

                    errors.forEach((err: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: err,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description: 'Ocorreu um erro ao cadastrar a unidade',
                    });
                }
            }
        },
        [addToast, history, id, bancos],
    );
    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <h5>{id ? 'Atualizar dados' : 'Cadastrar Nova Unidade'}</h5>
                </Card.Header>
                <Card.Body>
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={unidade}
                    >
                        <Row>
                            <Col lg={6}>
                                <MaskInput
                                    type="text"
                                    mask="99.999.999/9999-99"
                                    name="cnpj"
                                    placeholder="CNPJ"
                                />
                                {/* <Input
                                    name="cnpj"
                                    type="text"
                                    placeholder="CNPJ"
                                    mask="99.999.999/9999-99"
                                /> */}
                            </Col>
                            <Col lg={6}>
                                <Input
                                    icon={FiMail}
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Input
                                    name="razaoSocial"
                                    type="text"
                                    placeholder="Razão Social"
                                />
                            </Col>
                            <Col lg={6}>
                                <Input
                                    name="nome"
                                    type="text"
                                    placeholder="Nome"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Input
                                    name="taxaRoyalties"
                                    type="number"
                                    placeholder="Taxa Royalties"
                                />
                            </Col>
                            <Col md={6}>
                                <Select
                                    defaultOpt=""
                                    name="isAtivo"
                                    type="text"
                                    placeholder="Status"
                                    options={[
                                        { label: 'Ativo', value: true },
                                        {
                                            label: 'Inativo',
                                            value: false,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Input
                                    name="iuguContaId"
                                    type="text"
                                    placeholder="Conta Id da Iugu"
                                />
                            </Col>
                            <Col lg={6}>
                                <Input
                                    name="iuguApiToken"
                                    type="text"
                                    placeholder="ApiToken da Iugu"
                                />
                            </Col>
                        </Row>
                        <b>
                            {id
                                ? '*Por segurança as informações da Iugu não serão apresentadas, e alteradas apenas quando as chaves forem informadas.'
                                : ''}
                        </b>
                        <Row>
                            <Col lg={6}>
                                <Input
                                    name="blipAuthorization"
                                    type="text"
                                    placeholder="Authorization Blib"
                                />
                            </Col>
                        </Row>
                        <b>
                            {id
                                ? '*Por segurança as informações do Blib não serão apresentadas, deixe o campo em branco para não alterar.'
                                : ''}
                        </b>

                        <h5 className="mt-5">Banco</h5>
                        <hr />
                        <Scope path="banco">
                            <Row>
                                <Col lg={3}>
                                    <Select
                                        name="numero"
                                        keyName="key"
                                        type="text"
                                        placeholder="Banco"
                                        options={bancos}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Input
                                        name="agencia"
                                        type="text"
                                        placeholder="Agência com dígito"
                                    />
                                </Col>
                                <Col lg={1}>
                                    <Input
                                        name="operacao"
                                        type="text"
                                        placeholder="Operação"
                                    />
                                </Col>
                                <Col lg={2}>
                                    <Select
                                        name="tipo"
                                        type="text"
                                        placeholder="Tipo Conta"
                                        options={contas}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <MaskInput
                                        name="conta"
                                        type="text"
                                        mask="9999999999"
                                        placeholder="Conta"
                                    />
                                </Col>
                            </Row>
                        </Scope>
                        <h5>Endereço</h5>
                        <hr />
                        <Scope path="endereco">
                            <Row>
                                <Col lg={4}>
                                    <MaskInput
                                        icon={FiMapPin}
                                        name="cep"
                                        type="text"
                                        mask="99999999"
                                        placeholder="Digite seu Cep"
                                        onKeyUp={handleCepSearch}
                                    />
                                    <small>
                                        O endereço será preenchido
                                        automaticamente.
                                    </small>
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="localidade"
                                        type="text"
                                        placeholder="Cidade"
                                        readOnly
                                    />
                                </Col>

                                <Col lg={2}>
                                    <Input
                                        name="uf"
                                        type="text"
                                        placeholder="UF"
                                        readOnly
                                    />
                                </Col>

                                <Col lg={2}>
                                    <Input
                                        name="numero"
                                        type="text"
                                        placeholder="N°"
                                    />
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="bairro"
                                        type="text"
                                        placeholder="Bairro"
                                        readOnly
                                    />
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="logradouro"
                                        type="text"
                                        placeholder="Logradouro"
                                        readOnly
                                    />
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="complemento"
                                        type="text"
                                        placeholder="Complemento"
                                    />
                                </Col>
                            </Row>
                        </Scope>

                        <Row>
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button
                                    type="submit"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                >
                                    <FiCheck />
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default CreateUnidade;
