import { Form } from '@unform/web';
import React, { useState } from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import { Container, Card } from './../../styles/components';
import { Col, Row } from 'react-bootstrap';
import { TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import Button from '../../components/Button';
import { colors } from '../../styles/variables';
import api from './../../services/api';
import { useToast } from './../../hooks/toast';
import { SelectProps } from '../../components/SelectInput';
import ReactSelectInput from '../../components/ReactSelect';
import { useHistory, useParams } from 'react-router-dom';
import { FiEdit, FiPlus, FiSend, FiEye } from 'react-icons/fi';
import Paper from '@mui/material/Paper';
import { useCallback, useEffect } from 'react';
import Modal from '../../components/Modal';
import { Switch } from '@mui/material';
import { GhostButton } from './styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Confirm from '../../components/Confirm';





interface Perfil {
  id: string;
  nome: string
}

const Comunicados = () => {
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `none`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, .05)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },

  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: 'none',
  }));


  const { addToast } = useToast();
  const history = useHistory()
  const { id } = useParams<any>()

  const [loading, setLoading] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [announcement, setAnnouncement] = useState({ titulo: '', conteudo: '' })
  const [announcements, setAnnouncements] = useState<any[]>([])
  const [modalAnnouncement, setModalAnnouncement] = useState(false)
  const [modalSend, setModalSend] = useState(false)
  const [modalUserAndAnnouncements, setModalUserAndAnnouncements] = useState(false)
  const [detailAnnouncement, setDetailAnnouncement] = useState<any>({})
  const [perfis, setPerfis] = useState<Perfil[]>([])
  const [perfisSelected, setPerfisSelected] = useState<Perfil[]>([])

  const breadcrumbItens = [
    {
      title: 'Comunicados',
      link: '/comunicados',
      active: true,
    },
  ];


  const handleCloseModal = () => {
    setModalAnnouncement(false)
    setModalSend(false)
    history.goBack()
  }

  const handleModalDetailsAnnouncements = () => {
    setModalUserAndAnnouncements(false)
    setDetailAnnouncement({})
  }

  const validateSendAnnouncement = (announcement: any) => {
    if (announcement.ativa) {
      setModalSend(true)
      history.push(`/comunicados/${announcement.id}`)
    } else {
      addToast({
        type: 'error',
        title: 'Só é possível enviar os comunicados que estão ativos',
      });
    }
  }

  const parseDataToBROrNotReaded = (data: string) => {
    if (!data) {
      return 'Não lido'
    }
    const arrayData = data.substring(0, 10).split('-')
    return arrayData[2] + '/' + arrayData[1] + '/' + arrayData[0]
  }

  const handleUpdateAnnouncement = useCallback(async () => {
    setIsSubmiting(true)
    try {
      const response = await api.put('/api/v1/painel/comunicado/alterar', announcement)
      if (response.status === 200) {
        addToast({ type: 'success', title: 'Comunicado atualizado com sucesso!' })
        setModalAnnouncement(false)
        setAnnouncement({ titulo: '', conteudo: '' })
      } else {
        addToast({
          type: 'error',
          title: 'Não foi possível atualizar o comunicado',
          description: 'Entre em contato com o admistrador do sistema'
        });
      }

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível atualizar o comunicado',
        description: 'Entre em contato com o admistrador do sistema'
      });
    }

    setIsSubmiting(false)
  }, [id, setIsSubmiting, addToast, announcement])

  const handleCreateAnnouncement = useCallback(async () => {

    setIsSubmiting(true)
    try {
      const response = await api.post('/api/v1/painel/comunicado/adicionar', announcement)
      if (response.status === 200) {
        addToast({ type: 'success', title: 'Comunicado cadastrado com sucesso!' })
        setModalAnnouncement(false)
        setAnnouncement({ titulo: '', conteudo: '' })
      } else {
        addToast({
          type: 'error',
          title: 'Não foi possível cadastrar um comunicado',
          description: 'Entre em contato com o admistrador do sistema'
        });
      }

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível cadastrar um comunicado',
        description: 'Entre em contato com o admistrador do sistema'
      });
    }

    setIsSubmiting(false)
  }, [setIsSubmiting, addToast, announcement])

  const getAnnouncementById = useCallback(async (id: string) => {
    setIsSubmiting(true)
    try {
      const response = await api.get(`/api/v1/painel/comunicado/${id}`)
      if (response.status === 200) {
        setAnnouncement(response.data.data)
      } else {
        history.push('/comunicados')
      }
    } catch (error) {
      history.push('/comunicados')

    }
    setIsSubmiting(false)

  }, [id])

  const handleActiveAnnouncement = useCallback(async (id: string) => {

    if ((await Confirm()).isConfirmed) {
      setIsSubmiting(true)
      try {
        const response = await api.put(`/api/v1/painel/comunicado/alterar-status/${id}`)
        if (response.status === 200) {
          await getAnnouncementes()
          addToast({ type: 'success', title: 'Comunicado atualizado com sucesso!' })
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possível atualizar o comunicado',
            description: 'Entre em contato com o admistrador do sistema'
          });
        }

      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possível atualizar o comunicado',
          description: 'Entre em contato com o admistrador do sistema'
        });
      }
      setIsSubmiting(false)
    }

  }, [id, setIsSubmiting, addToast, announcement])

  const handleSendAnnouncement = useCallback(async () => {
    setIsSubmiting(true)
    try {
      const response = await api.post(`/api/v1/painel/comunicado/enviar/${id}`, perfisSelected)
      if (response.status === 200) {
        addToast({ type: 'success', title: 'Comunicado enviado com sucesso!' })
        setModalAnnouncement(false)
        setAnnouncement({ titulo: '', conteudo: '' })
      } else {
        addToast({
          type: 'error',
          title: 'Não foi possível enviar o comunicado',
          description: 'Entre em contato com o admistrador do sistema'
        });
      }

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível enviar o comunicado',
        description: 'Entre em contato com o admistrador do sistema'
      });
    }

    setIsSubmiting(false)
  }, [id, setIsSubmiting, addToast, perfisSelected])

  useEffect(() => {
    setIsSubmiting(true)

    api.get('/api/v1/painel/permissao/lista-grupos-simples')
      .then(response => {
        if (response.status === 200) {
          setPerfis(response.data.data)
          console.log(response)
        } else {
          addToast({
            type: 'error',
            title: 'Houve um erro desconhecido',
            description: 'Entre em contato com o admistrador do sistema'
          });
        }
      })
      .catch(error => {

      })

    setIsSubmiting(false)
  }, [])

  useEffect(() => {
    if (id) {
      setModalAnnouncement(true)
      getAnnouncementById(id)
    }

  }, [id])

  const getAnnouncementes = useCallback( async () => {
    try {
      await api.get('/api/v1/painel/comunicado/todos')
        .then(response => {
          if (response.status === 200) {
            setAnnouncements(response.data.data)
          } else {
            addToast({
              type: 'error',
              title: 'Não foi possível listar os comunicados',
              description: 'Entre em contato com o admistrador do sistema'
            });
          }
        })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível listar os comunicados',
        description: 'Entre em contato com o admistrador do sistema'
      });
    }
  }, [setAnnouncements, addToast])

  useEffect(() => {
    getAnnouncementes()
  }, [])

  return (
    <div>
      <Container fluid>
        <Breadcrumb itens={breadcrumbItens} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Card>
              <Row style={{ padding: '12px', marginBottom: '24px' }}>
                <Col md={10}>
                  <h5>Listagem de Comunicados</h5>
                </Col>
                <Col md={2}>
                  <Button
                    type="button"
                    color={colors.primary}
                    textcolor={colors.light}
                    onClick={() => setModalAnnouncement(true)}
                  >
                    <FiPlus color={colors.light} />
                    Adicionar
                  </Button>
                </Col>
              </Row>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Título</TableCell>
                      {/* <TableCell style={{ fontWeight: 700 }}>Perfis</TableCell> */}
                      <TableCell style={{ fontWeight: 700 }} align="right">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <div style={{ marginRight: '170px' }}>Ativar</div>
                          <div>Ações</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {announcements?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <GhostButton onClick={() => {
                              setModalUserAndAnnouncements(true)
                              setDetailAnnouncement(item)
                            }}>
                              <FiEye size={16} color={colors.dark} />

                            </GhostButton>
                            <Accordion style={{ width: '100%' }}>
                              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography style={{ marginLeft: '6px' }}>{item.titulo}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  {item.conteudo}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </TableCell>
                        {/* <TableCell>{row.perfis.toString()}</TableCell> */}
                        <TableCell align='right'>
                          <div style={{ display: 'inline-flex' }}>
                            <Switch checked={item.ativa} onChange={() => handleActiveAnnouncement(item.id)} />
                            <Button
                              style={{ width: 'min-content' }}
                              type="button"
                              color={colors.primary}
                              textcolor={colors.light}
                              onClick={() => history.push(`/comunicados/${item.id}`)}
                            >
                              <FiEdit color={colors.light} />
                              Editar
                            </Button>
                            <Button
                              disabled={!item.ativa}
                              textDisabled={'Enviar'}
                              style={{ width: 'min-content' }}
                              type="button"
                              color={colors.primary}
                              textcolor={colors.light}
                              onClick={() => validateSendAnnouncement(item)}
                            >
                              <FiSend color={colors.light} />
                              Enviar
                            </Button>
                          </div>
                        </TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>

            {/* modal Enviar, cadastrar e atualizar */}
            <Modal show={modalAnnouncement} setShow={handleCloseModal}>
              <Form onSubmit={id ? (modalSend ? handleSendAnnouncement : handleUpdateAnnouncement) : handleCreateAnnouncement}>
                <Row style={{ alignItems: 'center', flexDirection: 'column' }}>
                  <Col>
                    <label htmlFor="">Título</label>
                  </Col>
                  <Col>
                    <TextField
                      disabled={modalSend}
                      value={announcement.titulo}
                      onChange={e => setAnnouncement({ ...announcement, titulo: e.target.value })}
                      required
                      fullWidth
                      placeholder='Título'
                    />
                  </Col>
                </Row>
                {modalSend && (
                  <Row>
                    <Col>
                      <ReactSelectInput
                        isMulti
                        name='perfis'
                        handleChange={(arrayPerfis: any) => setPerfisSelected(arrayPerfis.map((perfil: any) => ({ nome: perfil.label, id: perfil.value })))}
                        placeholder="Quais perfis devem ver o comunicado?"
                        options={perfis.map(perfil => ({ label: perfil.nome, value: perfil.id }))}
                      />
                    </Col>
                  </Row>)}
                {!modalSend && (
                  <Row style={{ alignItems: 'center', flexDirection: 'column', marginTop: '12px' }}>
                    <Col>
                      <label htmlFor="">Escreva o comunicado</label>
                    </Col>
                    <Col>
                      <TextField
                        value={announcement.conteudo}
                        onChange={e => setAnnouncement({ ...announcement, conteudo: e.target.value })}
                        required
                        fullWidth
                        multiline
                        maxRows={10}
                        minRows={1}
                      />
                    </Col>
                  </Row>)}

                <Row>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                    <Button
                      style={{ marginTop: '12px', width: '200px' }}
                      type="submit"
                      color={colors.primary}
                      textcolor={colors.light}
                      disabled={isSubmiting}
                    >
                      {id ? 'Atualizar' : 'Cadastrar'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal>
            <Modal show={modalUserAndAnnouncements} setShow={handleModalDetailsAnnouncements}>
              <>
                {console.log(detailAnnouncement)}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 700 }}>
                  <span style={{ width: '60%' }}>E-mail</span>
                  <span style={{ width: '20%' }}>Data de <br /> envio</span>
                  <span style={{ width: '20%' }}>Data de visualização</span>
                </div>
                {detailAnnouncement.envios?.map((item: any) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
                    <div style={{ width: '60%' }}>{item.funcionario.email}</div>
                    <div style={{ width: '20%' }}> {parseDataToBROrNotReaded(item.dataEnvio)}</div>
                    <div style={{ width: '20%' }}> {parseDataToBROrNotReaded(item.dataLeitura)}</div>
                  </div>
                ))}
              </>
            </Modal>
          </>
        )}
      </Container>
    </div>
  )
}

export default Comunicados
