import React, { useCallback, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { RiFileExcel2Line } from 'react-icons/ri';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useToast } from '../../../hooks/toast';
import formatValue from '../../../helpers/formatValue';
import Breadcrumb from '../../../components/Breadcrumb';
import Input from '../../../components/Input';
import { Pdf } from '../../../components/PdfVagas';
import { colors } from '../../../styles/variables';
import api from '../../../services/api';
import { Container, Card, CheckBox } from '../../../styles/components';
import Select from '../../../components/SelectInput';
import AsyncReactSelectInput from '../../../components/AsyncReactSelect';
import { IRelatorioVagas } from '../../../interfaces/IRelatorio';
import Button from '../../../components/Button';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/clientes/beneficiarios',
        active: true,
    },
];
interface PreloaProps {
    beneficiarioId: number;
    beneficiarioNome: string;
}

const Beneficiarios: React.FC = () => {
    const { addToast } = useToast();
    const [relatorios, setRelatorios] = useState<IRelatorioVagas[]>([]);
    const date = format(new Date(), "ddMMyyyyHHmm");
    const [totalFatura, setTotalFatura] = useState<number>(0);
    const [sendFaturas, setSendFaturas] = useState(false);
    const [faturas, setFaturas] = useState<number[]>([]);

    const handleGetVagas = useCallback(
        async (event) => {
            try {
                const { beneficiarioId, dataInicio, dataFim, status } = event;

                const { data } = await api.get('/api/v1/painel/faturamento/relatorio/beneficiario', {
                    params: {
                        beneficiarioId,
                        dataInicio,
                        dataFim,
                        status,
                    },
                });
                setRelatorios(data.data.map(({ diasAgendados, ...item }: any): IRelatorioVagas => {
                    return diasAgendados.map((dia : any) => {
                        return {
                            ...item,
                            ...dia,
                            dataCadastroVaga: format(parseISO(item.dataCadastroVaga), 'dd/MM/yyyy'),
                            valorEmpresaVagaFormatado: formatValue(item.valorEmpresaVaga),
                            valorVagaFormatado: formatValue(item.valorVaga),
                            dataServico: format(parseISO(dia.dataServico), 'dd/MM/yyyy'),
                            valorAgendaFormatado: formatValue(dia.valorAgenda),
                            valorTotal: (item.valorEmpresaVaga + item.valorVaga),
                            valorTotalFormatado: formatValue(item.valorEmpresaVaga + item.valorVaga),
                        }
                    }).sort((itemAnterior: any, itemPosterior: any) => {
                        if (itemAnterior.dataServico < itemPosterior.dataServico) {
                            return -1;
                        }
                        if (itemAnterior.dataServico > itemPosterior.dataServico) {
                            return 1;
                        }
                        return 0;
                    })
                }).flat());
                console.log(data.data);
            } catch (error){
                console.error("Erro aqui:", error);
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: 'Erro ao buscar dados',
                });
            }
        },
        [addToast],
    );

    const handleSendFaturas = useCallback(() => {
        if (faturas.length === 0) {
            addToast({
                type: 'error',
                title: 'Selecione pelo menos uma vaga!',
            });
            return;
        }
        setSendFaturas(true);
    }, [faturas, addToast]);

    const handleSetFaturas = useCallback(
        (e: number) => {
            const findFatura = faturas.findIndex((fatura) => fatura === e);
            const updatedFaturas = findFatura < 0
                ? [...faturas, e]
                : faturas.filter((fatura) => fatura !== e);

            const selectedRelatorios = relatorios.filter((relatorio) =>
                updatedFaturas.includes(relatorio.vagaId)
            );

            // Calcular o valor total dinamicamente
            const selectedTotal = selectedRelatorios.reduce(
                (total, relatorio) => total + relatorio.valorTotal,
                0
            );

            // Atualizar o estado de faturas e o valor total
            setFaturas(updatedFaturas);
            setTotalFatura(selectedTotal);
        },
        [faturas, relatorios]
    );

    const handleEnviarFaturamento = useCallback(async ({ dataVencimento}) => {
        try {
            if (faturas.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Selecione pelo menos uma vaga!',
                });
                return;
            }
            await api.post(`/api/v1/painel/faturamento/`, {
                vagasIds: faturas,
                dataVencimento,
            });
            window.location.reload();
            addToast({
                type: 'success',
                title: 'Fatura gerada com sucesso!',
            });
            setSendFaturas(false);
        } catch (err) {
            const errors = err.response.data.errors;
            errors.forEach((err: string) => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: err,
                });
            });
            window.location.reload();
        }
    }, [addToast, faturas]);

    const handlerGetBeneficiarios = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/vaga/beneficiarios?beneficiarioNome=${inputValue}`,
                );
                const dados = data.data;
                return dados.beneficiarios.map((item: PreloaProps) => ({
                    label: item.beneficiarioNome,
                    value: item.beneficiarioId,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col>
                                <h3>Relatório de Beneficiários</h3>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetVagas(e)
                            }
                        >
                            <Row>
                                <Col md={2}>
                                    <Input
                                        name="dataInicio"
                                        type="date"
                                        placeholder="Data de início"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        name="dataFim"
                                        type="date"
                                        placeholder="Data de fim"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select
                                        defaultOpt="Selecione o status"
                                        name="status"
                                        type="text"
                                        placeholder="Status da Fatura"
                                        options={[
                                            { label: 'Todas', value: "", },
                                            { label: 'Pago', value: true, },
                                            { label: 'Não pago', value: false, },
                                        ]}
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="beneficiarioId"
                                        type="text"
                                        placeholder="Beneficiários"
                                        loadOptions={handlerGetBeneficiarios}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/beneficiarios"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando beneficiários'
                                        }
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Row className="mt-3">
                        {sendFaturas ? (
                            <Col md={5}>
                                <Form
                                    onSubmit={handleEnviarFaturamento}
                                >
                                    <Row>
                                        <Col md={8}>
                                            <Input
                                                name="dataVencimento"
                                                type="date"
                                                placeholder="Vencimento"
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Button
                                                type="submit"
                                                color={colors.primary}
                                                textcolor={colors.light}
                                                style={{ marginTop: '38px' }}
                                            >
                                                Enviar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) : (
                            <Col md={2}>
                                <Button
                                    type="button"
                                    color={colors.primary}
                                    textcolor={colors.light}
                                    style={{ marginTop: '38px' }}
                                    onClick={handleSendFaturas}
                                >
                                    Gerar Fatura(s)
                                </Button>
                            </Col>
                        )}
                        </Row>
                        {faturas.length > 0 && (
                            <div className="mt-3">
                                <strong>Prévia do Valor Total da Fatura: {formatValue(totalFatura)}</strong>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-end w-100">
                        {relatorios.length > 0 ? (
                            <>
                                <PDFDownloadLink
                                    document={<Pdf relatorios={relatorios} />}
                                    fileName={`relatorio-mensal-beneficiario-${date}.pdf`}
                                    className="btn btn-danger"
                                >
                                    {({ loading }) =>
                                        loading
                                            ? 'Carregando...'
                                            : <span className="d-flex align-items-center">
                                                <AiOutlineFilePdf className="mr-2" />PDF
                                            </span>
                                    }
                                </PDFDownloadLink>

                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success ml-2"
                                    table="table-to-relatorio-beneficiario"
                                    filename={`relatorio-mensal-beneficiario-${date}`}
                                    sheet="tablexls"
                                    buttonText={
                                        <span className="d-flex align-items-center">
                                            <RiFileExcel2Line className="mr-2" />Excel
                                        </span>
                                    }
                                />
                            </>
                        ): null}
                    </div>
                </Card.Header>
                <Card.Body>
                    {relatorios.length > 0 ? (
                        <Table responsive id="table-to-relatorio-beneficiario">
                            <thead>
                                <tr>
                                    <th>Número da Vaga</th>
                                    <th>Profissional</th>
                                    <th>Data Serviço</th>
                                    <th>Horário do Serviço</th>
                                    <th>Valor Empresa</th>
                                    <th>Valor Profissional</th>
                                    <th>Data Cadastro Vaga</th>
                                    <th>Valor Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatorios.map((relatorio) => (
                                    <tr key={relatorio.idAgenda}>
                                        <td className="d-flex">
                                            <CheckBox>
                                                <input
                                                    type="checkbox"
                                                    name={relatorio.vagaTitulo}
                                                    disabled={
                                                        (
                                                            relatorio.pago === true ||
                                                            relatorio.profissionalId === null
                                                        )
                                                    }
                                                    checked={
                                                        !!faturas.find(
                                                            (fatura) =>
                                                                fatura ===
                                                                relatorio.vagaId,
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleSetFaturas(
                                                            relatorio.vagaId,
                                                        )
                                                    }
                                                />
                                                <span className="checkmark" />
                                            </CheckBox>
                                            <Button
                                                type="button"
                                                color={colors.primary2}
                                                textcolor={colors.light}
                                                onClick={() => {
                                                    const newTab = window.open(`/vagas/${relatorio.vagaId}/edit`, '_blank');
                                                    newTab?.focus();
                                                  }}
                                            >
                                                {relatorio.codigoVaga}
                                            </Button>
                                        </td>
                                        <td>
                                            {relatorio.profissionalId !== null ? (
                                                <Button
                                                    type="button"
                                                    color={colors.primary2}
                                                    textcolor={colors.light}
                                                    onClick={() => {
                                                        const newTab = window.open(`/profissionais/${relatorio.profissionalId}/edit`, '_blank');
                                                        newTab?.focus();
                                                    }}
                                                    >
                                                    {relatorio.nomeProfissional}
                                                </Button>
                                            ) :
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                Vaga em aberto
                                            </div>
                                            }
                                        </td>
                                        <td>{relatorio.dataServico}</td>
                                        <td>{relatorio.horarioDescricao}</td>
                                        <td>{relatorio.valorEmpresaVagaFormatado}</td>
                                        <td>{relatorio.valorVagaFormatado}</td>
                                        <td>{relatorio.dataCadastroVaga}</td>
                                        <td>{relatorio.valorTotalFormatado}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <h5 className="text-center">Selecione um beneficiário</h5>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Beneficiarios;
