import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbProps } from 'react-bootstrap';
import { Container } from './styles';

interface BreadcrumbItensProps {
    title: string;
    link: string;
    active: boolean;
}
interface CustomBreadcrumbProps extends BreadcrumbProps {
    itens: Array<BreadcrumbItensProps>;
}
const Breadcrumb: React.FC<CustomBreadcrumbProps> = ({ itens }) => (
    <Container>
        {itens.map((item) => (
            <Container.Item
                key={item.link}
                active={item.active}
                as="div"
                linkAs="div"
            >
                <Link to={item.link}>{item.title}</Link>
            </Container.Item>
        ))}
    </Container>
);

export default Breadcrumb;
