import styled from 'styled-components';
import BSContainer from 'react-bootstrap/Container';
import BSCard from 'react-bootstrap/Card';
import { colors } from '../../../styles/variables';

export const Container = styled(BSContainer)`
    margin-bottom: 2rem;

    h6 {
        font-weight: 600;
        margin-bottom: 0;
        color: rgba(1, 1, 1, 0.7);
    }
`;

export const Header = styled.header`
    padding: 1.5rem 2rem;
    background: ${colors.primary};
    margin-bottom: 1rem;

    img {
        height: 50px;
    }
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const ItemList = styled.li`
    button {
        width: 50px;
        svg {
            margin: 0;
        }
    }
`;
