import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FiPlus, FiEdit } from 'react-icons/fi';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import IVagas from '../../../../interfaces/IVagas';
import INfeProfissional from '../../../../interfaces/INfeProfissional';
import Breadcrumb from '../../../../components/Breadcrumb';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import PaginationComponent from '../../../../components/PaginationComponent';
import PaginationProps from '../../../../interfaces/PaginationProps';
import { colors } from '../../../../styles/variables';
import { Container, Card, CheckBox } from '../../../../styles/components';
import Select, { SelectProps } from '../../../../components/SelectInput';
import AsyncReactSelectInput from '../../../../components/AsyncReactSelect';
import { List } from './styles';
import ReactSelectInput from '../../../../components/ReactSelect';
import { MdCancel } from 'react-icons/md';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { RiFileExcel2Line } from 'react-icons/ri';
import { format } from 'date-fns';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/nfe/profissionais',
        active: true,
    },
];

interface PreloaProps {
    beneficiarioId: number;
    beneficiarioNome: string;
}

interface PreloaPropsProfi {
    id: number;
    nome: string;
}

const NfeProfissional: React.FC = () => {
    const { addToast } = useToast();
    const history = useHistory();
    const [nfeProfissionais, setNfeProfissionais] = useState<INfeProfissional[]>([]);
    const [vagas, setVagas] = useState<IVagas[]>([]);
    const [selecionados, setSelecionados] = useState<number[]>([]);
    const [profissoes, setProfissoes] = useState<SelectProps[]>([]);
    const [paginationData, setPaginationData] = useState<PaginationProps>();
    const [sendSelecionados, setSendSelecionados] = useState(false);

    const [mesAux, setMesAux] = useState('');
    const [anoAux, setAnoAux] = useState('');
    const [unidadeAux, setUnidadeAux] = useState('');
    const [unidadeNfeAux, setUnidadeNfeAux] = useState('');

    const [profissionalAux, setProfissionalAux] = useState('');
    const [dtInicioAux, setDtInicioAux] = useState('');
    const [dtFimAux, setDtFimAux] = useState('');
    const [beneficiarioAux, setBeneficiarioAux] = useState('');

    const [unidades, setUnidades] = useState<SelectProps[]>([]);
    const [unidadesNfe, setUnidadesNfe] = useState<SelectProps[]>([]);

    const [meses, setMeses] = useState<SelectProps[]>([]);
    const [anos, setAnos] = useState<SelectProps[]>([]);
    const date = format(new Date(), "ddMMyyyyHHmm");

    const [checked, setChecked] = useState<boolean>();


    const handleSendProfissionais = useCallback(() => {

        if (selecionados.length === 0) {
            addToast({
                type: 'error',
                title: 'Selecione pelo menos um profissional!',
            });
            return;
        }
        console.log(unidadeNfeAux);
        if (unidadeNfeAux == "") {
            addToast({
                type: 'error',
                title: 'Selecione a unidade para geração da(s) nota(s)!',
            });
            return;
        }

        setSendSelecionados(true);

        handleEnviar();
    }, [selecionados, unidadeNfeAux, addToast]);

    const handleGetNfe = useCallback(
        async (
            page = 1,
            event = { unidade: unidadeAux, mes: mesAux, ano: anoAux, profissional: profissionalAux },
        ) => {
            try {
                const { unidade, mes, ano, profissional } = event;

                //setUnidadeNfeAux(unidadenfe);
                setUnidadeAux(unidade);
                setAnoAux(ano);
                setMesAux(mes);
                setProfissionalAux(profissional);

                console.log(event);
                const { data } = await api.get('/api/v1/painel/nfe/profissionais', {
                    params: {
                        mes,
                        ano,
                        UnidadeId: unidade,
                        ProfissionalId: profissional
                    },
                });
                setNfeProfissionais(data.data);
                // setPaginationData({
                //     page: data.data.paginaAtual,
                //     perPage: data.data.quantidadeTotalPagina,
                //     total: data.data.quantidadeTotal,
                //     lastPage:
                //         data.data.quantidadeTotal /
                //         data.data.quantidadeTotalPagina,
                // });
            } catch {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: 'Erro ao bsucar dados',
                });
            }
        },
        [addToast, unidadeAux, mesAux, anoAux, profissionalAux],
    );

    const handleSetSelecionados = useCallback(
        (e: number) => {
            const findSelecionado = selecionados.findIndex((selecionado) => selecionado === e);
            if (findSelecionado < 0) {
                setSelecionados((old) => [...old, e]);
            } else {
                setSelecionados((old) => old.filter((selecionado) => selecionado !== e));
            }
        },
        [selecionados],
    );

    const handleEnviar = useCallback(async () => {
        try {
            if (selecionados.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Selecione pelo menos um profissional!',
                });
                return;
            }



            await api.post(`/api/v1/painel/nfe/profissionais`, {
                Ids: selecionados,
                UnidadeId: unidadeAux,
                UnidadeNfeId: unidadeNfeAux,
                Mes: mesAux,
                Ano: anoAux
            });
            addToast({
                type: 'success',
                title: 'NFEs geradas com sucesso!',
            });
            setSendSelecionados(false);
            handleGetNfe();
        } catch (err) {

            //console.log(err.response.data);
            const { errors } = err.response.data;

            errors.forEach((err: string) => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: err,
                });
            });


        }
    }, [addToast, selecionados, unidadeNfeAux, handleGetNfe]);

    const handleGetMesAnos = useCallback(async () => {
        try {
            var meses = [
                { label: "Janeiro", value: 1 },
                { label: "Fevereiro", value: 2 },
                { label: "Março", value: 3 },
                { label: "Abril", value: 4 },
                { label: "Maio", value: 5 },
                { label: "Junho", value: 6 },
                { label: "Julho", value: 7 },
                { label: "Agosto", value: 8 },
                { label: "Setembro", value: 9 },
                { label: "Outubro", value: 10 },
                { label: "Novembro", value: 11 },
                { label: "Dezembro", value: 12 }
            ]
            setMeses(
                meses.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                })),
            );

            const { data } = await api.get(`api/v1/painel/nfe/anos`);

            setAnos(
                data.data.map((item: any) => ({
                    label: item,
                    value: item,
                })),
            );


        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    // const handleGetUnidades = useCallback(async () => {
    //     try {
    //         const { data } = await api.get(`api/v1/painel/unidade/usuario`);
    //         setUnidades(
    //             data.data.map((item: any) => ({
    //                 label: item.nome,
    //                 value: item.nome,
    //             })),
    //         );

    //         setUnidadeAux("teste");
    //     } catch {
    //         addToast({
    //             type: 'error',
    //             title: 'Ooops! Algo deu errado!',
    //             description: 'Erro ao buscar profissões',
    //         });
    //     }
    // }, [addToast]);

    useEffect(() => {

        api.get('api/v1/painel/unidade/usuario').then(({ data }) => {
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );

            var option = [{
                label: "Selecione uma unidade",
                value: "",
            }]
            setUnidadesNfe(
                [...unidadesNfe,
                ...option.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                }))]
            );


            // data.data.map((item: any) =>
            //     setUnidadesNfe([...unidadesNfe, {
            //         label: item.nome,
            //         value: item.id,
            //     }])
            // );

            setUnidadesNfe(old => [...old,
            ...data.data.map((item: any) => ({
                label: item.nome,
                value: item.id,
            }))]
            );
        });

        handleGetMesAnos();
        //handleGetNfe();


    }, [handleGetMesAnos]);

    // const handleGetBeneficiario = useCallback(
    //     async (inputValue: string) => {
    //         try {
    //             const { data } = await api.get(
    //                 `/api/v1/painel/vaga/beneficiarios?beneficiarioNome=${inputValue}`,
    //             );
    //             const dados = data.data;

    //             return dados.beneficiarios.map((item: PreloaProps) => ({
    //                 label: item.beneficiarioNome,
    //                 value: item.beneficiarioNome,
    //             }));
    //         } catch {
    //             addToast({
    //                 type: 'error',
    //                 title: 'Erro ao buscar responsáveis',
    //             });
    //         }
    //     },
    //     [addToast],
    // );

    // const handleGetProfissoesSelect = useCallback(
    //     async (inputValue: string) => {
    //         try {
    //             const { data } = await api.get(
    //                 `/api/v1/painel/profissao`,
    //             );
    //             const dados = data.data;

    //             return dados.map((item: PreloaPropsProfi) => ({
    //                 label: item.nome,
    //                 value: item.nome,
    //             }));
    //         } catch {
    //             addToast({
    //                 type: 'error',
    //                 title: 'Erro ao buscar as profissoes',
    //             });
    //         }
    //     },
    //     [addToast],
    // );

    const handleCancel = useCallback(
        (id: any, mes: any, ano: any, unidade: any) => {
            var url = `/api/v1/painel/nfe/profissionais?profissionalId=${id}&mes=${mes}&ano=${ano}&unidadeId=${unidade}`;
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Este processo não poderá ser desfeito',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: colors.primary,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, cancelar!',
                cancelButtonText: 'Fechar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.delete(url);

                        handleGetNfe();

                        addToast({
                            type: 'success',
                            title: 'NFE cancelada com sucesso!',
                        });
                    } catch {
                        addToast({
                            type: 'error',
                            title: 'Ooops! Algo deu errado!',
                            description: 'Erro ao cancelar NFE',
                        });
                    }
                }
            });
        },
        [addToast, handleGetNfe],
    );

    const downloadXML = (id: any) =>
        api.get(`/api/v1/painel/nfe/profissionais/xml?profissionalId=${id}&mes=${mesAux}&ano=${anoAux}&unidadeId=${unidadeAux}`, { responseType: 'blob' })
            .then((response) => {
                console.log(response.data);
                saveAs(response.data, `nfe.xml`);

                // console.log(response);
                // const blob = new Blob([response.data], { type: response.data.type });
                // const src = window.URL.createObjectURL(blob);

                // window.open(src);
                // setSrcDocumento(src);
                // setShowModalDocumento(true);
            })
            .catch((error) => {
                addToast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Ocorreu um erro ao carregar o arquivo.',
                });
            });

    const downloadPDF = (id: any) =>
        api.get(`/api/v1/painel/nfe/profissionais/pdf?profissionalId=${id}&mes=${mesAux}&ano=${anoAux}&unidadeId=${unidadeAux}`, { responseType: 'blob' })
            .then((response) => {
                console.log(response.data);
                saveAs(response.data, `nfe.pdf`);

                // console.log(response);
                // const blob = new Blob([response.data], { type: response.data.type });
                // const src = window.URL.createObjectURL(blob);

                // window.open(src);
                // setSrcDocumento(src);
                // setShowModalDocumento(true);
            })
            .catch((error) => {
                addToast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Ocorreu um erro ao carregar o arquivo.',
                });
            });

    // const handleSendTodos = (e) => {
    //     var updatedList = [...checked];
    //     if (event.target.checked) {
    //         updatedList = [...checked, event.target.value];
    //     } else {
    //         updatedList.splice(checked.indexOf(event.target.value), 1);
    //     }
    //     setChecked(updatedList);
    // };

    const handleSendTodos = (e: any) => {
        setChecked(e.target.checked);
        console.log(selecionados, 'aqui');
        console.log(e.target.checked);
        if (e.target.checked) {
            console.log(e.target.checked, 'entrou');
            setSelecionados([]);
            setSelecionados(nfeProfissionais.filter(x => x.status == null).map((item) => item.id));
            console.log(selecionados);
            // nfeProfissionais.map((x) => {
            //     if (x.status == null) {
            //         setSelecionados((old) => [...old, x.id]);
            //         console.log(x.id);
            //     }
            // });
            //setSelecionados(nfeProfissionais.filter(x => x.status == null).map(return x.id));
        } else {
            setSelecionados([]);
        }

        console.log(selecionados);
        // const findSelecionado = selecionados.findIndex((selecionado) => selecionado === e);
        // if (findSelecionado < 0) {
        // setSelecionados((old) => [...old, e]);
        // } else {
        //     setSelecionados((old) => old.filter((selecionado) => selecionado !== e));
        // }
    }

    const handleUnidadeNfe = useCallback((event) => {
        setUnidadeNfeAux(event.target.value);
        //console.log(event.target.value);
    }, [unidadeNfeAux]);
    console.log(unidadeNfeAux);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col md={10}>
                                <h5>NFEs Profissionais</h5>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetNfe(1, e)
                            }
                        >
                            <Row>
                                <Col md={3}>
                                    <Select
                                        //defaultOpt="Selecione a Unidade"
                                        name="mes"
                                        type="text"
                                        placeholder="mês"
                                        options={meses}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Select
                                        //defaultOpt="Selecione a Unidade"
                                        name="ano"
                                        type="text"
                                        placeholder="Ano"
                                        options={anos}
                                    />
                                </Col>

                                <Col md={3}>
                                    <Select
                                        //defaultOpt="Selecione a Unidade"
                                        name="unidade"
                                        type="text"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                    {/* <ReactSelectInput
                                        isMulti={false}
                                        hideLabel={false}
                                        name="unidade"
                                        placeholder="Unidade"
                                        options={unidades}
                                    /> */}
                                    {/* <Select
                                        defaultOpt="Selecione a Unidade"
                                        name="unidade"
                                        type="text"
                                        placeholder="Unidade"
                                        options={unidades}
                                    /> */}
                                </Col>
                                {/* <Col md={3}>
                                    <AsyncReactSelectInput
                                        name="profissao"
                                        type="text"
                                        placeholder="Profissões"
                                        loadOptions={handleGetProfissoesSelect}
                                        defaultOptions
                                        route="/api/v1/painel/profissao"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Profissão'
                                        }
                                    />
                                </Col> */}
                                {/* <Col md={3}>
                                    <AsyncReactSelectInput
                                        name="beneficiario"
                                        type="text"
                                        placeholder="Beneficiario"
                                        loadOptions={handleGetBeneficiario}
                                        defaultOptions
                                        route="/api/v1/painel/vaga/beneficiarios"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Beneficiario'
                                        }
                                    />
                                </Col> */}
                                {/* <Col md={3}>
                                    <Input
                                        name="dtInicio"
                                        type="date"
                                        placeholder="Data de início"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Input
                                        name="dtFim"
                                        type="date"
                                        placeholder="Data final"
                                    />
                                </Col> */}

                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={3}>
                                    <Select
                                        //defaultOpt="Selecione a Unidade"
                                        name="unidadenfe"
                                        type="text"
                                        placeholder="Unidade para emitir NFE(s)"
                                        options={unidadesNfe}
                                        onChange={handleUnidadeNfe}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="button"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                        onClick={handleSendProfissionais}
                                    >
                                        Emitir NFE(s)
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                        {nfeProfissionais && (
                            <>

                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success ml-2"
                                    table="table-nfe-profissionais"
                                    filename={`relatorio-nfes-profissional-${date}`}
                                    sheet="tablexls"
                                    buttonText={
                                        <span className="d-flex align-items-center">
                                            <RiFileExcel2Line className="mr-2" />Excel
                                        </span>
                                    }
                                />
                            </>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>

                    {nfeProfissionais.length > 0 ? (
                        <div className='row'>
                            <table>
                                <thead>
                                    <tr>
                                        <td className='class-td'><CheckBox>
                                            <input
                                                type="checkbox"
                                                name="todos"
                                                onChange={handleSendTodos}
                                            />
                                            <span className="checkmark" />
                                        </CheckBox></td>
                                        <td className='class-td'><b>Selecionar Todos</b></td>
                                    </tr>
                                </thead>
                            </table>


                            <Table responsive id="table-nfe-profissionais">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Profissional</th>
                                        <th>Valor</th>
                                        <th>Nfe Id</th>
                                        <th>Emissor</th>
                                        <th>Data</th>
                                        <th>Status</th>
                                        <th>Motivo</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {nfeProfissionais.map((i) => (
                                        <tr key={i.id}>
                                            <td >
                                                <CheckBox>
                                                    <input
                                                        type="checkbox"
                                                        name={i.cpf}
                                                        disabled={i.status !== null}
                                                        checked={!!selecionados.find((s) => s === i.id)}
                                                        // checked={
                                                        //     !!faturas.find(
                                                        //         (fatura) =>
                                                        //             fatura ===
                                                        //             vaga.id,
                                                        //     )
                                                        // }
                                                        onChange={() =>
                                                            handleSetSelecionados(
                                                                i.id,
                                                            )
                                                        }
                                                    />
                                                    <span className="checkmark" />
                                                </CheckBox>

                                            </td>
                                            <td className="d-flex">{i.cpf} - {i.nome}</td>
                                            <td>R$ {i.valorFormatado}</td>
                                            <td>{i.nfeId}</td>
                                            <td>{i.emissor}</td>
                                            <td>{i.dataEmissaoFormatado}</td>
                                            <td>{i.status}</td>
                                            <td>{i.motivoStatus}</td>
                                            <td style={{ width: '10%' }}>
                                                {i.status !== null && (
                                                    <Button
                                                        type="button"
                                                        color={colors.danger}
                                                        textcolor={colors.light}

                                                        onClick={() =>
                                                            handleCancel(i.id, mesAux, anoAux, unidadeAux)
                                                        }
                                                    >
                                                        <MdCancel
                                                            color={colors.light}
                                                        />
                                                        Cancelar
                                                    </Button>
                                                )}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {i.status === "Autorizada" && (
                                                    <Button
                                                        type="button"
                                                        color={colors.primary}

                                                        textcolor={colors.light}
                                                        onClick={() => downloadXML(i.id)
                                                        }
                                                    >
                                                        XML
                                                    </Button>
                                                )}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {i.status === "Autorizada" && (
                                                    <Button
                                                        type="button"
                                                        color={colors.primary}

                                                        textcolor={colors.light}
                                                        onClick={() => downloadPDF(i.id)
                                                        }
                                                    >
                                                        PDF
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                    ) : (
                        <h5 className="text-center">Nada encontrado</h5>
                    )}
                    {/* <div className="d-flex justify-content-center">
                        {paginationData && (
                            <PaginationComponent
                                currentPage={paginationData.page}
                                totalPages={paginationData.perPage}
                                perPage={10}
                                onChangePage={handleGetV}
                            />
                        )}
                    </div> */}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default NfeProfissional;
