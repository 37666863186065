import React from 'react';

import ReactLoading from 'react-loading';

import { Container } from './styles';

import { colors } from '../../styles/variables';

const Loading: React.FC = () => (
    <Container>
        <h2 style={{ color: colors.secondary }}>Carregando</h2>
        <ReactLoading
            type="spin"
            color={colors.secondary}
            height="10%"
            width="10%"
        />
    </Container>
);

export default Loading;
