import React from 'react';
import { Container } from './styles';

const Dashboard: React.FC = () => {
    return (
        <Container>
            <h1>BEM VINDO A LEVENS</h1>
        </Container>
    );
};

export default Dashboard;
