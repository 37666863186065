import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal, Row, Col } from 'react-bootstrap';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Loading from '../../../components/Loading';
import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from '../styles';
import { FiEdit } from 'react-icons/fi';
import { useAuth } from '../../../hooks/auth';

export interface ModalFuncionarioHandles {
    handleShowModalFuncionario: (idGrupo: string, grupoNome: string) => void;
}

interface ModalProps {
}

interface Funcionario {
    id: number;
    nome: string;
    email: string;
    ativo: Boolean;
    associado: Boolean;
    gruposDeAcesso: GrupoAcesso[];
}
 interface GrupoAcesso {
     id: string;
     nome: string;
 }

const ModalFuncionario = forwardRef<ModalFuncionarioHandles, ModalProps>((props, ref) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToast();
    const [funcionarios, setFuncionarios] = useState<Funcionario[]>([] as Funcionario[],);
    var grupoId: string = '';
    var grupoNome: string = '';

    const  {user} = useAuth()

    const handleShowModal = useCallback(async (idGrupo: string, nomeGrupo: string) => {
        setShow(true);
        grupoId = idGrupo;
        grupoNome = nomeGrupo;
        await getFuncionarios(idGrupo);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShow(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModalFuncionario: handleShowModal
        };
    });

    const getFuncionarios = useCallback(async (idGrupo: string | undefined) => {
        try {
            setLoading(true);

            const responseFuncaionarioGrupo: Funcionario[] = await api.get(`/api/v1/painel/permissao/grupo-acesso/usuarios/${idGrupo}`).then((response) => {
                return response.data.data;
            });

            const response = await api.get(`/api/v1/painel/funcionario`);

            const { data } = response;

            const helper: Funcionario[] = data.data;

            const funcionariosAll: Funcionario[] = helper
                .filter(funcionario => funcionario.ativo)
                .filter(funcionario => funcionario.gruposDeAcesso.length > 0 ? funcionario.gruposDeAcesso[0].id === idGrupo : funcionario.gruposDeAcesso.length === 0)
                .map((funcionario) => {
                    funcionario.associado = responseFuncaionarioGrupo.findIndex((func) => {
                        return func.email.trim().toUpperCase() == funcionario.email.trim().toUpperCase();
                    }) != -1 ? true : false;

                    return funcionario;
                });

            setFuncionarios(funcionariosAll);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    'Não conseguimos exibir as telas, tente novamente!',
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    const associar = useCallback(async (email: string) => {
        try {
             await api.post(`/api/v1/painel/permissao/grupo-acesso/usuario/associar`,{}, {
                headers: {
                'idGrupo': grupoId,
                'email': email,
            },});

            await getFuncionarios(grupoId);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    `Erro ao associar funcionário, tente novamente! \n ${error.message}`,
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    const desassociar = useCallback(async (email: string) => {
        if (user?.tipo === 'Administrador' && email === user?.login) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: `Não é possível dessassociar o próprio usuário administrador`});
        }

        try {
            await api.post(`/api/v1/painel/permissao/grupo-acesso/usuario/desassociar`,{}, {
                headers: {
                'idGrupo': grupoId,
                'email': email,
            },});

            await getFuncionarios(grupoId);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    `Erro ao desassociar funcionário, tente novamente! \n ${error.message}`,
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    return (
        <Modal show={show} onHide={handleCloseModal} size="xl">
            <Modal.Header closeButton>
                <h5>Funcionários</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <span>Carregando...</span>
                ) : (
                    <List responsive>
                        <Header>
                            <tr>
                                <th style={{ width: '5%' }}>Id</th>
                                <th style={{ width: '30%' }}>Nome</th>
                                <th style={{ width: '30%' }}>Email</th>
                                <th style={{ width: '10%' }}>Ativo?</th>
                                <th style={{ width: '10%' }}>Associado?</th>
                                <th style={{ width: '15%' }}></th>
                            </tr>
                        </Header>
                        <Content>
                            {funcionarios.map((funcionario) => (
                                <tr key={funcionario.id}>
                                    <td>{funcionario.id}</td>
                                    <td>{funcionario.nome}</td>
                                    <td>{funcionario.email}</td>
                                    <td>{funcionario.ativo ? (<span>SIM</span>) : (<span>NÃO</span>)}</td>
                                    <td>{funcionario.associado ? (<span>SIM</span>) : (<span>NÃO</span>)}</td>
                                    <td>
                                        <div className="row" style={{ padding: 0, margin: 0 }}>
                                            {funcionario.associado ?
                                                (
                                                <div className="col-md-12" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.danger}
                                                        textcolor={colors.light}
                                                        onClick={() => desassociar(funcionario.email)}>
                                                        <FiEdit
                                                            color={colors.light}
                                                        />
                                                        Desassociar
                                                    </Button>
                                                </div>
                                                )
                                                :
                                                (
                                                <div className="col-md-12" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.primary}
                                                        textcolor={colors.light}
                                                        onClick={() => associar(funcionario.email)}>
                                                        <FiEdit
                                                            color={colors.light}
                                                        />
                                                        Associar
                                                    </Button>
                                                </div>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </Content>
                    </List>
                )}
            </Modal.Body>
        </Modal>
    );
});

export default ModalFuncionario;
