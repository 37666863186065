import React from 'react'
import Button from '../../components/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { colors } from '../../styles/variables';

interface IProps {
  children: JSX.Element;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  size?: 'sm' | 'lg' | 'xl';
  title?: string;
}

const Index = ({
  children,
  show,
  setShow,
  size = 'lg',
  title,
}: IProps
) => {

  return (
    <>
      <Modal size={size} show={show} onHide={() => setShow(false)}>
        {
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        }
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  )
}

export default Index
