import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { colors } from '../../../styles/variables';

interface ToastProps {
    type?: 'success' | 'error' | 'info';
    description?: number;
}
const toastTypeVariations = {
    info: css`
        background: ${colors.info};
        color: ${colors.dark};
    `,
    success: css`
        background: ${colors.success};
        color: ${colors.light};
    `,
    error: css`
        background: ${colors.danger};
        color: ${colors.light};
    `,
};

export const Container = styled(animated.div)<ToastProps>`
    width: 360px;
    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    & + & {
        margin-top: 8px;
    }

    display: flex;

    ${(props) => toastTypeVariations[props.type || 'info']}

    > svg {
        margin: 4px 12px 0 0;
    }

    div {
        flex: 1;
        p {
            margin-top: 4px;
            font-size: 14px;
            opacity: 0.8;
            line-height: 20px;
        }
    }
    button {
        position: absolute;
        right: 16px;
        top: 19px;
        opacity: 0.6;
        border: 0;
        background: transparent;
        color: inherit;
    }

    ${(props) =>
        !props.description &&
        css`
            align-items: center;
            svg {
                margin-top: 0;
            }
        `}
`;
