import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import BSContainer from 'react-bootstrap/Container';
import BSCard from 'react-bootstrap/Card';

export const Container = styled(BSContainer)`
    margin-top: 24px;
    margin-bottom: 500px;
    hr {
        background: #333333;
    }
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Form = styled(Unform)`
    div[class*='col'] {
        margin-bottom: 8px;
        margin-top: 8px;
    }
    input:disabled,
    select:disabled {
        color: #333;
        opacity: 1;
    }
    .ebtvWG,
    .react-select__control--is-disabled,
    .gRCImp,
    .fvIpSB {
        background: #eee;
    }
`;
