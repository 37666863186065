import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconBaseProps } from 'react-icons';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

interface ILink {
  title: string;
  link: string;
}

interface DropdownProps {
  Main: {
    title: string;
    Icon: React.ComponentType<IconBaseProps>;
  };
  options: ILink[];
}

export function Dropdown({ Main, options }: DropdownProps) {
  const [dropdownVisible, setMenuVisible] = useState(false);

  const handleSetDropdown = () => {
    setMenuVisible(old => !old);
  };

  return (
    <>
      <button type="button" onClick={handleSetDropdown}>
        <Main.Icon size={24} />
        {Main.title}
        {!dropdownVisible
            ? <TiArrowSortedDown className="ml-1" size={20} />
            : <TiArrowSortedUp className="ml-1" size={20} />
        }
      </button>
      {dropdownVisible && (
        <div>
          <ul>
            {options.map(Option => (
              <li key={Option.link}>
                <Link
                  to={Option.link}
                  className="item-dropdown pl-5"
                >
                  {Option.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
