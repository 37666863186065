import React from 'react';

import Pagination from 'react-js-pagination';
import { Container } from './styles';

interface PageProps {
    currentPage: number;
    totalPages: number;
    perPage: number;
    onChangePage(page: number): void;
}

const PaginationComponent: React.FC<PageProps> = ({
    currentPage,
    totalPages,
    onChangePage,
    perPage,
}) => {
    function handleCurrentPageChange(page: number): void {
        onChangePage(page);
    }

    if (!totalPages || totalPages === 1) return null;

    return (
        <nav aria-label="Page navigation example">
            <Container>
                <Pagination
                    activePage={currentPage}
                    onChange={handleCurrentPageChange}
                    totalItemsCount={totalPages * perPage}
                    itemsCountPerPage={perPage}
                    pageRangeDisplayed={5}
                    linkClass="page-link"
                    firstPageText="Primeira"
                    lastPageText="Ultima"
                    prevPageText="Anterior"
                    nextPageText="Próxima"
                    itemClass="page-item"
                />
            </Container>
        </nav>
    );
};

export default PaginationComponent;
