import { CircularProgress, Icon } from '@mui/material';
import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';
import { colors } from '../../styles/variables';
import { FiSend } from 'react-icons/fi';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  textcolor: string;
  textDisabled?: string
}


const Button: React.FC<ButtonProps> = ({ children, ...props }) => {

  return (
    <Container type="button" {...props} >
      {
        props.disabled ?
          props.textDisabled ? <><FiSend color='transparent' />{props.textDisabled}</> :  'Carregando'
          : children
      }
    </Container>
  );
};

export default Button;
