import styled, { keyframes } from 'styled-components';
import BootstrapContainer from 'react-bootstrap/Container';
import { shade } from 'polished';
import signInBackgroundImg from '../../assets/sign-in-background.jpg';
import { colors } from '../../styles/variables';

export const Container = styled(BootstrapContainer)`
    padding: 0;
    height: 100vh;
    display: flex;
    align-items: stretch;
    background: ${colors.secondary};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
`;
export const Background = styled.div`
    flex: 1;
    background: linear-gradient(
            to bottom,
            rgba(63, 154, 102, 0.3),
            rgba(0, 0, 0, 0.3)
        ),
        url(${signInBackgroundImg}) no-repeat right;
    background-size: cover;
`;

const appearFromLeft = keyframes`
     from{
        opactiy:0;
        transform:translateX(-100px);
     }
     to{
         opactiy:1;
         transform:translateX(0)
     }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
     img{
         width:248px;
         height:auto;
     }
    animation ${appearFromLeft} 1s;
    form {
        margin: 50px 0;
        width: 325px;
        text-align: center;
        h1 {
            margin-bottom: 24px;
        }

        a {
            color: ${colors.dark};
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, `${colors.dark}`)};
            }
        }
    }

    a {
        color: ${colors.primary};
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;
        display: flex;
        align-items: center;
        svg {
            margin-right: 16px;
        }
        &:hover {
            color: ${shade(0.2, `${colors.primary}`)};
        }
    }
`;
