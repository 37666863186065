import React from 'react'
import Swal from 'sweetalert2'
import { colors } from '../../styles/variables'

const Confirm = async () => {
  return Swal.fire({
    title: 'Você tem certeza?',
    // text: 'Este processo não poderá ser desfeito',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: colors.primary,
    cancelButtonColor: '#eee',
    confirmButtonText: 'Confirmar!',
    cancelButtonText: 'Cancelar',
  })
}

export default Confirm
