import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import BSContainer from 'react-bootstrap/Container';
import BSCard from 'react-bootstrap/Card';

export const Container = styled(BSContainer)`
    margin-top: 24px;
    margin-bottom: 500px;
    hr {
        background: #333333;
    }

    .dropzone-container {
        .dropzone {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            border-width: 2;
            border-radius: 2;
            border-color: #bdbdbd;
            border-style: dashed;
            background-color: #dedede47;
            color: #5a5a5a;
            outline: none;
            transition: border 0.24s ease-in-out;
        }

        aside {
            margin-top: 16px;
        }
    }
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Form = styled(Unform)`
    div[class*='col'] {
        margin-bottom: 8px;
        margin-top: 8px;
    }
    input {
        border: #dfdfdf;
    }
    input[readonly] {
        background-color: #dddddd;
    }
`;
