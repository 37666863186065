import React, { Fragment } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { IRelatorioVagas } from '../../../interfaces/IRelatorio';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: "center",
        padding: '6px 0',
        borderTop: '1px solid #ccc',
        marginTop: '4px',
        fontSize: '8.5px',
    },
    name: {
        width: '80%',
    },
    th: {
        alignItems: "center",
        width: '75%',
    },
});

interface RowProps {
    relatorios: IRelatorioVagas[];
}

export const Row = ({ relatorios }: RowProps) => {
    const rows = relatorios.map( item =>
        <View style={styles.row} key={item.idAgenda}>
            <Text style={styles.name}>{item.nomeProfissional}</Text>
            <Text style={styles.th}>{item.codigoVaga}</Text>
            <Text style={styles.th}>{item.dataServico}</Text>
            <Text style={styles.th}>{item.horarioDescricao}</Text>
            <Text style={styles.th}>{item.valorVagaFormatado}</Text>
            <Text style={styles.th}>{item.valorEmpresaVagaFormatado}</Text>
            <Text style={styles.th}>{item.valorTotalFormatado}</Text>
        </View>
    )

    return (
        <Fragment>
            <View style={styles.row}>
                <Text style={styles.name}>Profissional</Text>
                <Text style={styles.th}>Número vaga</Text>
                <Text style={styles.th}>Data serviço</Text>
                <Text style={styles.th}>Horário serviço</Text>
                <Text style={styles.th}>Valor Profissional</Text>
                <Text style={styles.th}>Valor Empresa</Text>
                <Text style={styles.th}>Valor Total</Text>
            </View>
            {rows}
        </Fragment>
    )
}
