import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FiPlus, FiTrash, FiEdit } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';
import { colors } from '../../styles/variables';
import ModalNewGrupo, { ModalNewGrupoHandles } from './ModalNewGrupo';
import ModalFuncionario, { ModalFuncionarioHandles } from './ModalFuncionario';


import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';

interface GrupoAcessoProps {
    id: string;
    nome: string;
}

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/grupo-acesso',
        active: true,
    },
];

interface Grupo {
    idGrupo: string;
}

const GrupoAcesso: React.FC = () => {
    const modalNewGrupoRef = useRef<ModalNewGrupoHandles>(null);
    const modalFuncionarioRef = useRef<ModalFuncionarioHandles>(null);
    const [grupos, setGrupos] = useState<GrupoAcessoProps[]>([] as GrupoAcessoProps[],);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToast();

    const getGrupos = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api.get(`/api/v1/painel/permissao/lista-grupos-simples`);

            const { data } = response;

            setGrupos(data.data);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    'Não conseguimos exibir as telas, tente novamente!',
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    useEffect(() => {
        getGrupos();
    }, [getGrupos]);

    const handleOpenModalNewGrupo = useCallback(() => {
        modalNewGrupoRef.current?.handleShowModalNewGrupo();
    }, []);

    const handleOpenModalFuncionario = useCallback((idGrupo: string, grupoNome: string) => {
        modalFuncionarioRef.current?.handleShowModalFuncionario(idGrupo, grupoNome);
    }, []);

    const handleDeleteGrupo = useCallback((idGrupo: string) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Deseja realmente realizar esta operação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Fechar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/api/v1/painel/permissao/grupo-acesso/${idGrupo}`);
                    getGrupos();
                    addToast({
                        type: 'success',
                        title: 'Operação realizada com sucesso!',
                    });
                } catch {
                    addToast({
                        type: 'error',
                        title: 'Ooops! Algo deu errado!',
                        description: 'Erro ao excluir o grupo de acesso',
                    });
                }
            }
        });
    }, [addToast]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            {loading ? (
                <Loading />
            ) : (
                <Card>
                    <Card.Header>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col md={10}>
                                    <h5>Listagem de Grupos de Acesso</h5>
                                </Col>
                                <Col md={2} className="pull-right">
                                    <Options>
                                        <OptionButton
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() => handleOpenModalNewGrupo()}>
                                            <FiPlus color={colors.light} />
                                            Adicionar
                                        </OptionButton>
                                        <ModalNewGrupo ref={modalNewGrupoRef} onClose={getGrupos} />
                                        <ModalFuncionario ref={modalFuncionarioRef} />
                                    </Options>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <List responsive>
                            <Header>
                                <tr>
                                    <th style={{ width: '30%' }}>Id</th>
                                    <th style={{ width: '40%' }}>Nome</th>
                                    <th style={{ width: '30%' }}></th>
                                </tr>
                            </Header>
                            <Content>
                                {grupos.map((grupo) => (
                                    <tr key={grupo.id}>
                                        <td>{grupo.id}</td>
                                        <td>{grupo.nome}</td>
                                        <td>
                                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Link
                                                        to={`/permissoes/${grupo.id}`}
                                                    >
                                                        <Button
                                                            type="button"
                                                            color={colors.primary}
                                                            textcolor={colors.light}

                                                            onClick={() => { return console.log() }}>
                                                            <FiEdit
                                                                color={colors.light}
                                                            />
                                                            Permissões
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.info}
                                                        textcolor={colors.light}
                                                        onClick={() => handleOpenModalFuncionario(grupo.id, grupo.nome)}>
                                                        <FiEdit
                                                            color={colors.light}
                                                        />
                                                        Funcionários
                                                    </Button>
                                                </div>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.danger}
                                                        textcolor={colors.light}
                                                        onClick={() => handleDeleteGrupo(grupo.id)}
                                                    >
                                                        <FiTrash
                                                            color={colors.light}
                                                        />
                                                        Excluir
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

export default GrupoAcesso;
