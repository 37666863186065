export enum colors {
    // 'primary' = '#00A167',
    'primary' = '#3796bc',
    'secondary' = '#ededed',
    'dark' = '#333333',
    'light' = '#EDF1F6',
    'info' = '#2082FF',
    'danger' = '#FF7070',
    'success' = '#32a852',
    'primary2' = '#2F96ab',
}
