import React, {
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiPhone } from 'react-icons/fi';
import { Modal, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import IExperiencia from '../../../interfaces/IExperiencia';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import Button from '../../../components/Button';
import IComentario from '../../../interfaces/IComentario';

export interface ModalComentarioHandles {
    handleShowModal: () => void;
}

interface ModalProps {
    setComentario: (comentario: IComentario) => void;
    comentario?: IComentario;
}

const ModalComentario: React.RefForwardingComponent<
    ModalComentarioHandles,
    ModalProps
> = ({ setComentario, comentario }, ref) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);

    const handleShowModal = useCallback(() => {
        setShow(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShow(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModal,
        };
    });

    const handleSubmit = useCallback(
        async (data: IComentario) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    descricao: Yup.string().required('Descrição é obrigatório'),

                });

                const dataToSend = parseObjectTypes(data, [
                    'descricao',
                ]);

                await schema.validate(dataToSend, { abortEarly: false });

                dataToSend.id = comentario?.id || uuid();
                setComentario(dataToSend);
                setShow(false);

                addToast({
                    type: 'success',
                    title: 'Comentário adicionado com sucesso',
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar, tente novamente',
                    });
                }
            }
        },
        [addToast, setComentario, comentario],
    );

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <h5>Adicionar comentário</h5>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} initialData={comentario} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Input
                                name="descricao"
                                type="text"
                                placeholder="Descrição"
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Button
                                type="submit"
                                color={colors.primary}
                                textcolor={colors.light}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default forwardRef(ModalComentario);
