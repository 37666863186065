import styled from 'styled-components';

export const Box = styled.div`
    h6 {
        padding: 10px 5px;
        background: rgba(1, 1, 1, 0.1);
        font-weight: bold;
    }

    strong {
        font-weight: bold;
        margin-right: 4px;
    }

    p {
        padding: 0 5px;
    }

    ul {
        list-style: none;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        li {
            width: auto;
            background: rgba(1, 1, 1, 0.1);
            padding: 5px;

            & + li {
                margin-left: 5px;
            }
        }
    }
`;
