import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import * as Yup from 'yup';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { colors } from '../../styles/variables';
import { Container, Content, Background, AnimationContainer } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface ForgotPasswordRequest {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const history = useHistory();

    const handleSubmit = useCallback(
        async (data: ForgotPasswordRequest) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido!'),
                });

                await schema.validate(data, { abortEarly: false });

                await api.post('/api/v1/painel/auth/recuperar-senha', data);

                addToast({
                    type: 'success',
                    title: 'Sucesso',
                    description:
                        'Solicitação realizada com sucesso. Verifique sua caixa de e-mail.',
                });

                history.push('/');
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    formRef.current?.setErrors(errors);
                } else if (err?.response) {
                    const { errors } = err.response.data;
                    errors.forEach((err: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro',
                            description: err,
                        });
                    });
                }
            }
        },
        [addToast, history],
    );
    return (
        <Container fluid>
            <Content>
                <AnimationContainer>
                    <img src={logoImg} alt="DeliveryPlus" />
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h2>Recuperar senha</h2>
                        <Input
                            icon={FiMail}
                            name="email"
                            type="text"
                            placeholder="E-mail"
                        />
                        <Button
                            style={{ marginTop: '16px' }}
                            type="submit"
                            color={colors.primary}
                            textcolor={colors.secondary}
                        >
                            Recuperar senha
                        </Button>
                        {
                            <a
                                onClick={() => history.push('/')}
                                href="javascript:void()"
                            >
                                Voltar
                            </a>
                        }
                    </Form>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default ForgotPassword;
