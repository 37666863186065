import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import Menu from './Menu';

import { colors } from '../../styles/variables';
import { Wrapper, Content, NavBar, CollapseButton } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
    const [active, setActive] = useState(false);

    return (
        <Wrapper>
            <Menu active={active} />
            <Content>
                <NavBar>
                    <CollapseButton
                        color="transparent"
                        textcolor={colors.light}
                        type="button"
                        onClick={() => setActive(!active)}
                    >
                        <FiMenu color={colors.light} size={24} />
                    </CollapseButton>
                </NavBar>
                {children}
            </Content>
        </Wrapper>
    );
};

export default DefaultLayout;
