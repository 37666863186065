export default function parseObjectTypes(
    obj: any,
    exceptions: Array<string> = [],
) {
    const keys = Object.keys(obj);
    const returnObj: any = {};
    keys.forEach((key) => {
        if (exceptions.findIndex((i) => key === i) !== -1) {
            returnObj[key] = obj[key];
        } else if (typeof obj[key] === 'object') {
            returnObj[key] = parseObjectTypes(obj[key], exceptions);
        } else if (isDate(obj[key])) {
            returnObj[key] = obj[key];
        } else if (isNumber(obj[key])) {
            returnObj[key] = parseFloat(obj[key]);
        } else if (checkIsBoolean(obj[key])) {
            returnObj[key] = obj[key] === 'true';
        } else {
            returnObj[key] = obj[key].replace(/[&\\#,+()$~%.'":*?<>{}-]/gi, '');
        }
    });

    return returnObj;
}

function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

function isDate(d: string) {
    const dateRegexp = /(\d{4})-(\d{2})-(\d{2})/;
    if (typeof d !== 'string') return false;
    return !!d.match(dateRegexp);
}
function checkIsBoolean(n: any) {
    return n === 'true' || n === 'false';
}
