import styled from 'styled-components';

import BSBreadcrumb from 'react-bootstrap/Breadcrumb';

import { colors } from '../../styles/variables';

export const Container = styled(BSBreadcrumb)`
    .active {
        a {
            color: ${colors.secondary} !important;
        }
    }
    .breadcrumb {
        box-shadow: 2px 2px 5px #00000082;

        background: ${colors.primary};
        a {
            color: ${colors.light};
        }
    }
`;
