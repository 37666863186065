import React from 'react';
import Image from 'react-bootstrap/Image';
import {
    FiPieChart,
    FiLogOut,
    FiUserCheck,
    FiUsers,
    FiUser,
    FiBriefcase,
    FiGrid,
    FiMessageSquare,
} from 'react-icons/fi';
import { BsFillBookmarksFill } from 'react-icons/bs';
import { RiFileList3Line, RiFileCopy2Line } from 'react-icons/ri';
import { FaUserNurse, FaHandsHelping } from 'react-icons/fa';
import { AiFillUnlock, AiFillFlag } from 'react-icons/ai';
import { useAuth, Funcoes } from '../../../hooks/auth';
import { Dropdown } from './Dropdown';
import logoImg from '../../../assets/logo-nova-branca-completa.png';
import { colors } from '../../../styles/variables';

import {
    Sidebar,
    SidebarHeader,
    SideMenu,
    Item,
    MenuLink,
    LeaveButton,
} from './styles';

interface MenuProps {
    active: boolean;
}
const menuItens = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        Icon: FiPieChart,
        permission: Funcoes.all,
    },
    {
        name: 'Unidades',
        link: '/unidades',
        Icon: FiGrid,
        permission: Funcoes.Unidade,
    },
    {
        name: 'Funcionarios',
        link: '/funcionarios',
        Icon: FiUsers,
        permission: Funcoes.Funcionario,
    },
    // {
    //     name: 'Clientes',
    //     link: '/clientes',
    //     Icon: FiUserCheck,
    //     permission: Roles.administrador,
    // },
    {
        name: 'Responsaveis',
        link: '/clientes/responsaveis',
        Icon: FaHandsHelping,
        permission: Funcoes.Responsavel,
    },
    {
        name: 'Listagem de Beneficiarios',
        link: '/clientes/beneficiarios',
        Icon: FiUserCheck,
        permission: Funcoes.Beneficiario,
    },
    {
        name: 'Relatório de Beneficiários',
        link: '/relatorios/beneficiarios',
        Icon: RiFileCopy2Line,
        permission: Funcoes.RelatorioBeneficiarios,
    },
    // {
    //     name: 'Pré Cadastros',
    //     link: '/precadastro',
    //     Icon: FaUserNurse,
    //     permission: Roles.administrador,
    // },
    {
        name: 'Profissionais',
        link: '/profissionais',
        Icon: FaUserNurse,
        permission: Funcoes.Profissional,
    },
    {
        name: 'Pré Cadastros',
        link: '/precadastro',
        Icon: FaUserNurse,
        permission: Funcoes.PreCadastro,
    },
    {
        name: 'Profissões',
        link: '/profissoes',
        Icon: FiBriefcase,
        permission: Funcoes.Profissao,
    },
    {
        name: 'Vagas',
        link: '/vagas',
        Icon: BsFillBookmarksFill,
        permission: Funcoes.Vaga,
    },
    {
        name: 'Faturas',
        link: '/faturas',
        Icon: RiFileList3Line,
        permission: Funcoes.Fatura,
    },
    // {
    //     name: 'Categorias',
    //     link: '/categories',
    //     Icon: FiList,
    //     permission: Funcoes.all,
    // },
    // {
    //     name: 'Produtos',
    //     link: '/products',
    //     Icon: FiBox,
    //     permission: Funcoes.all,
    // },
    // {
    //     name: 'Pedidos',
    //     link: '/sales',
    //     Icon: FiFolderPlus,
    //     permission: Funcoes.all,
    // },
    {
        name: 'Comunicados',
        link: '/comunicados',
        Icon: FiMessageSquare,
        permission: Funcoes.admin,
    },
    {
        name: 'Banner',
        link: '/banner',
        Icon: AiFillFlag,
        permission: Funcoes.admin,
    },
    {
        name: 'Grupos de Acesso',
        link: '/grupo-acesso',
        Icon: AiFillUnlock,
        permission: Funcoes.admin,
    },
];
const menuDropdown = [
    {
        link: '/relatorios/comissao',
        title: 'Taxa de agenciamento',
        permission: Funcoes.RelatorioTaxaAgenciamento,
    },
    {
        link: '/relatorios/taxas',
        title: 'Taxa da empresa',
        permission: Funcoes.RelatorioTaxaEmpresa,
    },
    {
        link: '/relatorios/envios',
        title: 'Envios Whatsapp',
        permission: Funcoes.RelatorioEnviosWhatsapp,
    },
    {
        link: '/relatorios/nfe/profissionais',
        title: 'NFEs Profissional',
        permission: Funcoes.RelatorioNfeProfissional,
    },
    {
        link: '/relatorios/nfe/responsaveis',
        title: 'NFEs Responsáveis',
        permission: Funcoes.RelatorioNfeResponsavel,
    },
];
const Menu: React.FC<MenuProps> = ({ active }) => {
    const { signOut, hasPermission } = useAuth();

    const menuToRender = menuItens.filter((item) =>
        item.permission !== Funcoes.RelatorioBeneficiarios &&
        hasPermission(item.permission),
    );

    const dropdownToRender = menuDropdown.filter((item) => {
        const dropdownItem = hasPermission(item.permission);
        delete Item.permission;
        return dropdownItem;
    });

    const beneficiarioDropdown = menuItens
        .filter((item) => (
            item.permission === Funcoes.Beneficiario ||
            item.permission === Funcoes.RelatorioBeneficiarios) &&
            hasPermission(item.permission)
            )
        .map((item) => ({
        link: item.link,
        title: item.name,
        }));
    return (
        <Sidebar active={active}>
            <SidebarHeader>
                <Image fluid src={logoImg} />
            </SidebarHeader>

            <SideMenu>
                {menuToRender.map(({ link, Icon, name, permission }) => (
                    <Item key={link}>
                        {permission === Funcoes.Beneficiario ? (
                            <Dropdown
                                Main={{
                                    Icon: FiUser,
                                    title: 'Beneficiarios',
                                }}
                                options={beneficiarioDropdown}
                            />
                        ) : (
                            <MenuLink to={link}>
                                <Icon size={24} color={colors.light} />
                                {name}
                            </MenuLink>
                        )}
                    </Item>
                ))}

                <Item>
                    <Dropdown
                        Main={{ Icon: RiFileCopy2Line, title: 'Relatórios' }}
                        options={dropdownToRender}
                    />
                </Item>

                <hr />
                <Item>
                    <LeaveButton type="button" onClick={signOut}>
                        <FiLogOut size={24} color={colors.light} />
                        Sair
                    </LeaveButton>
                </Item>
            </SideMenu>
        </Sidebar>
    );
};

export default Menu;
