import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../styles/variables';
import Tooltip from '../Tooltip';

export const Container = styled.div`
    margin-top: 8px;
    label {
        font-size: 16px;
    }
    color: #222222;
`;

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const SelectContainer = styled.div<ContainerProps>`
    background: #fff;
    padding: 10px 8px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #DFDFDF;
    border-radius: 5px;

    & + & {
        margin-top: 8px;
    }


    ${(props) =>
        props.isErrored &&
        css`
            border-color: ${colors.danger};
            box-shadow: 0 0 0 3px ${transparentize(0.7, colors.danger)};
        `}
    ${(props) =>
        props.isFocused &&
        css`
            svg {
                color: ${colors.primary};
            }
            border-color: ${colors.primary};
            box-shadow: 0 0 0 2px ${transparentize(0.7, colors.primary)};
        `}
    ${(props) =>
        props.isFilled &&
        css`
            svg {
                color: ${colors.primary};
            }
        `}

    select {
        flex: 1;
        border: 0;
        width: 100%;
        background: transparent;
        color: ${colors.dark};
        &::placeholder {
            color: ${transparentize(0.4, colors.dark)};
        }
    }
`;

export const Error = styled(Tooltip)`
    display: flex;
    margin-left: 8px;
    span {
        background: ${colors.danger};
        color: #fff;
        &::before {
            border-color: ${colors.danger} transparent;
        }
    }
    svg {
        margin: 0;
    }
`;
