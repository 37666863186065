import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { FiAtSign, FiCheck, FiExternalLink } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { MdClose } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import api from '../../../services/api';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import formatValue from '../../../helpers/formatValue';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input';
import Select, { SelectProps } from '../../../components/SelectInput';
import ReactSelectInput from '../../../components/ReactSelect';
import Breadcrumb from '../../../components/Breadcrumb';
import Button from '../../../components/Button';
import CurrencyInput from '../../../components/CurrencyInput';
import AsyncReactSelectInput from '../../../components/AsyncReactSelect';
import ILogStatus from '../../../interfaces/ILogStatus';
import { colors } from '../../../styles/variables';
import { Container, Card, Form, ItemTable } from './styles';
import Swal from 'sweetalert2';

interface PreloaProps {
    id: number;
    nome: string;
}

interface AptidoesProps {
    vagaId: number;
    aptidaoId: number;
    nome: string;
    // aptidao: {
    //     id: number;
    //     nome: string;
    // };
}
interface EventCurrent {
    floatValue: number;
    formattedValue: string;
    value: string;
}

interface IAgenda {
    data: string;
    pago: boolean;
    valor?: string;
}

interface ICandidato {
    isAtivo: boolean;
    dataCadastro: string;
    nome: string;
    id: number;
}

const FormVaga: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const [selectedDays, setSelectedDays] = useState<Date[]>([]);
    const [aptidoes, setAptidoes] = useState<SelectProps[]>([]);
    const [profissoes, setProfissoes] = useState<SelectProps[]>([]);
    const [sexo, setSexo] = useState<SelectProps[]>([]);
    const [tipos, setTipos] = useState<SelectProps[]>([]);
    const [horarios, sethorarios] = useState<SelectProps[]>([]);
    const [agenda, setAgenda] = useState<IAgenda[]>([]);
    const [vaga, setVaga] = useState({});
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [unidades, setUnidades] = useState<SelectProps[]>([]);
    const [status, setStatus] = useState('');
    const [candidatos, setCandidatos] = useState<SelectProps[]>([]);
    const [isDisabledProfs, setIsDisabledProfs] = useState(false);
    const [candidatosShow, setCandidatosShow] = useState<ICandidato[]>([]);
    const [candidatoSelecionado, setCandidatoSelecionado] = useState<number>(0);
    const [logStatus, setLogStatus] = useState<ILogStatus[]>([]);

    useEffect(() => {
        api.get('api/v1/painel/unidade/usuario').then(({ data }) => {
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
        });

        api.get('/api/v1/painel/vaga/preload').then(({ data }) => {
            const dados = data.data;

            setAptidoes(
                dados.aptidoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );

            setProfissoes(
                dados.profissoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );

            sethorarios(
                dados.horarios.map((item: any) => ({
                    label: item.descricao,
                    value: item.id,
                })),
            );

            setSexo(
                dados.sexo.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );

            setTipos(
                dados.tipos.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
        });
    }, []);

    useEffect(() => {
        if (id) {
            setLoading(true);
            api.get(`/api/v1/painel/vaga/${id}`).then(({ data }) => {
                const dados = {
                    ...data.data,
                    valor: data.data.valor * 100,
                    valorEmpresa: data.data.valorEmpresa * 100,
                };
                const options = dados.aptidoes.map((ap: AptidoesProps) => {
                    return {
                        value: ap.aptidaoId,
                        label: ap.nome,
                    };
                });
                dados.aptidoes = options;

                let candts = dados.candidatos.map((item: any) => ({
                    label: item.isAtivo
                        ? item.nome
                        : item.nome + ' (Cadastro Inativo)',
                    value: item.id,
                }));

                candts.unshift({
                    label: 'Nenhum',
                    value: '',
                });

                setCandidatos(candts);

                setCandidatosShow(dados.candidatos);

                setSelectedDays(
                    dados.agenda.map((ag: { data: string }) =>
                        parseISO(ag.data),
                    ),
                );
                setVaga(dados);
                setAgenda(
                    dados.agenda.map((item: any) => ({
                        data: format(parseISO(item.data), 'dd/MM/yyyy'),
                        pago: item.pago,
                        valor: formatValue(item.valorTotal),
                    })),
                );
                setStatus(dados.vagaStatus.nome);
                setLogStatus(dados.logsStatus);
                console.log(dados);
                setLoading(false);
            });
        }
    }, [id]);

    const FormataStringData = useCallback((data) => {
        const dia = data.split('/')[0];
        const mes = data.split('/')[1];
        const ano = data.split('/')[2];

        return `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`;
    }, []);

    const handleSubmit = useCallback(
        async (data: any) => {
            try {
                const [, valor] = data.valor.split(' ');
                const [, valorEmpresa] = data.valorEmpresa.split(' ');
                data = {
                    ...data,
                    valor,
                    valorEmpresa,
                };

                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    titulo: Yup.string()
                        .max(14, 'O campo precisa ter no máximo 14 caracteres')
                        .required('Título é obrigatório'),
                    vagaTipoId: Yup.number().required(
                        'Tipo de vaga é obrigatória',
                    ),
                    caracteristicaPaciente: Yup.string().required(
                        'Caracterítica do paciente é obrigatório',
                    ),
                    requisitos: Yup.string().required(
                        'Requisitios é obrigatório',
                    ),
                    beneficios: Yup.string().required(
                        'Benefício é obrigatório',
                    ),
                    local: Yup.string().required('Local é obrigatório'),
                    horarioDescricao: Yup.string().required(
                        'Horário é obrigatório',
                    ),
                    valorEmpresa: Yup.string().required(
                        'Valor Empresa é obrigatório',
                    ),
                    valor: Yup.string().required('Salário é obrigatório'),
                    taxa: Yup.string().required('Taxa é obrigatório'),
                    beneficiarioId: Yup.number().required(
                        'Informar o beneficiario',
                    ),
                    // profissionalId: Yup.number().required(
                    //     'Informar o profissional',
                    // ),
                    profissaoId: Yup.number().required(
                        'Profissão é obrigatório',
                    ),
                    unidadeId: Yup.number().required('Unidade é obrigatório'),
                    horarioId: Yup.number().required('Horário é obrigatório'),
                    sexoId: Yup.number().required('Sexo é obrigatório'),
                    isCnh: Yup.boolean().required(
                        'Informar se possui habilitação',
                    ),
                    isPossuiVeiculo: Yup.boolean().required(
                        'Informar se possui veículo',
                    ),
                });

                await schema.validate(data, { abortEarly: false });
                console.log(data, 'data');

                if (data.profissionalId == undefined) {
                    data.profissionalId = '';
                }

                const dataToSend = parseObjectTypes(data, [
                    'titulo',
                    'local',
                    'horario',
                    'beneficios',
                    'requisitos',
                    'caracteristicaPaciente',
                    'aptidoes',
                ]);
                dataToSend.valor /= 100;
                dataToSend.valorEmpresa /= 100;

                const daysFormatted = agenda.map((dia) => {
                    return FormataStringData(dia.data);
                });

                if (id) {
                    dataToSend.id = Number(id);
                    await api.put(`/api/v1/painel/vaga/${id}`, {
                        ...dataToSend,
                        dias: daysFormatted,
                    });
                } else {
                    await api.post('/api/v1/painel/vaga', {
                        ...dataToSend,
                        dias: daysFormatted,
                    });
                }
                addToast({
                    type: 'success',
                    title: 'Vaga Cadastrada com sucesso',
                });
                history.push('/vagas');
            } catch (err) {
                console.log(err);
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err.response) {
                    const { errors } = err.response.data;

                    errors.forEach((error: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: error,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar o profissional',
                    });
                }
            }
        },
        [addToast, history, id, agenda, FormataStringData],
    );

    const handleGetProfissionais = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/profissional?nome=${inputValue}`,
                );
                const dados = data.data;

                var profs = dados.profissionais.map((item: any) => ({
                    label: item.isAtivo
                        ? item.nome
                        : item.nome + ' (Cadastro Inativo)',
                    value: item.id,
                }));

                profs.unshift({
                    label: 'Nenhum',
                    value: '',
                });

                return profs;
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar profissionais',
                });
            }
        },
        [addToast],
    );

    const handleGetBeneficiario = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/cliente/beneficiarios?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.beneficiarios.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar beneficiários',
                });
            }
        },
        [addToast],
    );

    const handleSetDias = useCallback(
        (date: Date): void => {
            if (agenda.find((dia) => format(date, 'dd/MM/yyyy') === dia.data)) {
                return;
            }
            const dayFormatted = {
                data: format(date, 'dd/MM/yyyy'),
                pago: false,
            };
            setAgenda((old) => [...old, dayFormatted]);
        },
        [agenda],
    );

    const handleRemoveDias = useCallback((index) => {
        setAgenda((oldDias) => [
            ...oldDias.filter((dia) => oldDias.indexOf(dia) !== index),
        ]);
    }, []);

    const handleDayClick = useCallback(
        (day, { selected }) => {
            if (selected) {
                setSelectedDays((old) =>
                    old.filter((item) => !DateUtils.isSameDay(item, day)),
                );
                setAgenda((old) =>
                    old.filter(
                        (item) => item.data !== format(day, 'dd/MM/yyyy'),
                    ),
                );
            } else {
                handleSetDias(day);
                setSelectedDays((old) => [...old, day]);
            }
        },
        [handleSetDias],
    );

    const handleCandidatos = async (event: SelectProps) => {
        try {
            let valor = event.value.toString();

            if (valor.length > 0) {
                setIsDisabledProfs(true);
                setCandidatoSelecionado(event?.value as number);
            } else {
                setIsDisabledProfs(false);
                setCandidatoSelecionado(0);
            }
        } catch { }
    };

    const handleEditarNovaAba = (profissionalId: number) => {
        const win = window.open(
            `/profissionais/${profissionalId}/edit`,
            '_blank',
        );
        win?.focus();
    };

    const handleEnviarNotificacaoResponsavel = useCallback(
        (idVaga) => {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Deseja realmente enviar uma notificação ao responsável?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#008052',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, enviar!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.post(
                            `/api/v1/painel/vaga/enviar-notificacao-responsavel/${idVaga}`,
                        );
                        addToast({
                            type: 'success',
                            title: 'Notificação enviada com sucesso!',
                        });
                    } catch {
                        addToast({
                            type: 'error',
                            title: 'Ops! Algo deu errado!',
                            description: 'Erro ao enviar mensagem',
                        });
                    }
                }
            });
        },
        [addToast],
    );

    return (
        <Container fluid>
            <Breadcrumb
                itens={[
                    {
                        title: 'Vagas',
                        link: '/vagas',
                        active: false,
                    },
                    {
                        title: id ? 'Atualizar dados' : 'Nova Vaga',
                        link: id ? `/vagas/${id}/edit` : '/vagas/new',
                        active: true,
                    },
                ]}
            />
            {!loading && (
                <Form ref={formRef} initialData={vaga} onSubmit={handleSubmit}>
                    <Card>
                        <Card.Header>
                            <h3>
                                {id ? 'Atualizar dados' : 'Cadastro de Vaga'}
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={1}>
                                    <Input
                                        name="codigo"
                                        type="text"
                                        readOnly
                                        disabled
                                        placeholder="Codigo"
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Input
                                        name="titulo"
                                        type="text"
                                        placeholder="Titulo"
                                    />
                                </Col>
                                <Col lg={2}>
                                    <Input
                                        name="status"
                                        disabled
                                        value={status}
                                        type="text"
                                        placeholder="Status"
                                    />
                                </Col>
                                {status == 'Em aberto' && (
                                    <Col lg={2}>
                                        <ReactSelectInput
                                            isMulti={false}
                                            hideLabel={false}
                                            name="candidatoId"
                                            options={candidatos}
                                            placeholder="Candidato"
                                            handleChange={handleCandidatos}
                                        />
                                    </Col>
                                )}
                                {status == 'Em aberto' && (
                                    <Col lg={1}>
                                        <Button
                                            title="Ver perfil do profissional"
                                            style={{ marginTop: '36px' }}
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() => {
                                                handleEditarNovaAba(
                                                    candidatoSelecionado,
                                                );
                                            }}
                                            disabled={!isDisabledProfs}
                                        >
                                            <FiExternalLink />
                                        </Button>
                                    </Col>
                                )}
                                <Col lg={status == 'Em aberto' ? 3 : 6}>
                                    <AsyncReactSelectInput
                                        name="profissionalId"
                                        type="text"
                                        placeholder="Profissional"
                                        loadOptions={handleGetProfissionais}
                                        defaultOptions
                                        route="/api/v1/painel/profissional"
                                        isDisabled={isDisabledProfs}
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Profissional'
                                        }
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        name="caracteristicaPaciente"
                                        type="text"
                                        placeholder="Caracteristica do Paciente"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <AsyncReactSelectInput
                                        name="beneficiarioId"
                                        type="text"
                                        placeholder="Beneficiário"
                                        loadOptions={handleGetBeneficiario}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/beneficiario"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Beneficiário'
                                        }
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        name="requisitos"
                                        type="text"
                                        placeholder="Requisitos"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        name="beneficios"
                                        type="text"
                                        placeholder="Benefícios"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        name="local"
                                        type="text"
                                        placeholder="Local"
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="vagaTipoId"
                                        type="text"
                                        placeholder="Tipo de vaga"
                                        options={tipos}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Input
                                        name="horarioDescricao"
                                        type="text"
                                        placeholder="Horário"
                                    />
                                </Col>

                                <Col lg={2}>
                                    <CurrencyInput
                                        name="valor"
                                        type="text"
                                        placeholder="Valor"
                                    />
                                </Col>
                                <Col lg={2}>
                                    <CurrencyInput
                                        name="valorEmpresa"
                                        type="text"
                                        placeholder="Valor Empresa"
                                    />
                                </Col>

                                <Col lg={3}>
                                    <CurrencyInput
                                        name="taxa"
                                        type="text"
                                        placeholder="Taxa de agenciamento"
                                        percentage
                                    />
                                </Col>

                                <Col lg={2}>
                                    <Select
                                        name="sexoId"
                                        type="text"
                                        placeholder="Sexo"
                                        options={sexo}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="profissaoId"
                                        type="text"
                                        placeholder="Profissão"
                                        options={profissoes}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="unidadeId"
                                        type="text"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="horarioId"
                                        type="text"
                                        placeholder="Horário"
                                        options={horarios}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="isCnh"
                                        type="text"
                                        placeholder="Possui Habilitação?"
                                        options={[
                                            { label: 'NÃO', value: false },
                                            { label: 'SIM', value: true },
                                        ]}
                                    />
                                </Col>

                                <Col lg={3}>
                                    <Select
                                        name="isPossuiVeiculo"
                                        type="text"
                                        placeholder="Possui veículo?"
                                        options={[
                                            { label: 'NÃO', value: false },
                                            { label: 'SIM', value: true },
                                        ]}
                                    />
                                </Col>

                                <Col lg={12}>
                                    <ReactSelectInput
                                        isMulti
                                        name="aptidoes"
                                        placeholder="Aptidoes"
                                        options={aptidoes}
                                    />
                                </Col>

                                <Col lg={4} className="d-flex flex-column">
                                    <DayPicker
                                        selectedDays={selectedDays}
                                        onDayClick={handleDayClick}
                                        months={[
                                            'Janeiro',
                                            'Fevereiro',
                                            'Março',
                                            'Abril',
                                            'Maio',
                                            'Junho',
                                            'Julho',
                                            'Agosto',
                                            'Setembro',
                                            'Outubro',
                                            'Novembro',
                                            'Dezembro',
                                        ]}
                                        weekdaysShort={[
                                            'D',
                                            'S',
                                            'T',
                                            'Q',
                                            'Q',
                                            'S',
                                            'S',
                                        ]}
                                    />

                                    <small>
                                        Selecione o dia para adicionar à vaga
                                        (você pode adicionar múltiplos dias)
                                    </small>
                                </Col>
                                <Col md={8}>
                                    {agenda.length > 0 ? (
                                        <Table className="text-center">
                                            <thead>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Valor Total</th>
                                                    <th>Status</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {agenda.map((dia, index) => (
                                                    <ItemTable
                                                        key={index}
                                                        paid={dia.pago}
                                                    >
                                                        <td>{dia.data}</td>
                                                        <td>
                                                            {dia?.valor || '-'}
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {dia.pago
                                                                    ? 'Pago'
                                                                    : 'Não pago'}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {!dia.pago && (
                                                                <button
                                                                    type="button"
                                                                    className="ml-1"
                                                                    onClick={() =>
                                                                        handleRemoveDias(
                                                                            index,
                                                                        )
                                                                    }
                                                                >
                                                                    <MdClose />
                                                                </button>
                                                            )}
                                                        </td>
                                                    </ItemTable>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <h6 className="text-danger">
                                            Nenhum dia selecionado!
                                        </h6>
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <h5>Candidatos</h5>
                                </Col>
                            </Row>
                            {candidatos.length > 0 ? (
                                <Row>
                                    <Col md={12}>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {candidatosShow.map((c) => (
                                                    <tr key={c.id}>
                                                        <td className="md-10">
                                                            {c.isAtivo
                                                                ? c.nome
                                                                : c.nome +
                                                                ' (Cadastro Inativo)'}
                                                        </td>

                                                        <td>
                                                            {format(
                                                                parseISO(
                                                                    c.dataCadastro,
                                                                ),
                                                                'dd/MM/yyyy HH:mm',
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            ) : (
                                <h6>Nenhum candidato encontrado!</h6>
                            )}
                            <hr />
                            <Row>
                                <Col md={4}>
                                <h5>Log Status</h5>
                                </Col>
                            </Row>
                            {logStatus.length > 0 ? (
                                <Row>
                                <Col md={12}>
                                    <Table responsive>
                                    <thead>
                                        <tr>
                                        <th>Ação</th>
                                        <th>Descrição</th>
                                        <th>Funcionário</th>
                                        <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logStatus.map((comen) => (
                                        <tr key={comen.descricao}>
                                            <td>
                                            {comen.desativado
                                                ? 'Ativação'
                                                : 'Desativação'}
                                            </td>
                                            <td>
                                            {comen.descricao}
                                            </td>
                                            <td>
                                            {
                                                comen.nomeFuncionario
                                            }
                                            </td>
                                            <td>
                                            {format(
                                                parseISO(
                                                comen.data,
                                                ),
                                                'dd/MM/yyyy HH:mm',
                                            )}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </Table>
                                </Col>
                                </Row>
                            ) : (
                                <h6>Nenhum log encontrado!</h6>
                            )}
                            {status != 'Em aberto' && (
                                <Row>
                                    <Col md={{ span: 3 }}>
                                        <Button
                                            type="button"
                                            color={colors.info}
                                            textcolor={colors.light}
                                            onClick={() =>
                                                handleEnviarNotificacaoResponsavel(
                                                    id,
                                                )
                                            }
                                        >
                                            <FiAtSign />
                                            Enviar notificação ao responsável
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={{ span: 4, offset: 4 }}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                    >
                                        <FiCheck />
                                        Cadastrar
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            )}
        </Container>
    );
};

export default FormVaga;
