import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import logoImg from '../../assets/logo-azul.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { colors } from '../../styles/variables';
import { Container, Content, Background, AnimationContainer } from './styles';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface SignInFormData {
    email: string;
    password: string;
}
const SignIn: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { signIn } = useAuth();
    const { addToast } = useToast();
    const history = useHistory();

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido!'),
                    password: Yup.string()
                        .required('Senha obrigatória')
                        .min(6, 'A senha precisa ter 6 caracteres.')
                        .max(6, 'A senha precisa ter 6 caracteres.'),
                });
                await schema.validate(data, { abortEarly: false });
                await signIn({
                    email: data.email,
                    password: data.password,
                });
                history.push('/dashboard');
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro na autenticação',
                        description:
                            'Ocorreu um erro ao fazer login,cheque as credenciais',
                    });
                }
            }
        },
        [signIn, addToast, history],
    );
    return (
        <Container fluid>
            <Content>
                <AnimationContainer>
                    <img src={logoImg} alt="DeliveryPlus" />
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h2>Login</h2>
                        <Input
                            icon={FiMail}
                            name="email"
                            type="text"
                            placeholder="E-mail"
                        />
                        <Input
                            icon={FiLock}
                            name="password"
                            type="password"
                            placeholder="Senha"
                        />
                        <Button
                            style={{ marginTop: '16px' }}
                            type="submit"
                            color={colors.primary}
                            textcolor={colors.secondary}
                        >
                            Entrar
                        </Button>
                        {
                            <a
                                onClick={() => history.push('/recuperar-senha')}
                                href="javascript:void()"
                            >
                                Esqueci minha senha
                            </a>
                        }
                    </Form>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default SignIn;
