import React, {
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
    useEffect
} from 'react';
import cep from 'cep-promise';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import { Modal, Row, Col } from 'react-bootstrap';
import {
    FiUser,
    FiMapPin,
    FiFileText,
    FiClock
} from 'react-icons/fi';
import { Form } from '@unform/web';
import parseObjectTypes from '../../../../helpers/parseObjectTypes';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useToast } from '../../../../hooks/toast';
import { FormHandles, Scope } from '@unform/core';
import Button from '../../../../components/Button';
import MaskInput from '../../../../components/MaskInput';
import Select, { SelectProps } from '../../../../components/SelectInput';
import Input from '../../../../components/Input';
import IBeneficiario from '../../../../interfaces/IBeneficiario';
import api from '../../../../services/api';
import { colors } from '../../../../styles/variables';

interface PreloaProps {
    id: number;
    nome: string;
}

export interface ModalHandles {
    handleShowModal: () => void;
}

interface ModalProps {
    onSetBeneficiarios: (beneficiario: IBeneficiario) => void;
}

const ModalAddBeneficiario: React.RefForwardingComponent<
    ModalHandles,
    ModalProps
> = ({ onSetBeneficiarios }, ref) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);
    const [alimentacao, setAlimentacao] = useState<SelectProps[]>([]);
    const [banho, setBanho] = useState<SelectProps[]>([]);
    const [locomocao, setLocomacao] = useState<SelectProps[]>([]);
    const [lucido, setLucido] = useState<SelectProps[]>([]);

    const handleShowModal = useCallback(() => {
        setShow(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShow(false);
    }, []);

    const handleSubmit = useCallback(async (data) => {
        try {
            console.log(data)
            formRef.current?.setErrors({});

            const enderecoSchema = Yup.object().shape({
                cep: Yup.string()
                    .required('CEP é obrigatório')
                    .min(8, 'CEP precisa ter pelo menos 8 números')
                    .max(8, 'CEP precisa ter no máximo 8 números'),
                logradouro: Yup.string().required(
                    'Logradouro da cliente é obrigatório',
                ),
                complemento: Yup.string(),
                bairro: Yup.string().required('Bairro é obrigatório'),
                uf: Yup.string().required('Nome da cliente é obrigatório'),
                localidade: Yup.string().required('Cidade é obrigatório'),
                numero: Yup.number()
                    .typeError('Digite o número')
                    .required('Número é obrigatório'),
            });
            const schema = Yup.object().shape({
                nome: Yup.string().required(
                    'Nome do beneficiário é obrigatório',
                ),
                dataNascimento: Yup.date()
                    .typeError(
                        'Data de nascimento do beneficiário é obrigatória',
                    )
                    .required(
                        'Data de nascimento do beneficiário é obrigatória',
                    ),
                dataPrevisaoInicioAtend: Yup.date()
                    .typeError(
                        'Data de previsão do inicio de atendimento do beneficiário é obrigatória',
                    )
                    .required(
                        'Data de previsão do inicio de atendimento do beneficiário é obrigatória',
                    ),
                endereco: enderecoSchema,
                atendimentoDiasHorarios: Yup.string().required(
                    'Dias e horários para atendimento do beneficiário é obrigatório',
                ),
                atendimentoObservacao: Yup.string(),
                principaisNecessidades: Yup.string().required(
                    'Principais necessidades do beneficiário é obrigatório',
                ),
                patologias: Yup.string().required(
                    'Patologias do beneficiário é obrigatório',
                ),
                peso: Yup.number()
                    .typeError('Digite o peso')
                    .required('Peso do beneficiário é obrigatória'),
                altura: Yup.number()
                    .typeError('Digite a altura')
                    .required('Altura do beneficiário é obrigatória'),
                lucidoId: Yup.number().required(
                    'Informar se o beneficiário é lúcido é obrigatório',
                ),
                alimentacaoId: Yup.number().required(
                    'Alimentação do beneficiário é obrigatória',
                ),
                locomocaoId: Yup.number().required(
                    'Locomoção do beneficiário é obrigatória',
                ),

                banhoId: Yup.number().required(
                    'Quantidade de banhos do beneficiário é obrigatória',
                ),
                isEliminacaoEmFrauda: Yup.boolean().required(
                    'Informar se o beneficiário usa fralda é obrigatório',
                ),
                isCurativo: Yup.boolean().required(
                    'Informar se o beneficiário usa curativo é obrigatório',
                ),
                isMedicacaoInjetavel: Yup.boolean().required(
                    'Informar se o beneficiário usa medicação injetável é obrigatório',
                ),
                outrasParticularidades: Yup.string(),
            });

            await schema.validate(data, { abortEarly: false });

            const dataToSend = parseObjectTypes(data, [
                'nome',
                'endereco',
                'atendimentoDiasHorarios',
                'atendimentoObservacao',
                'principaisNecessidades',
                'patologias',
                'outrasParticularidades',
            ]);

            onSetBeneficiarios({ ...dataToSend, id: uuid() });
            addToast({ type: 'success', title: 'Beneficiário adicionado ' });
            handleCloseModal();
        } catch (err) {
            console.log('erro --->', err)
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                addToast({
                    type: 'error',
                    title: 'Erro ao validar dados',
                    description: 'Verifique o formulário',
                });

                formRef.current?.setErrors(errors);
            } else if (err.response) {
                const { errors } = err.response.data;

                errors.forEach((erro: string) => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description: erro,
                    });
                });
            } else {
                addToast({
                    type: 'error',
                    title: 'Erro ao adicionar',
                    description:
                        'Ocorreu um erro ao adicionar o beneficiário',
                });
            }
        }
    }, [addToast, onSetBeneficiarios, handleCloseModal]);

    useImperativeHandle(ref, () => {
        return {
            handleShowModal,
        };
    });

    const handleGetPreload = useCallback(async () => {
        try {
            const { data } = await api.get('/api/v1/painel/publico/preload');
            const dados = data.data;
            setAlimentacao(
                dados.alimentacao.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setBanho(
                dados.banho.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setLocomacao(
                dados.locomocao.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setLucido(
                dados.lucido.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Erro ao buscar o preload',
            });
        }
    }, [addToast]);

    useEffect(() => {
        handleGetPreload();
    }, [handleGetPreload]);

    const handleCepSearch = useCallback(async (e) => {
        const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
        if (cepValue.length === 8) {
            const response = await cep(cepValue);
            formRef.current?.setFieldValue(
                `endereco.logradouro`,
                response.street,
            );
            formRef.current?.setFieldValue(
                `endereco.bairro`,
                response.neighborhood,
            );
            formRef.current?.setFieldValue(
                `endereco.localidade`,
                response.city,
            );
            formRef.current?.setFieldValue(`endereco.uf`, response.state);
        }
    }, []);

    return (
        <Modal show={show} onHide={handleCloseModal} size="xl">
            <Modal.Header closeButton>
                <h5>Adicionar beneficiário</h5>
            </Modal.Header>
            <Modal.Body>
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col lg={8}>
                                    <Input
                                        icon={FiUser}
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="dataNascimento"
                                        type="date"
                                        placeholder="Data de Nascimento"
                                    />
                                </Col>

                                <Col lg={8}>
                                    <Input
                                        icon={FiFileText}
                                        name="atendimentoObservacao"
                                        type="text"
                                        placeholder="Observações p/atendimento"
                                    />
                                </Col>

                                <Col lg={4}>
                                    <Input
                                        name="dataPrevisaoInicioAtend"
                                        type="date"
                                        placeholder="Previsão início do atendimento"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        icon={FiClock}
                                        name="atendimentoDiasHorarios"
                                        type="text"
                                        placeholder="Dias e horários p/atendimento"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="principaisNecessidades"
                                        type="text"
                                        placeholder="Principais necessidades"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="patologias"
                                        type="text"
                                        placeholder="Patologias"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="outrasParticularidades"
                                        type="text"
                                        placeholder="Outras particularidades"
                                    />
                                </Col>
                                <Col lg={3}>
                                    <MaskInput
                                        mask="9.99"
                                        name="altura"
                                        type="text"
                                        placeholder="Altura"
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="lucidoId"
                                        type="text"
                                        placeholder="Lucidez"
                                        options={lucido}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="alimentacaoId"
                                        type="text"
                                        placeholder="Alimentação"
                                        options={alimentacao}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="banhoId"
                                        type="text"
                                        placeholder="Banho"
                                        options={banho}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="locomocaoId"
                                        type="text"
                                        placeholder="Locomoção"
                                        options={locomocao}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isEliminacaoEmFrauda"
                                        type="text"
                                        placeholder="Usa frauda?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isCurativo"
                                        type="text"
                                        placeholder="Usa curativo?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isMedicacaoInjetavel"
                                        type="text"
                                        placeholder="Usa medicação injetável?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <MaskInput
                                        mask="999"
                                        name="peso"
                                        type="text"
                                        placeholder="Peso"
                                    />
                                </Col>
                            </Row>
                            <h6 style={{ marginTop: '20px' }}>Endereço</h6>
                            <hr />
                            <Scope path="endereco">
                                <Row>
                                    <Col lg={4}>
                                        <MaskInput
                                            icon={FiMapPin}
                                            name="cep"
                                            type="text"
                                            mask="99999999"
                                            placeholder="Digite seu Cep"
                                            onChange={handleCepSearch}
                                        />
                                        <small>
                                            O endereço será preenchido
                                            automaticamente.
                                        </small>
                                    </Col>
                                    <Col lg={4}>
                                        <Input
                                            name="localidade"
                                            type="text"
                                            placeholder="Cidade"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="uf"
                                            type="text"
                                            placeholder="UF"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="numero"
                                            type="text"
                                            placeholder="N°"
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="bairro"
                                            type="text"
                                            placeholder="Bairro"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="complemento"
                                            type="text"
                                            placeholder="Complemento"
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <Input
                                            name="logradouro"
                                            type="text"
                                            placeholder="Logradouro"
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                            </Scope>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={{ span: 2, offset: 5 }}>
                            <Button
                                type="submit"
                                color={colors.primary}
                                textcolor={colors.light}
                            >
                                Adicionar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default forwardRef(ModalAddBeneficiario);
