import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Breadcrumb from '../../components/Breadcrumb';
import PaginationComponent from '../../components/PaginationComponent';
import { colors } from '../../styles/variables';

import { Container, Card, List, Header, Content } from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import PaginationProps from '../../interfaces/PaginationProps';
import ICliente from '../../interfaces/IClienteProps';
import ReactSelectInput from '../../components/ReactSelect';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select, { SelectProps } from '../../components/SelectInput';
import { Form } from '@unform/web';

const breadcrumbItens = [
    {
        title: 'Listagem Pré Cadastro',
        link: '/precadastro',
        active: true,
    },
];

const ProfissionalPosCadastro: React.FC = () => {
    const [profissionais, setClientes] = useState<any[]>([] as ICliente[]);

    const [paginationData, setPaginationData] = useState<PaginationProps>();
    const [estados, setEstados] = useState<SelectProps[]>([]);
    const [cidades, setCidades] = useState<SelectProps[]>([]);
    const [bairros, setBairros] = useState<SelectProps[]>([]);
    const [aptidoes, setAptidoes] = useState<SelectProps[]>([]);

    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);

    const [profissoes, setprofissoes] = useState<SelectProps[]>([]);
    const [sexo, setSexo] = useState<SelectProps[]>([]);
    const [unidades, setUnidades] = useState<SelectProps[]>([]);
    const [status, setStatus] = useState<SelectProps[]>([]);

    const [cpfAux, setCpfAux] = useState('');
    const [nomeAux, setNomeAux] = useState('');
    const [profissaoAux, setProfissaoAux] = useState('');
    const [unidadeAux, setUnidadeAux] = useState('');
    const [sexoAux, setSexoAux] = useState('');
    const [statusAux, setStatusAux] = useState('');
    const [ufAux, setUfAux] = useState<string>();
    const [emailAux, setEmailAux] = useState('');
    const [cidadeAux, setCidadeAux] = useState<string>();
    const [bairroAux, setBairroAux] = useState<string>();
    const [ativoAux, setAtivoAux] = useState('');
    const [aptidaoAux, setAptidaoAux] = useState('');

    const cidadeRef = useRef('');

    const obterStatus = (status: number): string => {
        let result = '';
        switch (status) {
            case 1:
                result = 'Aguardando dados cadastro';
                break;
            case 2:
                result = 'Aguardando aprovação';
                break;
            case 3:
                result = 'Aguardando assinatura(s)';
                break;
            case 4:
                result = 'Aguardando liberação cadastro';
                break;
            case 5:
                result = 'Cadastro aprovado';
                break;
        }
        return result;
    };

    const handleGetUnidades = useCallback(async () => {
        try {
            const { data } = await api.get(`api/v1/painel/unidade/usuario`);
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    const obterProfissionaisPosCadastro = useCallback(
        async (
            page = 1,
            data = {
                cpf: cpfAux,
                nome: nomeAux,
                profissao: profissaoAux,
                email: emailAux,
                uf: ufAux,
                cidade: cidadeAux,
                bairro: bairroAux,
                ativo: ativoAux,
                aptidao: aptidaoAux,
                unidade: unidadeAux,
                sexo: sexoAux,
            },
        ) => {
            try {
                const {
                    cpf,
                    nome,
                    profissao,
                    email,
                    uf,
                    cidade,
                    bairro,
                    ativo,
                    unidade,
                    aptidao,
                    sexo
                } = data;

                setLoading(true);

                setCpfAux(cpf);
                setNomeAux(nome);
                setProfissaoAux(profissao);
                setEmailAux(email);
                setUfAux(uf);
                setCidadeAux(cidade);
                setBairroAux(bairro);
                setAtivoAux(ativo);
                setAptidaoAux(aptidao);
                setUnidadeAux(unidade);
                setSexoAux(sexo);

                await api
                    .get('/api/v1/painel/pos-cadastro-profissional', {
                        params: {
                            pagina: page,
                            cpf,
                            nome,
                            profissao,
                            unidade,
                            sexo,
                            status,
                            uf,
                            cidade,
                            bairro,
                            ativo
                        },
                    })
                    .then((res) => {
                        const dados = res.data.data;

                        setPaginationData({
                            page: dados.paginaAtual,
                            perPage: dados.quantidadeTotalPagina,
                            total: dados.quantidadeTotal,
                            lastPage:
                                dados.quantidadeTotal /
                                dados.quantidadeTotalPagina,
                        });

                        setClientes(dados.profissionais);
                    });
            } catch (error) {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description:
                        'Não conseguimos exibir as telas, tente novamente!',
                });
            } finally {
                setLoading(false);
            }
        },
        [
            addToast,
            nomeAux,
            cpfAux,
            profissaoAux,
            unidadeAux,
            sexoAux,
            statusAux,
            ufAux,
            cidadeAux,
            bairroAux,
            ativoAux
        ],
    );

    const handleGetCidades = async (event: SelectProps) => {
        try {
            const uf = event.value.toString();

            api.get(`api/v1/painel/regiao/estados/cidades`, {
                params: {
                    uf
                }
            })
                .then(response => {
                    setUfAux(uf);
                    setCidades(
                        response.data.data.map((item: any) => ({
                            label: item,
                            value: item,
                        })),
                    );
                });
        } catch {
            console.log('erro')
        }
    }

    const handleGetBairros = async (event: SelectProps) => {
        try {
            const cidade = event.value.toString();

            api.get(`api/v1/painel/regiao/estados/cidades/bairros`, {
                params: {
                    uf: ufAux,
                    cidade
                }
            })
                .then(response => {
                    setBairroAux('');
                    setBairros(
                        response.data.data.map((item: any) => ({
                            label: item,
                            value: item,
                        })),
                    );
                });
        } catch {
            console.log('erro')
        }
    }

    useEffect(() => {
        handleGetUnidades();
        obterProfissionaisPosCadastro();
    }, [handleGetUnidades, obterProfissionaisPosCadastro]);

    useEffect(() => {
        async function getPreLoad() {
            const { data } = await api.get(
                '/api/v1/painel/profissional/preload',
            );
            const dados = data.data;
            setprofissoes(
                dados.profissoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setAptidoes(
                dados.aptidoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            let sexo = dados.sexo.map((item: any) => ({
                label: item.nome,
                value: item.id,
            }));
            sexo.unshift({
                label: 'Todos',
                value: null,
            });
            setSexo(sexo);
            const statusTemp = [
                {
                    label: 'Todos',
                    value: 'Todos',
                },
                {
                    label: 'Aguardando aprovação',
                    value: 2,
                },
                {
                    label: 'Aguardando assinatura(s)',
                    value: 3,
                },
                {
                    label: 'Aguardando liberação cadastro',
                    value: 4,
                },
            ];
            setStatus(statusTemp);
        }
        getPreLoad();
    }, []);

    useEffect(() => {
        api
            .get(`api/v1/painel/regiao/estados`)
            .then(({ data }) => {
                setEstados(
                    data.data.map((item: any) => ({
                        label: item,
                        value: item,
                    })),
                );
            })
            .catch(err => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description:
                        'Erro ao buscar estados',
                });
            });
    }, [addToast]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <Row>
                            <Col md={12}>
                                <h5>Listagem Pré Cadastro Profissionais</h5>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                obterProfissionaisPosCadastro(1, e)
                            }
                        >
                            <Row>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="cpf"
                                        type="text"
                                        placeholder="CPF"
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="profissao"
                                        placeholder="Profissão"
                                        options={profissoes}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti={false}
                                        hideLabel
                                        name="unidade"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti
                                        hideLabel
                                        name="aptidao"
                                        placeholder="Aptidão"
                                        options={aptidoes}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        name="uf"
                                        hideLabel
                                        placeholder="Estado"
                                        handleChange={handleGetCidades}
                                        options={estados}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="cidade"
                                        placeholder="Cidade"
                                        handleChange={handleGetBairros}
                                        options={[{label: 'todos', value: ''}, ...cidades ]}
                                        ref={cidadeRef}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="bairro"
                                        placeholder="Bairro"
                                        options={[{label: 'todos', value: ''}, ...bairros]}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Select
                                        defaultOpt="Selecione o sexo"
                                        name="sexo"
                                        type="text"
                                        placeholder=""
                                        options={sexo}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Select
                                        defaultOpt="Selecione o status"
                                        hideLabel
                                        name="status"
                                        placeholder=""
                                        options={status}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.light}
                                        textcolor={colors.dark}
                                        style={{
                                            marginTop: '10px',
                                        }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card.Header>
                <Card.Body>
                    {profissionais.length > 0 ? (
                        <List responsive>
                            <Header>
                                <tr>
                                    <th>CPF</th>
                                    <th>Nome</th>
                                    <th>Unidade</th>
                                    <th>Status do antigo pré cadastro</th>
                                    <th>Status do antigo pós cadastro</th>
                                    <th>Opções</th>
                                </tr>
                            </Header>
                            <Content>

                                {profissionais.map((profissional) => (
                                    <tr key={profissional.id}>
                                        <td>{profissional.cpf}</td>
                                        <td>{profissional.nome}</td>
                                        <td>{profissional.unidade}</td>
                                        <td>{profissional.isAtivo ? 'Ativo' : 'Inativo'}</td>
                                        <td>{obterStatus(profissional.status)}</td>
                                        <td style={{ width: '20%' }}>
                                            <div
                                                className="row"
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <div
                                                    className="col-md-4"
                                                    style={{
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                >
                                                    <div style={{display: 'flex', flexDirection: 'row'}}>

                                                        <Button
                                                            title="Editar na aba atual"
                                                            type="button"
                                                            color={colors.primary}
                                                            textcolor={colors.light}
                                                            onClick={() => history.push(`/precadastro/${profissional.id}`)}

                                                        >
                                                            <FiEdit
                                                                color={colors.light}
                                                            />
                                                            Visualizar
                                                        </Button>
                                                        <Button
                                                            title="Editar na aba atual"
                                                            type="button"
                                                            color={colors.primary}
                                                            textcolor={colors.light}
                                                            onClick={() => history.push(`/precadastro/${profissional.id}/edit`)}

                                                        >
                                                            <FiEdit
                                                                color={colors.light}
                                                            />
                                                            Editar
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    ) : (
                        <h5 className="text-center">
                            Nenhum resultado encontrado.
                        </h5>
                    )}
                </Card.Body>
                <div className="d-flex justify-content-center">
                    {paginationData && (
                        <PaginationComponent
                            currentPage={paginationData.page}
                            totalPages={paginationData.perPage}
                            perPage={10}
                            onChangePage={obterProfissionaisPosCadastro}
                        />
                    )}
                </div>
            </Card>
        </Container>
    );
};

export default ProfissionalPosCadastro;
