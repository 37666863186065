import React, { useRef, useEffect, useState, useCallback } from 'react';

import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, SelectContainer, Error } from './styles';
import { SelectProps } from '../SelectInput';

interface Props {
    name: string;
    placeholder: string;
    isMulti?: boolean;
    hideLabel?: boolean;
    isDisabled?: boolean;
    handleChange?: (e: SelectProps) => void;
    options: Array<{ label: string; value: string | number }>;
    ref?: any
}

// const customStyles: StylesConfig = {
//     control: (provided, state) => ({
//         ...provided,
//         borderWidth: '1px',
//         borderStyle: 'solid',
//         background: '#fff',
//         borderRadius: '5px',
//         '&:hover': {
//             border: '1px solid #dde3f0',
//         },
//         boxShadow: state.isFocused
//             ? `0 0 0 2px ${transparentize(0.7, colors.primary)}`
//             : '0 0 0 2px transparent',
//         borderColor: state.isFocused ? colors.primary : '#dde3f0',
//     }),
// };

const ReactSelectInput: React.FC<Props> = ({ name, hideLabel = false, isDisabled = false, handleChange, ...rest }: any) => {
    const selectRef = useRef<any>(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);
    // const [selectValue, setSelectValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    // useEffect(() => {
    //     setSelectValue(defaultValue);
    // }, [defaultValue]);

    // const handleChange = useCallback((e: any) => {
    //     setSelectValue(e.target.value);
    // }, []);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!selectRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option: any) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    return (
        <Container>
            {!hideLabel && <p id="span-label">{rest.placeholder}</p>}
            <SelectContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
            >
                {handleChange ? (
                    <ReactSelect
                        // styles={customStyles}
                        defaultValue={defaultValue}
                        ref={selectRef}
                        onChange={(e: SelectProps) => handleChange(e)}
                        onFocus={handleInputFocused}
                        onBlur={handleInputBlur}
                        classNamePrefix="react-select"
                        isDisabled={isDisabled}
                        {...rest}
                    />
                ) : (
                    <ReactSelect
                        // styles={customStyles}
                        defaultValue={defaultValue}
                        ref={selectRef}
                        onFocus={handleInputFocused}
                        onBlur={handleInputBlur}
                        classNamePrefix="react-select"
                        isDisabled={isDisabled}
                        {...rest}
                    />
                )}

                {error && (
                    <Error title={error}>
                        <FiAlertCircle color="#c53030" size={20} />
                    </Error>
                )}
            </SelectContainer>
        </Container>
    );
};
export default ReactSelectInput;
