import React, { useRef, useEffect, useState, useCallback } from 'react';
// import ReactSelect, {
//     OptionTypeBase,
//     Props as SelectProps,
// } from 'react-select';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container, SelectContainer, Error } from './styles';

export interface SelectProps {
    label: string;
    value: string | number;
}
interface Props {
    name: string;
    options: Array<SelectProps>;
    keyName?: string;
}
const Select: React.FC<any> = ({
    name,
    options,
    defaultOpt,
    error,
    keyName = 'value',
    ...rest
}) => {
    const selectRef = useRef<HTMLSelectElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name);
    const [selectValue, setSelectValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    useEffect(() => {
        setSelectValue(defaultValue);
    }, [defaultValue]);

    const handleChange = useCallback((e: any) => {
        setSelectValue(e.target.value);
    }, []);

    const handleInputFocused = useCallback(() => setIsFocused(true), []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!selectRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
        });
    }, [fieldName, registerField, rest.isMulti]);
    return (
        <Container>
            <span id="span-label">{rest.placeholder}</span>
            <SelectContainer
                isErrored={!!error}
                isFocused={isFocused}
                isFilled={isFilled}
            >
                <select
                    value={selectValue}
                    onChange={handleChange}
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    ref={selectRef}
                    {...rest}
                >
                    <option disabled>
                        {defaultOpt ? defaultOpt : 'Selecione'}
                    </option>
                    {options.map((option: any) => (
                        <option key={option[keyName]} value={option.value} >
                            {option.label}
                        </option>
                    ))}
                </select>
                {error && (
                    <Error title={error}>
                        <FiAlertCircle color="#c53030" size={20} />
                    </Error>
                )}
            </SelectContainer>
        </Container>
    );
};
export default Select;
