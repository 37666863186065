import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { FiUser, FiClock, FiFileText, FiMapPin, FiCheck } from 'react-icons/fi';
import cep from 'cep-promise';
import { format, parseISO, parse } from 'date-fns';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import parseObjectTypes from '../../../../helpers/parseObjectTypes';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Breadcrumb from '../../../../components/Breadcrumb';
import Input from '../../../../components/Input';
import MaskInput from '../../../../components/MaskInput';
import Button from '../../../../components/Button';
import Select, { SelectProps } from '../../../../components/SelectInput';
import { colors } from '../../../../styles/variables';
import { Container, Card } from '../../../../styles/components';
import AsyncReactSelectInput from '../../../../components/AsyncReactSelect';
import ILogStatus from '../../../../interfaces/ILogStatus';

interface PreloaProps {
    id: number;
    nome: string;
}

const FormBeneficiarios: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { addToast } = useToast();
    const [alimentacao, setAlimentacao] = useState<SelectProps[]>([]);
    const [banho, setBanho] = useState<SelectProps[]>([]);
    const [locomocao, setLocomacao] = useState<SelectProps[]>([]);
    const [lucido, setLucido] = useState<SelectProps[]>([]);
    const [beneficiario, setBeneficiario] = useState({});
    const [loading, setLoading] = useState(false);
    const [logStatus, setLogStatus] = useState<ILogStatus[]>([]);

    useEffect(() => {
        if (id) {
            setLoading(true);

            api.get(`/api/v1/painel/cliente/beneficiario/${id}`).then(({ data }) => {
                data.data.dataPrevisaoInicioAtend = format(parseISO(data.data.dataPrevisaoInicioAtend), 'dd/MM/yyyy');
                data.data.dataNascimento = format(parseISO(data.data.dataNascimento), 'dd/MM/yyyy');
                setBeneficiario(data.data);
                setLogStatus(data.data.logsStatus);
                console.log(logStatus);
                setLoading(false);
            });
        }
    }, [id]);

    const handleSubmit = useCallback(
        async (data) => {
            try {
                formRef.current?.setErrors({});

                const enderecoSchema = Yup.object().shape({
                    cep: Yup.string()
                        .required('CEP é obrigatório')
                        .min(8, 'CEP precisa ter pelo menos 8 números')
                        .max(8, 'CEP precisa ter no máximo 8 números'),
                    logradouro: Yup.string().required(
                        'Logradouro da cliente é obrigatório',
                    ),
                    complemento: Yup.string(),
                    bairro: Yup.string().required('Bairro é obrigatório'),
                    uf: Yup.string().required('Nome da cliente é obrigatório'),
                    localidade: Yup.string().required('Cidade é obrigatório'),
                    numero: Yup.number()
                        .typeError('Digite o número')
                        .required('Número é obrigatório'),
                });
                const schema = Yup.object().shape({
                    nome: Yup.string().required(
                        'Nome do beneficiário é obrigatório',
                    ),
                    dataNascimento: Yup.string()
                        .typeError(
                            'Data de nascimento do beneficiário é obrigatória',
                        )
                        .required(
                            'Data de nascimento do beneficiário é obrigatória',
                        ),
                    dataPrevisaoInicioAtend: Yup.string()
                        .typeError(
                            'Data de previsão do inicio de atendimento do beneficiário é obrigatória',
                        )
                        .required(
                            'Data de previsão do inicio de atendimento do beneficiário é obrigatória',
                        ),
                    endereco: enderecoSchema,
                    atendimentoDiasHorarios: Yup.string().required(
                        'Dias e horários para atendimento do beneficiário é obrigatório',
                    ),
                    atendimentoObservacao: Yup.string(),
                    principaisNecessidades: Yup.string().required(
                        'Principais necessidades do beneficiário é obrigatório',
                    ),
                    patologias: Yup.string().required(
                        'Patologias do beneficiário é obrigatório',
                    ),
                    peso: Yup.number()
                        .typeError('Digite o peso')
                        .required('Peso do beneficiário é obrigatória'),
                    altura: Yup.number()
                        .typeError('Digite a altura')
                        .required('Altura do beneficiário é obrigatória'),
                    lucidoId: Yup.number().required(
                        'Informar se o beneficiário é lúcido é obrigatório',
                    ),
                    alimentacaoId: Yup.number().required(
                        'Alimentação do beneficiário é obrigatória',
                    ),
                    locomocaoId: Yup.number().required(
                        'Locomoção do beneficiário é obrigatória',
                    ),

                    banhoId: Yup.number().required(
                        'Quantidade de banhos do beneficiário é obrigatória',
                    ),
                    responsavelId: Yup.number().required(
                        'Informar o responśavel',
                    ),
                    isEliminacaoEmFrauda: Yup.boolean().required(
                        'Informar se o beneficiário usa fralda é obrigatório',
                    ),
                    isCurativo: Yup.boolean().required(
                        'Informar se o beneficiário usa curativo é obrigatório',
                    ),
                    isMedicacaoInjetavel: Yup.boolean().required(
                        'Informar se o beneficiário usa medicação injetável é obrigatório',
                    ),
                    outrasParticularidades: Yup.string(),
                });

                await schema.validate(data, { abortEarly: false });

                const dataToSend = parseObjectTypes(data, [
                    'nome',
                    'endereco',
                    'atendimentoDiasHorarios',
                    'atendimentoObservacao',
                    'principaisNecessidades',
                    'patologias',
                    'outrasParticularidades',
                ]);
                dataToSend.dataNascimento = parse(dataToSend.dataNascimento, 'dd/MM/yyyy', new Date());
                dataToSend.dataPrevisaoInicioAtend = parse(dataToSend.dataPrevisaoInicioAtend, 'dd/MM/yyyy', new Date());


                if (id) {
                    dataToSend.id = Number(id);
                    await api.put(
                        `/api/v1/painel/cliente/beneficiario/${id}`,
                        dataToSend,
                    );
                } else {
                    await api.post('/api/v1/painel/cliente/beneficiario', dataToSend);
                }

                addToast({
                    type: 'success',
                    title: 'Beneficiário salvo com sucesso',
                });

                history.push('/clientes/beneficiarios');
            } catch (err) {
                console.log(err)
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err.response) {
                    const { errors } = err.response.data;

                    errors.forEach((erro: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: erro,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar o responsável',
                    });
                }
            }
        },
        [addToast, history, id],
    );

    const handleCepSearch = useCallback(async (e) => {
        const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
        console.log(formRef, 'e');


        if (cepValue.length === 8) {
            console.log(cepValue);
            //const searchCep = async () => {
            try {
                const response = await cep(cepValue);
                console.log(response);
                if (response) {
                    formRef.current?.setFieldValue(
                        `endereco.logradouro`,
                        response.street,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.bairro`,
                        response.neighborhood,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.localidade`,
                        response.city,
                    );
                    formRef.current?.setFieldValue(`endereco.uf`, response.state);
                }
            } catch (err) {
                switch (err.type) {
                    case "service_error":
                        addToast({
                            type: 'error',
                            title: 'CEP não encontrado',
                        });
                        break;
                    case "validation_error":
                        addToast({
                            type: 'error',
                            title: 'CEP possui um formato inválido',
                        });
                        break;
                    default:
                    // addToast({
                    //     type: 'error',
                    //     title: err,
                    // });
                    //setSearch(err);
                }

                formRef.current?.setFieldValue(
                    `endereco.logradouro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.bairro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.localidade`, null,
                );
                formRef.current?.setFieldValue(`endereco.uf`, null);
            }
        }
    }, []);

    // const handleCepSearch = useCallback(async (e) => {
    //     const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
    //     if (cepValue.length === 8) {
    //         const response = await cep(cepValue);
    //         formRef.current?.setFieldValue(
    //             `endereco.logradouro`,
    //             response.street,
    //         );
    //         formRef.current?.setFieldValue(
    //             `endereco.bairro`,
    //             response.neighborhood,
    //         );
    //         formRef.current?.setFieldValue(
    //             `endereco.localidade`,
    //             response.city,
    //         );
    //         formRef.current?.setFieldValue(`endereco.uf`, response.state);
    //     }
    // }, []);

    const handleGetResponsaveis = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/cliente/responsaveis?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.responsaveis.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    const handleGetPreload = useCallback(async () => {
        try {
            const { data } = await api.get('/api/v1/painel/cliente/preload');
            const dados = data.data;
            setAlimentacao(
                dados.alimentacao.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setBanho(
                dados.banho.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setLocomacao(
                dados.locomocao.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setLucido(
                dados.lucido.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Erro ao buscar o preload',
            });
        }
    }, [addToast]);

    // const loadOptions = (inputValue:string) =>
    //     new Promise((resolve) => {

    //     });
    // }

    useEffect(() => {
        handleGetPreload();
    }, [handleGetResponsaveis, handleGetPreload]);

    return (
        <Container fluid>
            <Breadcrumb
                itens={[
                    {
                        title: 'Beneficiários',
                        link: '/clientes/beneficiarios',
                        active: false,
                    },
                    {
                        title: id ? 'Atualizar dados' : 'Novo beneficário',
                        link: id
                            ? `/vagas/${id}/edit`
                            : '/clientes/beneficiarios/new',
                        active: true,
                    },
                ]}
            />
            {!loading && (
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={beneficiario}
                >
                    <Card>
                        <Card.Header>
                            <h3>
                                {id
                                    ? 'Atualizar dados'
                                    : 'Cadastro de Beneficiário'}
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={8}>
                                    <Input
                                        icon={FiUser}
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>

                                <Col lg={4}>
                                    {/* <Input
                                        name="dataNascimento"
                                        type="date"
                                        placeholder="Data de Nascimento"
                                    /> */}
                                    <MaskInput
                                        mask="99/99/9999"
                                        name="dataNascimento"
                                        type="text"
                                        placeholder="Data de nascimento"
                                    />
                                </Col>

                                <Col lg={8}>
                                    <Input
                                        icon={FiFileText}
                                        name="atendimentoObservacao"
                                        type="text"
                                        placeholder="Observações p/atendimento"
                                    />
                                </Col>

                                <Col lg={4}>
                                    <MaskInput
                                        mask="99/99/9999"
                                        name="dataPrevisaoInicioAtend"
                                        type="text"
                                        placeholder="Previsão início do atendimento"
                                    />
                                </Col>

                                <Col lg={8}>
                                    <AsyncReactSelectInput
                                        name="responsavelId"
                                        type="text"
                                        placeholder="Responsável"
                                        loadOptions={handleGetResponsaveis}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/responsavel"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Responsável'
                                        }
                                    />
                                </Col>
                                <Col lg={4}>
                                    <MaskInput
                                        mask="999"
                                        name="peso"
                                        type="text"
                                        placeholder="Peso"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiClock}
                                        name="atendimentoDiasHorarios"
                                        type="text"
                                        placeholder="Dias e horários p/atendimento"
                                    />
                                </Col>

                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="principaisNecessidades"
                                        type="text"
                                        placeholder="Principais necessidades"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="patologias"
                                        type="text"
                                        placeholder="Patologias"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Input
                                        icon={FiFileText}
                                        name="outrasParticularidades"
                                        type="text"
                                        placeholder="Outras particularidades"
                                    />
                                </Col>
                                <Col lg={3}>
                                    <MaskInput
                                        mask="9.99"
                                        name="altura"
                                        type="text"
                                        placeholder="Altura"
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="lucidoId"
                                        type="text"
                                        placeholder="Lucidez"
                                        options={lucido}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="alimentacaoId"
                                        type="text"
                                        placeholder="Alimentação"
                                        options={alimentacao}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="banhoId"
                                        type="text"
                                        placeholder="Banho"
                                        options={banho}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="locomocaoId"
                                        type="text"
                                        placeholder="Locomoção"
                                        options={locomocao}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isEliminacaoEmFrauda"
                                        type="text"
                                        placeholder="Usa frauda?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isCurativo"
                                        type="text"
                                        placeholder="Usa curativo?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Select
                                        name="isMedicacaoInjetavel"
                                        type="text"
                                        placeholder="Usa medicação injetável?"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <h5 style={{ marginTop: '20px' }}>Endereço</h5>
                            <hr />
                            <Scope path="endereco">
                                <Row>
                                    <Col lg={4}>
                                        <MaskInput
                                            icon={FiMapPin}
                                            name="cep"
                                            type="text"
                                            mask="99999999"
                                            placeholder="Digite seu Cep"
                                            onKeyUp={handleCepSearch}
                                        />
                                        <small>
                                            O endereço será preenchido
                                            automaticamente.
                                        </small>
                                    </Col>
                                    <Col lg={4}>
                                        <Input
                                            name="localidade"
                                            type="text"
                                            placeholder="Cidade"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="uf"
                                            type="text"
                                            placeholder="UF"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="numero"
                                            type="text"
                                            placeholder="N°"
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="bairro"
                                            type="text"
                                            placeholder="Bairro"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="complemento"
                                            type="text"
                                            placeholder="Complemento"
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <Input
                                            name="logradouro"
                                            type="text"
                                            placeholder="Logradouro"
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                            </Scope>

                            <hr />
                            <Row>
                                <Col md={4}>
                                <h5>Log Status</h5>
                                </Col>
                            </Row>
                            {logStatus.length > 0 ? (
                                <Row>
                                <Col md={12}>
                                    <Table responsive>
                                    <thead>
                                        <tr>
                                        <th>Ação</th>
                                        <th>Descrição</th>
                                        <th>Funcionário</th>
                                        <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logStatus.map((comen) => (
                                        <tr key={comen.descricao}>
                                            <td>
                                            {comen.desativado
                                                ? 'Ativação'
                                                : 'Desativação'}
                                            </td>
                                            <td>
                                            {comen.descricao}
                                            </td>
                                            <td>
                                            {
                                                comen.funcionario?.nome
                                            }
                                            </td>
                                            <td>
                                            {format(
                                                parseISO(
                                                comen.data,
                                                ),
                                                'dd/MM/yyyy HH:mm',
                                            )}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </Table>
                                </Col>
                                </Row>
                            ) : (
                                <h6>Nenhum log encontrado!</h6>
                            )}

                            <Row style={{ marginTop: 30 }}>
                                <Col md={{ offset: 4, span: 4 }}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                    >
                                        <FiCheck />
                                        Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            )}
        </Container>
    );
};

export default FormBeneficiarios;
