import React, {
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiPhone } from 'react-icons/fi';
import { Modal, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import IExperiencia from '../../../interfaces/IExperiencia';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import Button from '../../../components/Button';

export interface ModalHandles {
    handleShowModal: () => void;
}

interface ModalProps {
    setExperienciasPro: (experiencia: IExperiencia) => void;
    experienciaPro?: IExperiencia;
}

const ModalExperiencia: React.RefForwardingComponent<
    ModalHandles,
    ModalProps
> = ({ setExperienciasPro, experienciaPro }, ref) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);

    const handleShowModal = useCallback(() => {
        setShow(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShow(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModal,
        };
    });

    const handleSubmit = useCallback(
        async (data: IExperiencia) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    local: Yup.string().required('Local é obrigatório'),
                    cargo: Yup.string().required('Cargo é obrigatório'),
                    telefone: Yup.string().required('Telefone é obrigatório'),
                    dataFim: Yup.string().required('Data de fim é obrigatório'),
                    dataInicio: Yup.string().required(
                        'Data de inicio é obrigatório',
                    ),
                    nomeContratante: Yup.string().required(
                        'Nome contratante é obrigatório',
                    ),
                    descricaoAtividades: Yup.string().required(
                        'Descrição é obrigatório',
                    ),
                    motivoSaida: Yup.string().required(
                        'Motivo de saída é obrigatório',
                    ),
                });

                const dataToSend = parseObjectTypes(data, [
                    'local',
                    'cargo',
                    'telefone',
                    'nomeContrante',
                    'descricaoAtividades',
                    'motivoSaida',
                ]);

                await schema.validate(dataToSend, { abortEarly: false });

                dataToSend.id = experienciaPro?.id ||  uuid();
                setExperienciasPro(dataToSend);
                setShow(false);

                addToast({
                    type: 'success',
                    title: 'Experiência adicionada com sucesso',
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar essa vaga, tente novamente',
                    });
                }
            }
        },
        [addToast, setExperienciasPro, experienciaPro],
    );

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <h5>Adicionar experiência</h5>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} initialData={experienciaPro} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Input
                                name="local"
                                type="text"
                                placeholder="Local"
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                name="cargo"
                                type="text"
                                placeholder="Cargo"
                            />
                        </Col>
                        <Col md={6}>
                            <MaskInput
                                mask="(99)99999-9999"
                                icon={FiPhone}
                                name="telefone"
                                type="tel"
                                placeholder="Telefone"
                            />
                        </Col>
                        <Col lg={6}>
                            <MaskInput
                                mask="99/9999"
                                name="dataInicio"
                                type="text"
                                placeholder="Data início"
                            />
                        </Col>
                        <Col lg={6}>
                            <MaskInput
                                mask="99/9999"
                                name="dataFim"
                                type="text"
                                placeholder="Data Fim"
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                name="nomeContratante"
                                type="text"
                                placeholder="Nome do contrante"
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                name="descricaoAtividades"
                                type="text"
                                placeholder="Descrição"
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                name="motivoSaida"
                                type="text"
                                placeholder="Motivo da saída"
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Button
                                type="submit"
                                color={colors.primary}
                                textcolor={colors.light}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default forwardRef(ModalExperiencia);
