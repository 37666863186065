import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import cep from 'cep-promise';
import { useDropzone } from 'react-dropzone';
import { format, parseISO } from 'date-fns';
import {
  FiMail,
  FiUser,
  FiMapPin,
  FiPhone,
  FiCheck,
  FiTrash2,
  FiEdit,
} from 'react-icons/fi';
import { FormHandles, Scope } from '@unform/core';
import { useParams, useHistory } from 'react-router-dom';

import {
  Row,
  Col,
  Table,
  Button as ButtonOriginal,
  Modal,
  Alert,
} from 'react-bootstrap';

import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import { colors } from '../../../styles/variables';
import IExperiencia from '../../../interfaces/IExperiencia';
import ModalExperiencia, { ModalHandles } from '../ModalExperiencia';
import ModalComentario, { ModalComentarioHandles } from '../ModalComentario';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import Select, { SelectProps } from '../../../components/SelectInput';
import ReactSelectInput from '../../../components/ReactSelect';
import Button from '../../../components/Button';
import { Container, Card, Form } from './styles';
import IComentario from '../../../interfaces/IComentario';
import { ICurso } from '../../../interfaces/ICurso';
import ModalCurso from '../ModalCurso';
import ILogStatus from '../../../interfaces/ILogStatus';
import FileUpload from '../FileUpload';

let breadcrumbItens: any = [];

interface SelectPropsBank {
  label: string;
  value: string | number;
  numero: number;
}

// SelectProps;
const FormProfissional: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const modalRef = useRef<ModalHandles>(null);
  const modalComentarioRef = useRef<ModalComentarioHandles>(null);
  const modalCursoRef = useRef<ModalComentarioHandles>(null);
  const { addToast } = useToast();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [aptidoes, setAptidoes] = useState<SelectProps[]>([]);
  const [estadoCivil, setEstadoCivil] = useState<SelectProps[]>([]);
  const [experiencias, setExperiencias] = useState<SelectProps[]>([]);
  const [grauEscolaridade, setGrauEscolaridade] = useState<SelectProps[]>([]);
  const [horarios, sethorarios] = useState<SelectProps[]>([]);
  const [bancos, setBancos] = useState<SelectPropsBank[]>([]);
  const [contas, setContas] = useState<SelectProps[]>([]);
  const [profissoes, setprofissoes] = useState<SelectProps[]>([]);
  const [sexo, setsexo] = useState<SelectProps[]>([]);
  const [experienciasPro, setExperienciasPro] = useState<IExperiencia[]>([]);
  const [experienciaEdit, setExperienciaEdit] = useState<IExperiencia>();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState<SelectProps[]>([]);

  const [comentarios, setComentarios] = useState<IComentario[]>([]);
  const [comentario, setComentario] = useState<IComentario>();

  const [cursos, setCursos] = useState<ICurso[]>([]);
  const [cursoEdit, setCursoEdit] = useState<ICurso>();

  const [arquivosEnviados, setArquivosEnviados] = useState<any[]>([]);

  const [profissional, setProfissional] = useState<any>({});
  const [listaNegra, setListaNegra] = useState<SelectProps[]>([]);

  const [logStatus, setLogStatus] = useState<ILogStatus[]>([]);

  const [typeFiles, setTypeFiles] = useState<SelectProps[]>([])
  const [files, setFiles] = useState<{ file: File, typeFile: string, typeFileId: string }[]>([])
  const [filesSelected, setFilesSelected] = useState<any>([])

  const [unidadeSelected, setUnidadeSelected] = useState<SelectProps[]>([])
  // Modal Documento Viewer
  const [srcDocumento, setSrcDocumento] = useState<string>('');
  const [showModalDocumento, setShowModalDocumento] = useState<boolean>(false);
  const handleCloseModalDocumento = () => setShowModalDocumento(false);

  const history = useHistory();

  const handleChangeTypeFiles = (options: any) => {
    const getDifference = (largeArr1: any, arr2: any) => {
      return largeArr1.find((item: any, i: any) => {
        return item.label !== arr2[i]?.label;
      });
    };
    if (filesSelected.length > options.length) {
      //SABER QUANDO O TIPO DE ARQUIVO É REMOVIDO DO SELECT
      // E IDENTIFICAR QUAL É O VALOR REMOVIDO DO SELECT PRA PODER REMOVER NO FILES TBM
      const modified: any = getDifference(filesSelected, options)
      setFiles(files.filter(file => file.typeFile !== modified.label))
    }
    setFilesSelected(options)
  }

  const getTypeFiles = async () => {
    try {
      const response = await api.get(`/api/v1/painel/tipo-arquivo`);
      setTypeFiles(response.data.data?.map((item: any) => ({ label: item.descricao, value: item.descricao, id: item.id })));
    } catch (error) {
      addToast({
        title: 'Ooops!',
        description: 'Alguma coisa deu errado ao entre em contato com o administrador do sistema',
        type: 'error'
      });
    }
  }
  useEffect(() => {

    getTypeFiles()

    if (id) {
      setLoading(true);

      api.get(`/api/v1/painel/profissional/${id}`).then(({ data }) => {
        const tempProfissional = data.data;

        tempProfissional.aptidoes = tempProfissional.aptidoes.map(
          (ap: { id: number; nome: string }) => ({
            value: ap.id,
            label: ap.nome,
          }),
        );

        tempProfissional.listaNegra = tempProfissional.listaNegra.map(
          (ap: { id: number; nome: string }) => ({
            value: ap.id,
            label: ap.nome,
          }),
        );

        tempProfissional.unidades = tempProfissional.unidades.map(
          (ap: { id: number; nome: string }) => ({
            value: ap.id,
            label: ap.nome,
          }),
        );

        tempProfissional.profissoes = tempProfissional.profissoes.map(
          (ap: { id: number; nome: string }) => ({
            value: ap.id,
            label: ap.nome,
          }),
        );

        tempProfissional.horarios = tempProfissional.horarios.map(
          (ap: { id: number; descricao: string }) => ({
            value: ap.id,
            label: ap.descricao,
          }),
        );

        tempProfissional.experienciasId = tempProfissional.experiencias.map(
          (ap: { id: number; nome: string }) => ({
            value: ap.id,
            label: ap.nome,
          }),
        );
        //tempProfissional.dataNascimento = format(parseISO(tempProfissional.dataNascimento), 'dd/MM/yyyy');

        setProfissional(tempProfissional);

        setExperienciasPro(
          tempProfissional.experienciasProfissionais.map(
            (item: any) => {
              let { dataInicio, dataFim } = item;

              if (dataInicio?.length > 7) {
                try {
                  dataInicio = format(parseISO(dataInicio), 'MM/yyyy');
                } catch (e) {
                  console.info(`A data início não é conversível [dataInicio: ${dataInicio}]`);
                }
              }

              if (dataFim?.length > 7) {
                try {
                  dataFim = format(parseISO(dataFim), 'MM/yyyy');
                } catch (e) {
                  console.info(`A data fim não é conversível [dataFim: ${dataFim}]`);
                }
              }

              return { ...item, dataFim, dataInicio };
            },
          ),
        );
        setCursos(
          tempProfissional.cursos.map((item: any) => {
            return {
              ...item,
              dataConclusao:
                item.dataConclusao.length > 7
                  ? format(
                    parseISO(item.dataConclusao),
                    'MM/yyyy',
                  )
                  : item.dataConclusao,
            };
          }),
        );
        setArquivosEnviados(tempProfissional.documentos);
        setComentarios(tempProfissional.comentarios);
        setLogStatus(tempProfissional.logsStatus);
        console.log(tempProfissional);
        setLoading(false);
      });
    }
  }, [id]);

  const handleRemoveExperiencia = useCallback(
    (id) => {
      setExperienciasPro((old) => old.filter((expe) => expe.id !== id));

      addToast({
        type: 'error',
        title: 'Experiencia removida',
      });
    },
    [addToast],
  );

  const handleRemoverCurso = useCallback(
    (id) => {
      setCursos((old) =>
        old.filter((expe) => expe.profissionalCursoId !== id),
      );
      addToast({
        type: 'success',
        title: 'Curso removido',
      });
    },
    [addToast],
  );

  const handleRemoveComentario = useCallback(
    (id) => {
      setComentarios((old) => old.filter((c) => c.id !== id));

      addToast({
        type: 'error',
        title: 'Comentário removido',
      });
    },
    [addToast],
  );
  const filesElement = acceptedFiles.map((file: any) => (
    <li className="list-group-item" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSetExperiencia = useCallback(
    (exp: IExperiencia): void => {
      //console.log(exp);
      if (experienciaEdit) {
        setExperienciasPro((old) =>
          [...old].map((object) => {
            if (object.id === exp.id) {
              return {
                ...exp,
              };
            }
            return object;
          }),
        );
      } else {
        setExperienciasPro((old) => [...old, exp]);
      }
    },
    [experienciaEdit],
  );

  const handleSetCurso = useCallback(
    (curso: ICurso): void => {
      if (cursoEdit) {
        setCursos((old) =>
          [...old].map((object) => {
            if (
              object.profissionalCursoId ==
              curso.profissionalCursoId
            ) {
              return {
                ...curso,
              };
            }
            return object;
          }),
        );
      } else {
        setCursos((old) => [...old, curso]);
      }
    },
    [cursoEdit],
  );

  const handleSetComentario = useCallback(
    (com: IComentario): void => {
      if (comentario) {
        setComentarios((old) =>
          [...old].map((object) => {
            if (object.id === com.id) {
              return {
                ...com,
              };
            }
            return object;
          }),
        );
      } else {
        setComentarios((old) => [...old, com]);
      }
    },
    [comentario],
  );



  const handleOpenModal = useCallback((data?: IExperiencia) => {
    setExperienciaEdit(data);
    modalRef.current?.handleShowModal();
  }, []);

  const handleOpenModalCurso = useCallback((data?: ICurso) => {
    setCursoEdit(data);
    modalCursoRef.current?.handleShowModal();
  }, []);

  const handleOpenModalComentario = useCallback((data?: IComentario) => {
    setComentario(data);
    modalComentarioRef.current?.handleShowModal();
  }, []);

  const handleOpenModalDocumento = useCallback((data: any) => {
    downloadDocumento(data);
  }, []);

  const handleDeletarDocumento = useCallback((data: any) => {
    deletarDocumento(data);
  }, []);

  const downloadDocumento = (documento: any) =>
    api
      .get(
        `/api/v1/painel/profissional/download-documento/${documento.id}/${id}`,
        { responseType: 'blob' },
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.data.type,
        });
        const src = window.URL.createObjectURL(blob);
        setSrcDocumento(src);
        setShowModalDocumento(true);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao carregar o arquivo.',
        });
      });


  const deletarDocumento = (documento: any) =>
    api
      .get(
        `/api/v1/painel/profissional/deletar-documento/${documento.id}/${id}`,
      ).then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Arquivo excluído.',
        });

        setArquivosEnviados(prevState => prevState.filter(x => x.id !== documento.id));
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao deletar o arquivo.',
        });
      });

  const handleCepSearch = useCallback(async (e) => {
    const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
    //console.log(formRef, 'e');

    if (cepValue.length === 8) {
      //console.log(cepValue);
      //const searchCep = async () => {
      try {
        const response = await cep(cepValue);
        //console.log(response);
        if (response) {
          formRef.current?.setFieldValue(
            `endereco.logradouro`,
            response.street,
          );
          formRef.current?.setFieldValue(
            `endereco.bairro`,
            response.neighborhood,
          );
          formRef.current?.setFieldValue(
            `endereco.localidade`,
            response.city,
          );
          formRef.current?.setFieldValue(
            `endereco.uf`,
            response.state,
          );
        }
      } catch (err) {
        switch (err.type) {
          case 'service_error':
            addToast({
              type: 'error',
              title: 'CEP não encontrado',
            });
            break;
          case 'validation_error':
            addToast({
              type: 'error',
              title: 'CEP possui um formato inválido',
            });
            break;
          default:
          // addToast({
          //     type: 'error',
          //     title: err,
          // });
          //setSearch(err);
        }

        formRef.current?.setFieldValue(`endereco.logradouro`, null);
        formRef.current?.setFieldValue(`endereco.bairro`, null);
        formRef.current?.setFieldValue(`endereco.localidade`, null);
        formRef.current?.setFieldValue(`endereco.uf`, null);
      }
    }
  }, []);

  // const handleCepSearch = useCallback(async (e) => {
  //     const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
  //     if (cepValue.length === 8) {
  //         const response = await cep(cepValue);
  //         formRef.current?.setFieldValue(
  //             `endereco.logradouro`,
  //             response.street,
  //         );
  //         formRef.current?.setFieldValue(
  //             `endereco.bairro`,
  //             response.neighborhood,
  //         );
  //         formRef.current?.setFieldValue(
  //             `endereco.localidade`,
  //             response.city,
  //         );
  //         formRef.current?.setFieldValue(`endereco.uf`, response.state);
  //     }
  // }, []);

  useEffect(() => {
    async function getPreLoad() {
      const { data } = await api.get(
        '/api/v1/painel/profissional/preload',
      );
      const dados = data.data;
      //.log(dados);
      setAptidoes(
        dados.aptidoes.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setListaNegra(
        dados.beneficiarios.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setEstadoCivil(
        dados.estadoCivil.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setExperiencias(
        dados.experiencias.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setGrauEscolaridade(
        dados.grauEscolaridade.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      sethorarios(
        dados.horarios.map((item: any) => ({
          label: item.descricao,
          value: item.id,
        })),
      );
      setprofissoes(
        dados.profissoes.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setsexo(
        dados.sexo.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
      setBancos(
        dados.bancos.map((item: any) => ({
          label: item.nome,
          value: item.numero,
          key: item.id,
        })),
      );

      setContas(
        dados.contas.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
    }

    async function getUnidades() {
      const { data } = await api.get(`api/v1/painel/unidade/usuario`);
      setUnidades(
        data.data.map((item: any) => ({
          label: item.nome,
          value: item.id,
        })),
      );
    }

    breadcrumbItens = [
        {
          title: 'Listagem',
          link: window.location.href.includes('precadastro') ? '/precadastro': '/profissionais',
          active: false,
        },
        {
          title: window.location.href.includes('profissionais/new') ? 'Cadastrar Profissional' : window.location.href.includes('edit') ? 'Editar Profissional' : 'Cadastro Profissional',
          link: '#',
          active: true,
        },
      ]
    getPreLoad();
    getUnidades();
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const dataToSend = parseObjectTypes(data, [
          'complemento',
          'cidade',
          'localidade',
          'bairro',
          'email',
          'rg',
          'cep',
          'numero',
          'logradouro',
          'cpf',
          'banco',
          'isPossuiMei',
        ]);

        dataToSend.isPossuiMei = !!dataToSend.isPossuiMei;

        const enderecoSchema = Yup.object().shape({
          cep: Yup.string()
            .required('CEP é obrigatório')
            .min(8, 'CEP precisa ter pelo menos 8 números')
            .max(8, 'CEP precisa ter no máximo 8 números'),
          logradouro: Yup.string().required(
            'Logradouro da profissional é obrigatório',
          ),
          complemento: Yup.string(),
          bairro: Yup.string().required('Bairro é obrigatório'),
          uf: Yup.string().required('UF é obrigatório'),
          localidade: Yup.string().required('Cidade é obrigatório'),
          numero: Yup.number()
            .typeError('Digite o número')
            .required('Número é obrigatório'),
        });

        const bancoSchema = Yup.object().shape({
          numero: Yup.string().required('Número é obrigatório'),
          agencia: Yup.string().required('Agência é obrigatório'),
          operacao: Yup.string(),
          conta: Yup.string().required('Conta é obrigatório'),
        });
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório'),
          dataNascimento: Yup.string().required(
            'Data de nascimento é obrigatória',
          ),
          quantidadeFilho: Yup.string(),
          cpf: Yup.string().required('Cpf é obrigatório'),
          rg: Yup.string().required('RG é obrigatório'),
          rgEmissor: Yup.string().required(
            'Emissor do RG é obrigatório',
          ),
          pis: Yup.string(),
          email: Yup.string()
            .email('Email não é válido!')
            .required('Email é obrigatório'),
          celular: Yup.string().required('Celular é obrigatório'),
          telefone: Yup.string(),
          isFumante: Yup.boolean().required(
            'Informar se fuma é obrigatório',
          ),
          isPossuiHabilitacao: Yup.boolean().required(
            'Informar se possui habilitação é obrigatório',
          ),
          habilitacaoTipo: Yup.string(),
          isPossuiCarro: Yup.boolean().required(
            'Informar se possui carro é obrigatório',
          ),
          isPossuiMoto: Yup.boolean().required(
            'Informar se possui moto é obrigatório',
          ),
          isAceitaViajar: Yup.boolean().required(
            'Informar se aceita viagem é obrigatório',
          ),
          isPossuiDeficiencia: Yup.boolean().required(
            'Informar se possui deficiência é obrigatório',
          ),
          deficiencia: Yup.string(),
          isPossuiMei: Yup.boolean().required(
            'Informar se o beneficiário possui MEI',
          ),
          mei: Yup.string(),
          formacaoLocal: Yup.string().required(
            'Formação Local é obrigatório',
          ),
          formacaoAnoTermino: Yup.number()
            .typeError('Digite o ano')
            .required('Ano de término da formação é obrigatória')
            .min(1900, 'Menor ano permitido é 1900')
            .max(
              new Date().getFullYear(),
              `Maior ano permitido é${new Date().getFullYear()}`,
            ),
          grauEscolaridadeId: Yup.number().required(
            'Grau de escolaridade é obrigatória',
          ),
          coren: Yup.string(),
          corenValidade: Yup.string(),
          isEmpregadoAtualmente: Yup.boolean().required(
            'Informar se está empregado é obrigatório',
          ),
          empregadoArea: Yup.string(),
          restricaoAtendimento: Yup.string(),
          endereco: enderecoSchema,
          banco: bancoSchema,
          // atividades: Yup.array().required(
          //     'Atividades é obrigatório',
          // ),
          //   horarios: Yup.array().required('Horários é obrigatório'),
          //   aptidoes: Yup.array().required('Aptidões é obrigatório'),
          //   profissoes: Yup.array().required('Profissão é obrigatória'),
          aptidaoOutros: Yup.string(),
        });

        //dataToSend.dataNascimento = parse(dataToSend.dataNascimento, 'dd/MM/yyyy', new Date());

        const findBanco = bancos.find(
          (banco) => banco.value === dataToSend.banco.numero,
        );

        dataToSend.banco.nome = findBanco?.label;

        await schema.validate(dataToSend, { abortEarly: false });

        //converte os arquivos
        const filesPromises = files.map(async (file: any) => {
          const formData: FormData = new FormData
          formData?.append("arquivo", file.file)
          const response = await api.post('/api/v1/public/arquivo/ler-arquivo', formData, { headers: { "Content-Type": "multipart/form-data" } })
          return { promise: response, tipoArquivoId: file.tipoArquivoId }
        })
        dataToSend.documentos = [];

        //adiciona o ID do tipo de arquivo ao objeto de arquivo para ser enviado
        try {
          const results = await Promise.all(filesPromises);
          const filesWithIds = results.map((result, index) => {
            return {
              ...result.promise.data.data,
              tipoArquivoId: files[index].typeFileId
            }
          });
          dataToSend.documentos = filesWithIds;
        } catch (error) {
          // Lida com erros
        }

        if (dataToSend.documentos.length === 0 && !id) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description:
              'Pelo menos um documento deve ser anexado!',
          });

          return;
        }

        dataToSend.experienciasProfissionais = experienciasPro.map(
          (ex: any) => {
            delete ex.id;
            return ex;
          },
        );

        dataToSend.comentarios = comentarios.map((ex: any) => {
          delete ex.id;
          return ex;
        });

        dataToSend.cursos = cursos.map((ex: any) => {
          delete ex.profissionalCursoId;
          return ex;
        });

        // console.log(dataToSend);

        if (dataToSend.experienciasProfissionais.length === 0) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description:
              'Pelo menos uma experiência profissional deve ser cadastrada deve ser anexado!',
          });

          return;
        }

        // const formData = new FormData();
        // formData.append('data', dataToSend);

        const formData = dataToSend;

        formData.unidades = Object.keys(formData.unidades).map((key: any) => formData.unidades[key]);
        formData.profissoes = Object.keys(formData.profissoes).map((key: any) => formData.profissoes[key]);
        formData.aptidoes = Object.keys(formData.aptidoes).map((key: any) => formData.aptidoes[key]);
        formData.horarios = Object.keys(formData.horarios).map((key: any) => formData.horarios[key]);
        formData.listaNegra = Object.keys(formData.listaNegra).map((key: any) => formData.listaNegra[key]);
        formData.atividades = Object.keys(formData.experienciasId).map((key: any) => formData.experienciasId[key]);

        if (id) {
          dataToSend.id = Number(id);
          //   formData.append('id', id);
          await api.put(`/api/v1/painel/profissional/${id}`,formData);
        } else {
          await api.post('/api/v1/painel/profissional', formData);
        }

        addToast({
          type: 'success',
          title: 'Profissional Cadastrado com sucesso',
        });
        history.goBack();
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          addToast({
            type: 'error',
            title: 'Erro ao validar dados',
            description: 'Verifique o formulário',
          });

          formRef.current?.setErrors(errors);
        } else if (err.response) {
          const { errors } = err.response.data;

          errors?.forEach((error: string) => {
            addToast({
              type: 'error',
              title: 'Erro ao cadastrar',
              description: error,
            });
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description:
              'Ocorreu um erro ao cadastrar o profissional',
          });
        }
      }
    },
    [
      addToast,
      history,
      acceptedFiles,
      experienciasPro,
      comentarios,
      cursos,
      id,
      bancos,
      files,
      filesSelected,
      profissional
    ],
  );

  useEffect(() => {
    const getTypeFiles = async () => {
      try {
        const response = await api.get(`/api/v1/painel/tipo-arquivo`);
        setTypeFiles(response.data.data?.map((item: any) => ({ label: item.nome, value: item.descricao, id: item.id })));
      } catch (error) {
        addToast({
          title: 'Ooops!',
          description: 'Alguma coisa deu errado ao entre em contato com o administrador do sistema',
          type: 'error'
        });
      }
    }
    getTypeFiles()
  }, [])
  return (
    <Container fluid>
      <Breadcrumb itens={breadcrumbItens} />
      {!loading && (
        <Form
          ref={formRef}
          initialData={profissional}
          onSubmit={handleSubmit}
        >
          <Card>
            <Card.Header>
            {
                window.location.href.includes('edit')
                ? <h3>Editar Profissional</h3>
                : <h3>Cadastrar Profissional</h3>
            }
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={5}>
                  <Input
                    icon={FiUser}
                    name="nome"
                    type="text"
                    placeholder="Nome"
                  />
                </Col>
                <Col lg={4}>
                  <ReactSelectInput
                    isMulti
                    name="unidades"
                    placeholder="Unidades"
                    options={unidades}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="dataNascimento"
                    type="date"
                    placeholder="Data de nascimento"
                    max="2999-12-31"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="sexoId"
                    type="text"
                    placeholder="Sexo"
                    options={sexo}
                  />
                </Col>
                <Col lg={2}>
                  <MaskInput
                    mask="999"
                    name="peso"
                    type="text"
                    placeholder="Peso"
                  />
                </Col>
                <Col lg={2}>
                  <MaskInput
                    mask="9.99"
                    name="altura"
                    type="text"
                    placeholder="Altura"
                  />
                </Col>
                <Col lg={2}>
                  <Select
                    name="isPossuiFilho"
                    type="text"
                    placeholder="Possui Filho"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>

                <Col lg={3}>
                  <MaskInput
                    mask="99"
                    name="quantidadeFilho"
                    type="text"
                    placeholder="Qtd. Filhos"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="estadoCivilId"
                    type="text"
                    placeholder="Estado Civil"
                    options={estadoCivil}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="rg"
                    type="text"
                    placeholder="RG"
                  />
                </Col>
                <Col lg={2}>
                  <Input
                    name="rgEmissor"
                    type="text"
                    placeholder="Emissor"
                  />
                </Col>
                <Col lg={4}>
                  <MaskInput
                    mask="999.999.999-99"
                    name="cpf"
                    type="text"
                    placeholder="CPF"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    icon={FiMail}
                    name="email"
                    type="email"
                    placeholder="E-mail"
                  />
                </Col>
                <Col lg={3}>
                  <MaskInput
                    mask="(99)99999-9999"
                    icon={FiPhone}
                    name="telefone"
                    type="tel"
                    placeholder="Telefone"
                  />
                </Col>
                <Col lg={3}>
                  <MaskInput
                    mask="(99)99999-9999"
                    icon={FiPhone}
                    name="celular"
                    type="tel"
                    placeholder="Celular"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isFumante"
                    type="text"
                    placeholder="Fuma?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isPossuiCarro"
                    type="text"
                    placeholder="Possui Carro?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isPossuiMoto"
                    type="text"
                    placeholder="Possui Moto?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isPossuiHabilitacao"
                    type="text"
                    placeholder="Possui Habilitação?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="habilitacaoTipo"
                    type="text"
                    placeholder="Tipo Habilitação"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isPossuiDeficiencia"
                    type="text"
                    placeholder="Possui Deficiência?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="deficiencia"
                    type="text"
                    placeholder="Tipo Deficiência"
                  />
                </Col>
                <Col lg={5}>
                  <Select
                    name="isAceitaViajar"
                    type="text"
                    placeholder="Tem disponibilidade para viajar?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="isEmpregadoAtualmente"
                    type="text"
                    placeholder="Está empregado atualmente"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="empregadoArea"
                    type="text"
                    placeholder="Área que está empregado"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="restricaoAtendimento"
                    type="text"
                    placeholder="Restrições de Atendimento"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="aptidaoOutros"
                    type="text"
                    placeholder="Outras aptidões"
                  />
                </Col>
              </Row>
              <h5 className="mt-5">Banco</h5>
              <hr />
              <Scope path="banco">
                <Row>
                  <Col lg={3}>
                    <Select
                      name="numero"
                      keyName="key"
                      type="text"
                      placeholder="Banco"
                      options={bancos}
                    />
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="agencia"
                      type="text"
                      placeholder="Agência com dígito"
                    />
                  </Col>
                  <Col lg={1}>
                    <Input
                      name="operacao"
                      type="text"
                      placeholder="Operação"
                    />
                  </Col>
                  <Col lg={2}>
                    <Select
                      name="tipo"
                      type="text"
                      placeholder="Tipo Conta"
                      options={contas}
                    />
                  </Col>
                  <Col lg={3}>
                    <MaskInput
                      name="conta"
                      type="text"
                      mask="9999999999"
                      placeholder="Conta"
                    />
                  </Col>
                </Row>
              </Scope>
              <h5>Endereço</h5>
              <hr />
              <Scope path="endereco">
                <Row>
                  <Col lg={4}>
                    <MaskInput
                      icon={FiMapPin}
                      name="cep"
                      type="text"
                      mask="99999999"
                      placeholder="Digite seu Cep"
                      onKeyUp={handleCepSearch}
                    />
                    <small>
                      O endereço será preenchido
                      automaticamente.
                    </small>
                  </Col>

                  <Col lg={4}>
                    <Input
                      name="localidade"
                      type="text"
                      placeholder="Cidade"
                      readOnly
                    />
                  </Col>

                  <Col lg={2}>
                    <Input
                      name="uf"
                      type="text"
                      placeholder="UF"
                      readOnly
                    />
                  </Col>

                  <Col lg={2}>
                    <Input
                      name="numero"
                      type="text"
                      placeholder="N°"
                    />
                  </Col>

                  <Col lg={4}>
                    <Input
                      name="bairro"
                      type="text"
                      placeholder="Bairro"
                      readOnly
                    />
                  </Col>

                  <Col lg={4}>
                    <Input
                      name="logradouro"
                      type="text"
                      placeholder="Logradouro"
                      readOnly
                    />
                  </Col>

                  <Col lg={4}>
                    <Input
                      name="complemento"
                      type="text"
                      placeholder="Complemento"
                    />
                  </Col>
                </Row>
              </Scope>

              <h5>Dados Profissionais</h5>
              <hr />
              <Row>
                <Col lg={4}>
                  <ReactSelectInput
                    isMulti
                    name="profissoes"
                    placeholder="Profissões"
                    options={profissoes}
                  />
                </Col>

                <Col lg={4}>
                  <Input
                    name="formacaoLocal"
                    type="text"
                    placeholder="Local de formação"
                  />
                </Col>

                <Col lg={4}>
                  <MaskInput
                    name="formacaoAnoTermino"
                    type="text"
                    mask="9999"
                    placeholder="Ano de termino da formação"
                  />
                </Col>
                <Col lg={4}>
                  <Select
                    name="grauEscolaridadeId"
                    type="text"
                    placeholder="Grau de escolaridade"
                    options={grauEscolaridade}
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="pis"
                    type="text"
                    placeholder="Num. PIS"
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="coren"
                    type="text"
                    placeholder="Coren"
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="corenValidade"
                    type="date"
                    placeholder="Validade do Coren"
                  />
                </Col>
                <Col lg={4}>
                  <Select
                    name="isPossuiMei"
                    placeholder="Possui MEI?"
                    options={[
                      { label: 'NÃO', value: false },
                      { label: 'SIM', value: true },
                    ]}
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="mei"
                    type="text"
                    placeholder="Número do MEI"
                  />
                </Col>

                <Col lg={6}>
                  <ReactSelectInput
                    isMulti
                    name="experienciasId"
                    placeholder="Experiências"
                    options={experiencias}
                  />
                </Col>
                <Col lg={6}>
                  <ReactSelectInput
                    isMulti
                    name="horarios"
                    placeholder="Disp. Horários"
                    options={horarios}
                  />
                </Col>
                <Col lg={12}>
                  <ReactSelectInput
                    isMulti
                    name="aptidoes"
                    placeholder="Aptidões"
                    options={aptidoes}
                  />
                </Col>
                <Col lg={12}>
                  <ReactSelectInput
                    isMulti
                    name="listaNegra"
                    placeholder="Não enviar para: (Beneficiários)"
                    options={listaNegra}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <h5>Experiências Profissionais</h5>
                </Col>
                <Col md={{ span: 2, offset: 6 }}>
                  <Button
                    type="button"
                    color={colors.primary}
                    textcolor={colors.light}
                    onClick={() => handleOpenModal()}
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
              {experienciasPro.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Local</th>
                          <th>Cargo</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {experienciasPro.map(
                          (experiencia) => (
                            <tr
                              key={
                                experiencia.local
                              }
                            >
                              <td
                                style={{
                                  width: '40%',
                                }}
                              >
                                {
                                  experiencia.local
                                }
                              </td>
                              <td
                                style={{
                                  width: '35%',
                                }}
                              >
                                {
                                  experiencia.cargo
                                }
                              </td>
                              <td
                                className="d-flex align-items-center justify-conten-center"
                                style={{
                                  width: '25%',
                                }}
                              >
                                <Button
                                  type="button"
                                  color="#e53935"
                                  textcolor={
                                    colors.light
                                  }
                                  onClick={() =>
                                    handleRemoveExperiencia(
                                      experiencia.id,
                                    )
                                  }
                                >
                                  <FiTrash2
                                    size={
                                      23
                                    }
                                  />
                                </Button>
                                <Button
                                  type="button"
                                  color={
                                    colors.primary
                                  }
                                  textcolor={
                                    colors.light
                                  }
                                  onClick={() =>
                                    handleOpenModal(
                                      experiencia,
                                    )
                                  }
                                >
                                  <FiEdit
                                    size={
                                      23
                                    }
                                  />
                                </Button>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                <h6>
                  Nenhuma experiência profissional adicionada!
                </h6>
              )}
              <hr />
              <Row>
                <Col md={4}>
                  <h5>Cursos</h5>
                </Col>
                <Col md={{ span: 2, offset: 6 }}>
                  <Button
                    type="button"
                    color={colors.primary}
                    textcolor={colors.light}
                    onClick={() => handleOpenModalCurso()}
                  >
                    Adicionar curso
                  </Button>
                </Col>
              </Row>
              {cursos.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome do curso</th>
                          <th>Carga Horária</th>
                          <th>Data Conclusão</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cursos.map((curso) => (
                          <tr
                            key={
                              curso.profissionalCursoId
                            }
                          >
                            <td
                              style={{
                                width: '25%',
                              }}
                            >
                              {curso.nomeDoCurso}
                            </td>
                            <td
                              style={{
                                width: '25%',
                              }}
                            >
                              {`${curso.cargaHoraria}h`}
                            </td>
                            <td
                              style={{
                                width: '25%',
                              }}
                            >
                              {
                                curso.dataConclusao
                              }
                            </td>
                            <td
                              className="d-flex align-items-center justify-conten-center"
                              style={{
                                width: '25%',
                              }}
                            >
                              <Button
                                type="button"
                                color="#e53935"
                                textcolor={
                                  colors.light
                                }
                                onClick={() =>
                                  handleRemoverCurso(
                                    curso.profissionalCursoId,
                                  )
                                }
                              >
                                <FiTrash2
                                  size={23}
                                />
                              </Button>
                              <Button
                                type="button"
                                color={
                                  colors.primary
                                }
                                textcolor={
                                  colors.light
                                }
                                onClick={() =>
                                  handleOpenModalCurso(
                                    curso,
                                  )
                                }
                              >
                                <FiEdit
                                  size={23}
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                <h6>Nenhum curso cadastrado.</h6>
              )}
              <hr />
              <Row>
                <Col md={4}>
                  <h5>Comentários</h5>
                </Col>
                <Col md={{ span: 2, offset: 6 }}>
                  <Button
                    type="button"
                    color={colors.primary}
                    textcolor={colors.light}
                    onClick={() =>
                      handleOpenModalComentario()
                    }
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
              {comentarios.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Descrição</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comentarios.map((comen) => (
                          <tr key={comen.descricao}>
                            <td
                              style={{
                                width: '75%',
                              }}
                            >
                              {comen.descricao}
                            </td>

                            <td
                              className="d-flex align-items-center justify-conten-center"
                              style={{
                                width: '25%',
                              }}
                            >
                              <Button
                                type="button"
                                color="#e53935"
                                textcolor={
                                  colors.light
                                }
                                onClick={() =>
                                  handleRemoveComentario(
                                    comen.id,
                                  )
                                }
                              >
                                <FiTrash2
                                  size={23}
                                />
                              </Button>
                              <Button
                                type="button"
                                color={
                                  colors.primary
                                }
                                textcolor={
                                  colors.light
                                }
                                onClick={() =>
                                  handleOpenModalComentario(
                                    comen,
                                  )
                                }
                              >
                                <FiEdit
                                  size={23}
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                <h6>Nenhum comentário adicionado!</h6>
              )}
              <h5 className="mt-5">Anexar documentos</h5>
              <hr />
              <Row>
                <Col lg={12}>
                  <div className='mx-3'>
                    <ReactSelectInput
                      isMulti
                      name="files"
                      placeholder="Selecione os tipos de arquivos"
                      options={typeFiles}
                      handleChange={(options: any) => handleChangeTypeFiles(options)}
                    />

                    {filesSelected.map((item: any, index: any) => (
                      <>
                        {
                        }
                        <FileUpload
                          key={index}
                          onFileUpload={(file) => setFiles([...files, file])
                          }
                          typeFile={item.label}
                          typeFileId={item.id}
                        />
                      </>
                    ))

                    }
                  </div>

                </Col>

                <Col lg={12}>
                  <section className="container-fluid dropzone-container">

                    {arquivosEnviados.length > 0 && (
                      <>
                        <h4 className="mt-2">
                          Arquivos já enviados
                        </h4>
                        <ul className="list-group">
                          {arquivosEnviados.map(
                            (arquivo: any) => (
                              <li
                                key={
                                  arquivo.id
                                }
                                className="list-group-item d-flex justify-content-between"
                              >
                                {
                                  arquivo.url
                                }
                                <div>

                                  <ButtonOriginal
                                    onClick={() =>
                                      handleOpenModalDocumento(
                                        arquivo,
                                      )
                                    }
                                    variant="primary"
                                  >
                                    Ver
                                  </ButtonOriginal>
                                  <ButtonOriginal
                                    onClick={() =>
                                      handleDeletarDocumento(
                                        arquivo,
                                      )
                                    }
                                    variant="danger"
                                  >
                                    Excluir
                                  </ButtonOriginal>
                                </div>
                              </li>
                            ),
                          )}
                        </ul>
                      </>
                    )}

                  </section>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col md={4}>
                  <h5>Log Status</h5>
                </Col>
              </Row>
              {logStatus.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Ação</th>
                          <th>Descrição</th>
                          <th>Funcionário</th>
                          <th>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logStatus.map((comen) => (
                          <tr key={comen.descricao}>
                            <td>
                              {comen.desativado
                                ? 'Ativação'
                                : 'Desativação'}
                            </td>
                            <td>
                              {comen.descricao}
                            </td>
                            <td>
                              {
                                comen.nomeFuncionario
                              }
                            </td>
                            <td>
                              {format(
                                parseISO(
                                  comen.data,
                                ),
                                'dd/MM/yyyy HH:mm',
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                <h6>Nenhum log encontrado!</h6>
              )}

              <Row>
                <Col md={{ span: 4, offset: 4 }}>
                  <Button
                    type="submit"
                    color={colors.primary}
                    textcolor={colors.light}
                  >
                    <FiCheck />
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      )}
      <ModalExperiencia
        ref={modalRef}
        setExperienciasPro={handleSetExperiencia}
        experienciaPro={experienciaEdit}
      />
      <ModalComentario
        ref={modalComentarioRef}
        setComentario={handleSetComentario}
        comentario={comentario}
      />
      <ModalCurso
        ref={modalCursoRef}
        setCurso={handleSetCurso}
        curso={cursoEdit}
      />
      <Modal
        show={showModalDocumento}
        onHide={handleCloseModalDocumento}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualização documento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '500px' }}>
          <Alert variant="danger">
            <strong style={{ fontWeight: 'bold' }}>
              Aviso! Este documento é sigiloso, ao baixá-lo você
              estará sujeito as penalidades da lei de proteção de
              dados. Todos os acessos são monitorados e auditados.
            </strong>
          </Alert>
          <div
            style={{
              margin: '0px',
              padding: '0px',
              overflow: 'hidden',
            }}
          >
            <iframe
              frameBorder="0"
              style={{
                width: '100%',
                minHeight: '500px',
                overflowX: 'hidden',
              }}
              src={srcDocumento}
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonOriginal
            onClick={handleCloseModalDocumento}
            variant="secondary"
          >
            Fechar
          </ButtonOriginal>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FormProfissional;
function fileTypeFromBuffer(data: any): any {
  throw new Error('Function not implemented.');
}
