import React, { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, Button as ButtonOriginal } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';
import Swal from 'sweetalert2';
import { Switch } from '@mui/material';
import Confirm from '../../components/Confirm';
import { colors } from '../../styles/variables';
import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';

interface BannerProps {
    id: number;
    nome: string;
    descricao: string;
    imagePath: string;
    arquivo: File;
    extensao: string;
    isAtivo: boolean;
}

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/banner',
        active: true,
    },
];

const Banner: React.FC = () => {
    const [banners, setBanners] = useState<BannerProps[]>(
        [] as BannerProps[],
    );
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [srcImagem, setsrcImagem] = useState<string>('');
    const [showModalImagem, setShowModalImagem] = useState<boolean>(false);
    const handleCloseModalImagem = () => setShowModalImagem(false);
    const { addToast } = useToast();
    const getBanners = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api.get(`/api/v1/painel/banner`);
            const { data } = response;
            setBanners(data.data);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    'Não conseguimos exibir as telas, tente novamente!',
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    useEffect(() => {
        getBanners();
    }, [getBanners]);

    const hanndleOpenModalImagem = useCallback((data: BannerProps) => {
        verImagem(data);
    }, []);

    const verImagem = (imagem: BannerProps) =>
        api
            .get(
                `/api/v1/painel/banner/visualizar-imagem/${imagem.id}`,
                { responseType: 'blob' },
            )
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: response.data.type,
                });
                const src = window.URL.createObjectURL(blob);
                setsrcImagem(src);
                setShowModalImagem(true);
            })
            .catch((error) => {
                addToast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Ocorreu um erro ao carregar o arquivo.',
                });
            });

    const handleDeleteBanner = useCallback((idBanner) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Deseja realmente realizar esta operação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Fechar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/api/v1/painel/banner/${idBanner}`);
                    getBanners();
                    addToast({
                        type: 'success',
                        title: 'Operação realizada com sucesso!',
                    });
                } catch {
                    addToast({
                        type: 'error',
                        title: 'Ooops! Algo deu errado!',
                        description: 'Erro ao excluir o banner!',
                    });
                }
            }
        });
    }, [addToast]);

    const handleActiveBanner = useCallback(async (id: number) => {

        if ((await Confirm()).isConfirmed) {
            try {
                const response = await api.put(`/api/v1/painel/banner/alterar-status/${id}`)
                if (response.status === 200) {
                    await getBanners()
                    addToast({ type: 'success', title: 'Banner atualizado com sucesso!' })
                } else {
                    addToast({
                        type: 'error',
                        title: 'Não foi possível atualizar o banner',
                        description: 'Entre em contato com o admistrador do sistema'
                    });
                }

            } catch (error) {
                addToast({
                    type: 'error',
                    title: 'Não foi possível atualizar o banner',
                    description: 'Entre em contato com o admistrador do sistema'
                });
            }
        }
    }, [addToast])

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            {loading ? (
                <Loading />
            ) : (
                <Card>
                    <Card.Header>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col md={10}>
                                    <h5>Listagem de Banners</h5>
                                </Col>
                                <Col md={2}>
                                    <Options>
                                        <OptionButton
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() =>
                                                history.push('/banner/new')
                                            }
                                        >
                                            <FiPlus color={colors.light} />
                                            Adicionar
                                        </OptionButton>
                                    </Options>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <List responsive>
                            <Header>
                                <tr>
                                    <th>Imagem</th>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Link da imagem</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </Header>
                            <Content>
                                {banners.map((banner) => (
                                    <tr key={banner.id}>
                                        <td> <ButtonOriginal
                                            onClick={() =>
                                                hanndleOpenModalImagem(
                                                    banner,
                                                )
                                            }
                                            variant="primary"
                                        >
                                            Ver
                                        </ButtonOriginal></td>
                                        <td>{banner.nome}</td>
                                        <td>{banner.descricao}</td>
                                        <td>{banner.imagePath}</td>
                                        <td><Switch checked={banner.isAtivo} onChange={() => handleActiveBanner(banner.id)} /></td>
                                        <td style={{ width: '14%' }}>
                                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Options>
                                                        <OptionButton
                                                            type="button"
                                                            color={colors.primary}
                                                            textcolor={colors.light}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/banner/${banner.id}/edit`,
                                                                )
                                                            }
                                                        >
                                                            <FiEdit
                                                                color={colors.light}
                                                            />
                                                            Editar
                                                        </OptionButton>
                                                    </Options>
                                                </div>
                                                <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        type="button"
                                                        color={colors.danger}
                                                        textcolor={colors.light}
                                                        onClick={() => handleDeleteBanner(banner.id)}
                                                    >
                                                        <FiTrash
                                                            color={colors.light}
                                                        />
                                                        Excluir
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    </Card.Body>
                    <Modal
                        show={showModalImagem}
                        onHide={handleCloseModalImagem}
                        size="xl"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Visualização imagem do banner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ minHeight: '500px' }}>
                            <div
                                style={{
                                    margin: '0px',
                                    padding: '0px',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    style={{
                                        width: '100%',
                                        height: '500px',
                                        overflowX: 'hidden',
                                    }}
                                    src={srcImagem}
                                ></img>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonOriginal
                                onClick={handleCloseModalImagem}
                                variant="secondary"
                            >
                                Fechar
                            </ButtonOriginal>
                        </Modal.Footer>
                    </Modal>
                </Card>
            )}
        </Container>
    );
};

export default Banner;
