import styled from 'styled-components';
import { Card as BSCard, Container as BSContainer } from 'react-bootstrap';
import Button from '../components/Button';
import { colors } from './variables';

export const Container = styled(BSContainer)`
    margin-top: 24px;
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    header {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const Options = styled.div`
    display: flex;
`;

export const OptionButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & + & {
        margin-left: 8px;
    }

    svg {
        margin-right: 8px;
    }
`;

export const CheckBox = styled.label`
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    margin-right: 30px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 42%;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        background-color: rgba(1, 1, 1, 0.2);
    }

    input:checked ~ .checkmark {
        background-color: ${colors.primary};
    }

    input:disabled ~ .checkmark {
        background-color: rgba(1, 1, 1, 0.05);
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 6px;
        top: 1px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
