import React, { useState } from "react";
import { Container } from "./styles";
import { Switch } from "@mui/material";
import type { Permission as IPermission } from "../";

interface PermissionProps {
  name: string;
  checked: boolean;
  positionFunction: number;
  positionPermission: number;
  onChange(permission: IPermission, positionFunction: number, positionPermission: number): void;
}

const Permission = (props: PermissionProps) => {
  const [permission, setPermission] = useState(props.checked);

  return (
    <Container>
      {props.name}{" "}
      <Switch
        checked={permission}
        onChange={(event, checked) => {
          setPermission(checked);
          props.onChange({ nome: props.name, concedido: checked, permissoes: [] }, props.positionFunction, props.positionPermission);
        }}
      />
    </Container>
  );
};

export default Permission;
