import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

export enum Roles {
    all = 0,
    administrador = 'Administrador',
    adminApp = 2,
    cliente = 3,
}

export enum Funcoes {
    admin = "admin",
    all = "all",
    Unidade = "Unidade",
    Funcionario = "Funcionario",
    Responsavel = "Responsavel",
    Beneficiario = "Beneficiario",
    Profissional = "Profissional",
    PreCadastro = "PreCadastro",
    Profissao = "Profissao",
    Vaga = "Vaga",
    Fatura = "Fatura",
    Banner = "Banner",
    RelatorioTaxaAgenciamento = "RelatorioTaxaAgenciamento",
    RelatorioEnviosWhatsapp = "RelatorioEnviosWhatsapp",
    RelatorioTaxaEmpresa = "RelatorioTaxaEmpresa",
    RelatorioNfeProfissional = "RelatorioNfeProfissional",
    RelatorioNfeResponsavel = "RelatorioNfeResponsavel",
    RelatorioBeneficiarios = "RelatorioBeneficiarios",
}

export enum Permissoes{
    Acessar = "Acessar",
    Consultar = "Consultar",
    Alterar = "Alterar",
    Incluir = "Incluir",
    Excluir = "Excluir",
    Imprimir = "Imprimir",
    Visualizar = "Visualizar",
    Enviar = "Enviar",
}

interface AuthState {
    token: string;
    user: UserData;
}

interface SignInCredentials {
    email: string;
    password: string;
}
interface AuthContextInterface {
    user: UserData;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
    hasPermission(permission: Funcoes): boolean;
}

interface UserData {
    login: string;
    nome: string;
    tipo: string;
    funcoes: UserFuncoes[]
}

interface UserFuncoes {
    nome: string;
    permissoes: string[]
}

export const AuthContext = createContext<AuthContextInterface>(
    {} as AuthContextInterface,
);

export const AuthProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@MaisEnfermagem:token');
        const user = localStorage.getItem('@MaisEnfermagem:user');

        if (token && user) {
            api.defaults.headers.Authorization = `Bearer ${token}`;

            return { token, user: JSON.parse(user) };
        }
        delete api.defaults.headers.common.Authorization;

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ email, password }) => {
        const response = await api.post(
            '/api/v1/painel/auth/signin',
            {
                Email: email,
                Password: password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            },
        );
        const { accessToken, login, nome, tipo, funcoes } = response.data.data;
        localStorage.setItem('@MaisEnfermagem:token', accessToken);
        localStorage.setItem(
            '@MaisEnfermagem:user',
            JSON.stringify({ login, nome, tipo, funcoes }),
        );
        api.defaults.headers.Authorization = `Bearer ${accessToken}`;

        setData({ token: accessToken, user: { login, nome, tipo, funcoes } });
    }, []);

    const hasPermission = useCallback(
        (permission) => {
            if (permission === Funcoes.all) return true;
            if(permission === Funcoes.admin){
                if(data?.user?.tipo === 'Administrador') return true
            }
            var funcao = data.user?.funcoes.find(function (funcao) {
                return permission.trim().toUpperCase() === funcao.nome.trim().toUpperCase()
            });

            if (!funcao) return false;


            var permissaoAcessar = funcao.permissoes.find(function (permissao) {
                return permissao.trim().toUpperCase() === Permissoes.Acessar.trim().toUpperCase()
            });

            if (!permissaoAcessar) return false;

            return true;
        },
        [data.user],
    );

    const signOut = useCallback(() => {
        localStorage.removeItem('@MaisEnfermagem:token');
        localStorage.removeItem('@MaisEnfermagem:user');
        delete api.defaults.headers.Authorization;

        setData({} as AuthState);
    }, []);

    return (
        <AuthContext.Provider
            value={{ user: data.user, signIn, signOut, hasPermission }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth(): AuthContextInterface {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used withn an AuthProvider');
    }

    return context;
}
