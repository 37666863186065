import React from 'react';
import imgThanks from '../../../../assets/thanks.svg';
import logo from '../../../../assets/logo-nova-branca-completa.png';
import { Header } from '../styles';
import { Container } from './styles';

const Agradecimento: React.FC = () => {
    return (
        <Container>
            <Header>
                <img src={logo} alt="Mais Enfermagem" />
            </Header>
            <div className="d-flex align-items-center justify-content-center">
                <div>
                    <img src={imgThanks} alt="Agradecimento Mais Enfermagem" />
                    <h1 className="mt-4">Obrigado,</h1>
                    <h5>Seu cadastro foi efetuado com sucesso!</h5>
                </div>
            </div>
        </Container>
    );
}

export default Agradecimento;
