import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { RiFileExcel2Line } from 'react-icons/ri';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useToast } from '../../../hooks/toast';
import formatValue from '../../../helpers/formatValue';
import Breadcrumb from '../../../components/Breadcrumb';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Pdf } from '../../../components/Pdf';
import { colors } from '../../../styles/variables';
import api from '../../../services/api';
import { Container, Card } from '../../../styles/components';
import AsyncReactSelectInput from '../../../components/AsyncReactSelect';
import { IRelatorio } from '../../../interfaces/IRelatorio';
import ReactSelectInput from '../../../components/ReactSelect';
import Select, { SelectProps } from '../../../components/SelectInput';
import { IEnvio } from '../../../interfaces/IEnvio';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/clientes/beneficiarios',
        active: true,
    },
];

interface PreloaProps {
    id: number;
    nome: string;
}

interface PreloaPropsVaga {
    id: number;
    codigo: string;
}

const Relatorio: React.FC = () => {
    const { addToast } = useToast();
    const [relatorios, setRelatorios] = useState<IEnvio[]>([]);
    const date = format(new Date(), "ddMMyyyyHHmm");
    // const [unidadeAux, setUnidadeAux] = useState('');
    // const [unidades, setUnidades] = useState<SelectProps[]>([]);

    const handleGetRelatorio = useCallback(
        async (event = { tipoOperacao: '', vagaId: '', profissionalId: '' }) => {
            try {
                const { tipoOperacao, vagaId, profissionalId } = event;

                const { data } = await api.get('/api/v1/painel/historico', {
                    params: {
                        tipoOperacao,
                        vagaId,
                        profissionalId,
                    }
                });
                console.log(data);
                setRelatorios(data.data.map((item: any) => {
                    return {
                        ...item
                        , dataEnvio: format(
                            parseISO(item.dataEnvio),
                            'dd/MM/yyyy HH:mm',
                        )
                    }
                }));

                console.log(relatorios);
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar o histórico',
                });
            }
        },
        [addToast],
    );

    // const handleGetResponsaveis = useCallback(
    //     async (inputValue: string) => {
    //         try {
    //             const { data } = await api.get(
    //                 `/api/v1/painel/cliente/responsaveis?nome=${inputValue}`,
    //             );
    //             const dados = data.data;

    //             return dados.responsaveis.map((item: PreloaProps) => ({
    //                 label: item.nome,
    //                 value: item.id,
    //             }));
    //         } catch {
    //             addToast({
    //                 type: 'error',
    //                 title: 'Erro ao buscar responsáveis',
    //             });
    //         }
    //     },
    //     [addToast],
    // );

    const handleGetVagas = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/vaga?codigo=${inputValue}`,
                );
                const dados = data.data;
                console.log(dados);
                return dados.vagas.map((item: PreloaPropsVaga) => ({
                    label: item.codigo,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar vagas',
                });
            }
        },
        [addToast],
    );

    // const handleGetUnidades = useCallback(async () => {
    //     try {
    //         const { data } = await api.get(`api/v1/painel/unidade/usuario`);
    //         setUnidades(
    //             data.data.map((item: PreloaProps) => ({
    //                 label: item.nome,
    //                 value: item.id,
    //             })),
    //         );
    //     } catch {
    //         addToast({
    //             type: 'error',
    //             title: 'Ooops! Algo deu errado!',
    //             description: 'Erro ao buscar profissões',
    //         });
    //     }
    // }, [addToast]);

    const handleGetProfissionais = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/profissional?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.profissionais.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar profissinal',
                });
            }
        },
        [addToast],
    );

    // useEffect(() => {
    //     //handleGetUnidades();
    //     handleGetRelatorio();
    // }, [handleGetRelatorio]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col>
                                <h3>Relatório de envios</h3>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetRelatorio(e)
                            }
                        >
                            <Row>
                                <Col md={4}>
                                    <Select
                                        name="tipoOperacao"
                                        type="text"
                                        placeholder="Operação"
                                        options={[
                                            { label: 'Divulgação de vaga', value: 1 },
                                            { label: 'Selecionado para a vaga', value: 2 },
                                            { label: 'Não selecionado para a vaga', value: 3 },
                                            { label: 'Notificar responsável', value: 4 },
                                        ]}
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="vagaId"
                                        type="text"
                                        placeholder="Vaga"
                                        loadOptions={handleGetVagas}
                                        defaultOptions
                                        route="/api/v1/painel/vaga"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Vagas'
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="profissionalId"
                                        type="text"
                                        placeholder="Profissional"
                                        loadOptions={handleGetProfissionais}
                                        defaultOptions
                                        route="/api/v1/painel/profissional"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando profissional'
                                        }
                                    />
                                </Col>

                                {/* <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="responsavelId"
                                        type="text"
                                        placeholder="Responsável"
                                        loadOptions={handleGetResponsaveis}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/responsavel"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Responsável'
                                        }
                                    />
                                </Col> */}
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                        {relatorios && (
                            <>
                                {/* <PDFDownloadLink
                                    document={<Pdf relatorios={relatorios} />}
                                    fileName={`relatorio-comissao-${date}.pdf`}
                                    className="btn btn-danger"
                                >
                                    {({ loading }) =>
                                        loading
                                            ? 'Carregando...'
                                            : <span className="d-flex align-items-center">
                                                <AiOutlineFilePdf className="mr-2" />PDF
                                            </span>
                                    }
                                </PDFDownloadLink> */}

                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success ml-2"
                                    table="table-to-comissao"
                                    filename={`relatorio-comissao-${date}`}
                                    sheet="tablexls"
                                    buttonText={
                                        <span className="d-flex align-items-center">
                                            <RiFileExcel2Line className="mr-2" />Excel
                                        </span>
                                    }
                                />
                            </>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {relatorios.length > 0 && (
                        <Table responsive id="table-to-comissao">
                            <thead>
                                <tr>
                                    <th>Vaga</th>
                                    <th>Operação</th>
                                    <th>Enviado para</th>
                                    <th>Status envio</th>
                                    <th>Data envio</th>
                                    <th>Erro</th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatorios.map((relatorio) => (
                                    <tr>
                                        <td>{relatorio.vagaCodigo}</td>
                                        <td>{relatorio.descricaoTipoOperacao}</td>
                                        <td>{relatorio.tipoOperacao == 4 ? relatorio.responsavelNome : relatorio.profissionalNome}</td>
                                        <td>{relatorio.status == true ? "Enviado" : "Não enviado"}</td>
                                        <td>{relatorio.dataEnvio}</td>
                                        <td>{relatorio.erro == null || relatorio.erro == "" ? "--" : relatorio.erro}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Relatorio;
