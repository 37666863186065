import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global';
import Routes from './routes';
import AppProvider from './hooks';
import CheckAnnouncement from './components/CheckAnnouncement/index';

const App: React.FC = () => (
    <Router>
        <AppProvider>
            <CheckAnnouncement>
                <Routes />
            </CheckAnnouncement>
        </AppProvider>
        <GlobalStyle />
    </Router>
);

export default App;
