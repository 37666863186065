import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../styles/variables';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
    isDisabled: boolean;
}

export const Container = styled.div`
    margin-top: 8px;
    label {
        font-size: 13px;
    }
    color: #222222;
`;

export const InputContainer = styled.div<ContainerProps>`
    background: #fff;
    padding: 8px;
    padding-right: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    position: relative;

    & + & {
        margin-top: 8px;
    }

    svg {
        margin-right: 6px;
        color: ${colors.dark};
    }

    ${(props) =>
        props.isErrored &&
        css`
            border-color: ${colors.danger};
            box-shadow: 0 0 0 3px ${transparentize(0.7, colors.danger)};
        `}
    ${(props) =>
        props.isFocused &&
        css`
            svg {
                color: ${colors.primary};
            }
            border-color: ${colors.primary};
            box-shadow: 0 0 0 2px ${transparentize(0.7, colors.primary)};
        `}
    ${(props) =>
        props.isFilled &&
        css`
            svg {
                color: ${colors.primary};
            }
        `}

        ${(props) =>
            props.isDisabled &&
            css`
                border-color: #f3f3f3 !important;
                background: #f3f3f3 !important;
            `}


    input {
        flex: 1;
        border: 0;
        background: transparent;
        color: ${colors.dark};

        &::placeholder {
            color: ${transparentize(0.4, colors.dark)};
        }
    }
`;

export const Error = styled.div`
    flex-wrap: wrap;

    span {
        color: #c53030;
        font-size: 13px;
    }

    svg {
        position: absolute;
        right: 20px;
        top: 51px;
    }
`;
