import React from 'react';

import { Switch } from 'react-router-dom';
import Route from './Route';

import DefaultLayout from '../layouts/default';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';

import Funcionario from '../pages/Funcionario';
import NewFuncionario from '../pages/Funcionario/NewFuncionario';

import Vagas from '../pages/Vagas';
import FormVaga from '../pages/Vagas/FormVaga';

import Faturas from '../pages/Faturas';
import FaturasShow from '../pages/Faturas/Show';

import FormResponsavel from '../pages/Cliente/Responsaveis/FormResponsavel';
import Responsaveis from '../pages/Cliente/Responsaveis';

import Benecifiarios from '../pages/Cliente/Beneficiarios';
import FormBeneficiario from '../pages/Cliente/Beneficiarios/FormBeneficiario';

import RelatorioMensalBeneficiarios from '../pages/Relatorio/Beneficiarios';
import RelatorioComissao from '../pages/Relatorio/Comissao';
import RelatorioTaxas from '../pages/Relatorio/Taxas';
import RelatorioEnvios from '../pages/Relatorio/Envios';

import Profissao from '../pages/Profissao';
import FormProfissao from '../pages/Profissao/FormProfissao';
import Profissional from '../pages/Profissional';
import FormProfissional from '../pages/Profissional/FormProfissional';

import CadastroSemLogin from '../pages/Cliente/CadastroSemLogin';
import Agradecimento from '../pages/Cliente/CadastroSemLogin/Agradecimento';

import Unidade from '../pages/Unidade';
import CreateUnidade from '../pages/Unidade/CreateUnidade';
import EditFuncionario from '../pages/Funcionario/EditFuncionario';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import NfeProfissional from '../pages/Relatorio/nfe/Profissional';
import NfeResponsaveis from '../pages/Relatorio/nfe/Responsavel';
import ProfissionalPosCadastro from '../pages/ProfissionalPosCadastro';
import FormProfissionalPosCadastro from '../pages/ProfissionalPosCadastro/FormProfissionalPosCadastro';
import Roles from '../pages/Roles';
import GrupoAcesso from '../pages/GrupoAcesso';
import Banner from '../pages/Banner';
import CreateBanner from '../pages/Banner/CreateBanner';
import { useAuth, Funcoes, Permissoes } from '../hooks/auth';
import Forbidden from '../components/Forbidden';
import Comunicados from '../pages/Comunicados';

const Routes: React.FC = () => {

    const { user } = useAuth()

    const userHasPermission = (userFunction: string, userPermission?: string) => {
        if (user?.tipo === 'Administrador') return true
        if (userFunction === 'admin') return false

        return user?.funcoes.some(funcao => {
            if (funcao.nome === userFunction) {
                return funcao.permissoes.some(permissao => permissao === userPermission)
            }

        })
    }

    return (
        <Switch>
            <Route path="/" exact component={SignIn} />

            <Route path="/recuperar-senha" exact component={ForgotPassword} />

            <Route
                path="/reset-senha/:code/:email"
                exact
                component={ResetPassword}
            />

            <Route
                path="/unidades/:id/edit"
                exact
                component={userHasPermission(Funcoes.Unidade, Permissoes.Alterar) ? CreateUnidade : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            {/* verificar */}
            <Route
                path="/cadastro/responsavel"
                exact
                component={CadastroSemLogin}
            />
            <Route path="/agradecimento" exact component={Agradecimento} />

            <Route
                path="/dashboard"
                exact
                component={Dashboard}
                isPrivate
                layout={DefaultLayout}
            />
            {/* <Route
                path="/products"
                exact
                component={userHasPermission('admin') ? Products : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/products/new"
                exact
                component={userHasPermission('admin') ? NewProduct : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/products/:id/edit"
                exact
                component={userHasPermission('admin') ? EditProduct : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}
            {/* <Route
                path="/sales"
                exact
                component={userHasPermission('admin') ? Sales : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/sales/:id/edit"
                exact
                component={userHasPermission('admin') ? EditSale : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}
            {/* <Route
                path="/stores"
                exact
                component={userHasPermission('admin') ? Store : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/stores/new"
                exact
                component={userHasPermission('admin') ? EditStore : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/stores/:id/edit"
                exact
                component={userHasPermission('admin') ? EditStore : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}
            {/* <Route
                path="/categories"
                exact
                component={userHasPermission('admin') ? Category : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/categories/new"
                exact
                component={userHasPermission('admin') ? NewCategory : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/categories/:id/edit"
                exact
                component={userHasPermission('admin') ? EditCategory : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}

            <Route
                path="/funcionarios"
                exact
                component={userHasPermission(Funcoes.Funcionario, Permissoes.Acessar) ? Funcionario : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/funcionarios/new"
                exact
                component={userHasPermission(Funcoes.Funcionario, Permissoes.Incluir) ? NewFuncionario : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/funcionarios/:id/edit"
                exact
                component={userHasPermission(Funcoes.Funcionario, Permissoes.Alterar) ? EditFuncionario : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            {/* <Route
                path="/clientes"
                exact
                component={userHasPermission(Funcoes.all, Permissoes.Consultar) ? Cliente : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/new"
                exact
                component={userHasPermission(Funcoes.all, Permissoes.Incluir) ? NewCliente : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/:id/edit"
                exact
                component={userHasPermission(Funcoes.all, Permissoes.Alterar) ? EditCliente : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}

            <Route
                path="/profissionais"
                exact
                component={userHasPermission(Funcoes.Profissional, Permissoes.Acessar) ? Profissional : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/profissionais/new"
                exact
                component={userHasPermission(Funcoes.Profissional, Permissoes.Incluir) ? FormProfissional : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/profissionais/:id/edit"
                exact
                component={userHasPermission(Funcoes.Profissional, Permissoes.Alterar) ? FormProfissional : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/precadastro" //antigo pos cadastro q virou pré
                exact
                component={userHasPermission(Funcoes.PreCadastro, Permissoes.Acessar) ? ProfissionalPosCadastro : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/precadastro/:id" //antigo pos cadastro q virou pré
                exact
                component={userHasPermission(Funcoes.PreCadastro, Permissoes.Alterar) ? FormProfissionalPosCadastro : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/precadastro/:id/edit" //antigo pos cadastro q virou pré
                exact
                component={userHasPermission(Funcoes.PreCadastro, Permissoes.Alterar) ? FormProfissional : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            {/* <Route
                path="/precadastro/:id/detail"
                exact
                component={userHasPermission(Funcoes.PreCadastro, Permissoes.Alterar) ? FormPreCastro : Forbidden}
                isPrivate
                layout={DefaultLayout}
            /> */}

            <Route
                path="/profissoes"
                exact
                component={userHasPermission(Funcoes.Profissao, Permissoes.Acessar) ? Profissao : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/profissoes/new"
                exact
                component={userHasPermission(Funcoes.Profissao, Permissoes.Incluir) ? FormProfissao : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/profissoes/:id/edit"
                exact
                component={userHasPermission(Funcoes.Profissao, Permissoes.Alterar) ? FormProfissao : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/vagas"
                exact
                component={userHasPermission(Funcoes.Vaga, Permissoes.Acessar) ? Vagas : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/vagas/new"
                exact
                component={userHasPermission(Funcoes.Vaga, Permissoes.Incluir) ? FormVaga : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/vagas/:id/edit"
                exact
                component={userHasPermission(Funcoes.Vaga, Permissoes.Alterar) ? FormVaga : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/Faturas"
                exact
                component={userHasPermission(Funcoes.Fatura, Permissoes.Acessar) ? Faturas : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/Faturas/:id/show"
                exact
                component={userHasPermission(Funcoes.Fatura, Permissoes.Visualizar) ? FaturasShow : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/beneficiarios"
                exact
                component={userHasPermission(Funcoes.Beneficiario, Permissoes.Acessar) ? Benecifiarios : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/beneficiarios"
                exact
                component={userHasPermission(Funcoes.RelatorioBeneficiarios, Permissoes.Acessar) ? RelatorioMensalBeneficiarios : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/comissao"
                exact
                component={userHasPermission(Funcoes.RelatorioTaxaAgenciamento, Permissoes.Acessar) ? RelatorioComissao : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/taxas"
                exact
                component={userHasPermission(Funcoes.RelatorioTaxaEmpresa, Permissoes.Acessar) ? RelatorioTaxas : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/envios"
                exact
                component={userHasPermission(Funcoes.RelatorioEnviosWhatsapp, Permissoes.Acessar) ? RelatorioEnvios : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/beneficiarios/new"
                exact
                component={userHasPermission(Funcoes.Beneficiario, Permissoes.Incluir) ? FormBeneficiario : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/beneficiarios/:id/edit"
                exact
                component={userHasPermission(Funcoes.Beneficiario, Permissoes.Alterar) ? FormBeneficiario : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/responsaveis"
                exact
                component={userHasPermission(Funcoes.Responsavel, Permissoes.Acessar) ? Responsaveis : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/responsaveis/new"
                exact
                component={userHasPermission(Funcoes.Responsavel, Permissoes.Incluir) ? FormResponsavel : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/clientes/responsaveis/:id/edit"
                exact
                component={userHasPermission(Funcoes.Responsavel, Permissoes.Alterar) ? FormResponsavel : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/unidades"
                exact
                component={userHasPermission(Funcoes.Unidade, Permissoes.Acessar) ? Unidade : Forbidden}

                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/unidades/new"
                exact
                component={userHasPermission(Funcoes.Unidade, Permissoes.Incluir) ? CreateUnidade : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/nfe/profissionais"
                exact
                component={userHasPermission(Funcoes.RelatorioNfeProfissional, Permissoes.Acessar) ? NfeProfissional : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            <Route
                path="/relatorios/nfe/responsaveis"
                exact
                component={userHasPermission(Funcoes.RelatorioNfeResponsavel, Permissoes.Acessar) ? NfeResponsaveis : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />

            {/* <Route
            path="/precadastro"
            exact
            component={PreCadastro}
            isPrivate
            layout={DefaultLayout}
        /> */}



            <Route
                path="/grupo-acesso"
                exact
                component={userHasPermission('admin') ? GrupoAcesso : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/banner"
                exact
                component={userHasPermission('admin') ? Banner : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/banner/new"
                exact
                component={userHasPermission('admin') ? CreateBanner : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/banner/:id/edit"
                exact
                component={userHasPermission('admin') ? CreateBanner : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/permissoes/:groupId"
                exact
                component={userHasPermission('admin') ? Roles : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
            <Route
                path="/comunicados/:id?"
                exact
                component={userHasPermission('admin') ? Comunicados : Forbidden}
                isPrivate
                layout={DefaultLayout}
            />
        </Switch>
    )
};

export default Routes;
