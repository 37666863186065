import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';
import BSContainer from 'react-bootstrap/Container';
import BSCard from 'react-bootstrap/Card';
import { colors } from '../../../styles/variables';

interface ItemListProps {
    paid: boolean;
}

export const Container = styled(BSContainer)`
    margin-top: 24px;
    margin-bottom: 500px;
    hr {
        background: #333333;
    }

    ul {
        list-style: none;
        flex-wrap: wrap;
    }

    table {
        button {
            border: none;
            background: #e57373;
            padding: 1px 3px;
            font-weight: bold;
            font-size: 8px;
            border-radius: 50%;
            color: #fff;
        }
    }

    .DayPicker-Day--selected {
        background: ${colors.primary} !important;
    }
`;

export const ItemTable = styled.tr<ItemListProps>`
    span {
        ${({ paid }) =>
            paid &&
            css`
                padding: 3px 6px;
                border-radius: 20px;
                background: #66bb6a;
                color: #fff;
            `}
    }
`;

export const Card = styled(BSCard)`
    box-shadow: 2px 2px 5px #00000082;
    padding: 8px;
    border: none;
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Form = styled(Unform)`
    div[class*='col'] {
        margin-bottom: 8px;
        margin-top: 8px;
    }
    input {
        border: #dfdfdf;
    }

    .eecmFB {
        margin-top: 7px !important;
    }

    button:disabled {
        background: #ddd;
        cursor: not-allowed;
        color: #fff;
    }
`;
