import React, { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';

import { colors } from '../../styles/variables';

import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface UnidadeProps {
    id: number;
    cnpj: string;
    razaoSocial: string;
    nome: string;
    email: string;
    endereco: string;
    isAtivo: boolean;
}

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/unidades',
        active: true,
    },
];

const Unidade: React.FC = () => {
    const [unidades, setUnidades] = useState<UnidadeProps[]>(
        [] as UnidadeProps[],
    );

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const { addToast } = useToast();
    const getUnidades = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api.get(`/api/v1/painel/unidade`);
            const { data } = response;
            console.log(data);
            setUnidades(data.data);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description:
                    'Não conseguimos exibir as telas, tente novamente!',
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    useEffect(() => {
        getUnidades();
    }, [getUnidades]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            {loading ? (
                <Loading />
            ) : (
                <Card>
                    <Card.Header>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col md={10}>
                                    <h5>Listagem de Unidades</h5>
                                </Col>
                                <Col md={2}>
                                    <Options>
                                        <OptionButton
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            onClick={() =>
                                                history.push('/unidades/new')
                                            }
                                        >
                                            <FiPlus color={colors.light} />
                                            Adicionar
                                        </OptionButton>
                                    </Options>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <List responsive>
                            <Header>
                                <tr>
                                    <th>CNPJ</th>
                                    <th>Razão Social</th>
                                    <th>Nome</th>
                                    <th>Ativo</th>
                                    <th></th>
                                </tr>
                            </Header>
                            <Content>
                                {unidades.map((unidade) => (
                                    <tr key={unidade.id}>
                                        <td>{unidade.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                        <td>{unidade.razaoSocial}</td>
                                        <td>{unidade.nome}</td>
                                        <td>{unidade.isAtivo ? "Ativo" : "Inativo"}</td>
                                        <td style={{ width: '14%' }}>
                                            <Options>
                                                <OptionButton
                                                    type="button"
                                                    color={colors.primary}
                                                    textcolor={colors.light}
                                                    onClick={() =>
                                                        history.push(
                                                            `/unidades/${unidade.id}/edit`,
                                                        )
                                                    }
                                                >
                                                    <FiEdit
                                                        color={colors.light}
                                                    />
                                                    Editar
                                                </OptionButton>
                                            </Options>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

export default Unidade;
