import styled from 'styled-components';
import { shade, lighten, transparentize } from 'polished';

interface ButtonProps {
    color: string;
    textcolor: string;
}

export const Container = styled.button<ButtonProps>`
font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: .875rem;
    letter-spacing: .0107142857em;
    font-weight: 500;
    text-transform: none;

    background: ${(props) => props.color};
    border: 1px solid #c3c3c3;

    color: ${(props) => props.textcolor};
    width: 100%;
    height:36px;
    padding: 0 24px 0 24px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    svg {
        margin-right: 5px;
    }

    &:hover {
        background: ${(props) => shade(0.2, props.color)};
        color: ${(props) => lighten(0.1, props.textcolor)};
    }

    &[disabled] {
        background: ${(props) => lighten(0.2, props.color)};
        cursor: default;
    }

    &:focus {
        background: ${(props) => shade(0.1, props.color)};
        box-shadow: 0 0 0 3px ${(props) => transparentize(0.5, props.color)};
    }

    &:active:not([disabled]) {
        background: ${(props) => shade(0.1, props.color)};
    }
`;
