import styled from 'styled-components';

import BTNavbar from 'react-bootstrap/Navbar';
import Button from '../../components/Button';

import { colors } from '../../styles/variables';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    .dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
`;

export const Content = styled.div`
    width: 100%;
    /* position: absolute;
    right: 0; */
`;

export const NavBar = styled(BTNavbar)`
    display: flex;
    padding: 16px;
    min-height: 73px;
    justify-content: space-between;
    align-items: center;
    background: ${colors.primary};
`;

export const CollapseButton = styled(Button)`
    background: 'transparent';
    width: fit-content;
`;
