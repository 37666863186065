import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../styles/variables';

export const Container = styled.div``;

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const SelectContainer = styled.div<ContainerProps>`
    margin-top: 12px;

    .react-select__control{
        min-height: 42px;
    }

     /* background: #fff;
    padding: 8px;
    margin-top:8px;

    width: 100%;
    border: 2px solid #dfdfdf;
    display: flex;
    align-items: center;
    border: 2px solid #dfdfdf;
    & + & {
        margin-top: 8px;
    }


    ${(props) =>
        props.isErrored &&
        css`
            border-color: ${colors.danger};
        `}
    ${(props) =>
        props.isFocused &&
        css`
            svg {
                color: ${colors.primary};
            }
            border-color: ${colors.primary};
        `}
    ${(props) =>
        props.isFilled &&
        css`
            svg {
                color: ${colors.primary};
            }
        `}

    .react-select__control {
        flex: 1;
        border: 0;
        width: 100%;
        background: transparent;
        color: ${colors.dark};
        &::placeholder {
            color: ${transparentize(0.4, colors.dark)};
        }
} */

`;

export const Error = styled.div`
    flex-wrap: wrap;

    span {
        color: #c53030;
        font-size: 13px;
    }

    svg {
        position: absolute;
        right: 20px;
        top: 53px;
    }
`;
