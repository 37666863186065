import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';
import PaginationComponent from '../../components/PaginationComponent';
import Modal, { ModalHandles } from './ModalConfirmDelete';
import ReactSelectInput from '../../components/ReactSelect';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select, { SelectProps } from '../../components/SelectInput';
import { CheckBox } from '../../styles/components';

import { colors } from '../../styles/variables';

import {
    Container,
    Card,
    List,
    Header,
    Options,
    Content,
    OptionButton,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import PaginationProps from '../../interfaces/PaginationProps';
import ICliente from '../../interfaces/IClienteProps';
import { Item } from '../../layouts/default/Menu/styles';
import { FaExternalLinkAlt } from 'react-icons/fa';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/profissionais',
        active: true,
    },
];

const Profissional: React.FC = () => {
    const [profissionais, setClientes] = useState<any[]>([] as ICliente[]);
    const [profissional, setProfissional] = useState<ICliente>();
    const [paginationData, setPaginationData] = useState<PaginationProps>();
    const [profissoes, setprofissoes] = useState<SelectProps[]>([]);
    const [estados, setEstados] = useState<SelectProps[]>([]);
    const [cidades, setCidades] = useState<SelectProps[]>([]);
    const [bairros, setBairros] = useState<SelectProps[]>([]);
    const [aptidoes, setAptidoes] = useState<SelectProps[]>([]);
    const [sexo, setSexo] = useState<SelectProps[]>([]);
    const [loading, setLoading] = useState(false);
    const modalRef = useRef<ModalHandles>(null);
    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToast();
    const [unidades, setUnidades] = useState<SelectProps[]>([]);

    const [cpfAux, setCpfAux] = useState('');
    const [nomeAux, setNomeAux] = useState('');
    const [ufAux, setUfAux] = useState<string>();
    const [profissaoAux, setProfissaoAux] = useState('');
    const [emailAux, setEmailAux] = useState('');
    const [cidadeAux, setCidadeAux] = useState<string>();
    const [bairroAux, setBairroAux] = useState<string>();
    const [ativoAux, setAtivoAux] = useState('');
    const [aptidaoAux, setAptidaoAux] = useState('');
    const [unidadeAux, setUnidadeAux] = useState('');
    const [sexoAux, setSexoAux] = useState('');

    const [profissionaisIds, setProfissionaisIds] = useState<number[]>([]);
    const [sendVaga, setSendVaga] = useState(false);

    const [data, setData] = useState<any>();

    const handleEditarNovaAba = (profissionalId: number) => {
        const win = window.open(`/profissionais/${profissionalId}/edit`, '_blank');
        win?.focus();
    }

    useEffect(() => {
        api
            .get(`api/v1/painel/regiao/estados`)
            .then(({ data }) => {
                setEstados(
                    data.data.map((item: any) => ({
                        label: item,
                        value: item,
                    })),
                );
            })
            .catch(err => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description:
                        'Erro ao buscar estados',
                });
            });
    }, [addToast]);

    const handleOpenModal = useCallback((data: ICliente) => {
        setProfissional(data);
        modalRef.current?.handleShowModal();
    }, []);

    const getClientes = useCallback(
        async (
            page = 1,
            data = {
                cpf: cpfAux,
                nome: nomeAux,
                profissao: profissaoAux,
                email: emailAux,
                uf: ufAux,
                cidade: cidadeAux,
                bairro: bairroAux,
                ativo: ativoAux,
                aptidao: aptidaoAux,
                unidade: unidadeAux,
                sexo: sexoAux,
            },
        ) => {
            console.log(data, 'data');
            console.log(nomeAux, 'nomeAux');
            try {
                const {
                    cpf,
                    nome,
                    profissao,
                    email,
                    uf,
                    cidade,
                    bairro,
                    ativo,
                    unidade,
                    aptidao,
                    sexo
                } = data;
                setLoading(true);

                setCpfAux(cpf);
                setNomeAux(nome);
                setProfissaoAux(profissao);
                setEmailAux(email);
                setUfAux(uf);
                setCidadeAux(cidade);
                setBairroAux(bairro);
                setAtivoAux(ativo);
                setAptidaoAux(aptidao);
                setUnidadeAux(unidade);
                setSexoAux(sexo);

                const aptidaoToString = Array(aptidao).toString();

                await api.get('/api/v1/painel/profissional', {
                    params: {
                        pagina: page,
                        cpf,
                        nome,
                        profissao,
                        email,
                        uf,
                        cidade,
                        bairro,
                        ativo,
                        aptidao: aptidaoToString,
                        unidade,
                        sexo
                    }
                }).then(res => {
                    const dados = res.data.data;

                    setPaginationData({
                        page: dados.paginaAtual,
                        perPage: dados.quantidadeTotalPagina,
                        total: dados.quantidadeTotal,
                        lastPage:
                            dados.quantidadeTotal / dados.quantidadeTotalPagina,
                    });

                    setClientes(dados.profissionais);
                })


            } catch (error) {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description:
                        'Não conseguimos exibir as telas, tente novamente!',
                });
            } finally {
                setLoading(false);
            }
        },
        [addToast, cpfAux, nomeAux, profissaoAux, emailAux, ufAux, cidadeAux, bairroAux, ativoAux, aptidaoAux, unidadeAux, sexoAux]
    );

    const handleGetUnidades = useCallback(async () => {
        try {
            const { data } = await api.get(`api/v1/painel/unidade/usuario`);
            setUnidades(
                data.data.map((item: any) => ({
                    label: item.nome,
                    value: item.nome,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    useEffect(() => {
        handleGetUnidades();
        getClientes();
    }, [handleGetUnidades, getClientes]);

    useEffect(() => {
        async function getPreLoad() {
            const { data } = await api.get('/api/v1/painel/profissional/preload');
            const dados = data.data;
            setprofissoes(
                dados.profissoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
            setAptidoes(
                dados.aptidoes.map((item: any) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );

            let sexo = dados.sexo.map((item: any) => ({
                label: item.nome,
                value: item.id,
            }));
            sexo.unshift({
                label: "Todos",
                value: null,
            });

            setSexo(sexo);
        }
        getPreLoad();
    }, []);

    const handleDeleteProfissional = useCallback(async (idProfissional, observacao) => {
        try {
            await api.delete(`/api/v1/painel/profissional/${idProfissional}`, {
                params: {
                    observacao,
                },
            })

            getClientes();
            // setClientes(old => old.map((item: any) => {
            //     return {
            //         ...item,
            //         isAtivo: Item.id === idProfissional ? !item.isAtivo : item.isAtivo
            //     }
            // }));
            //setClientes(old => [...old.filter(item => item.id !== idProfissional)]);
            addToast({
                type: 'success',
                title: 'Ação realizada com sucesso',
            });
        } catch {
            addToast({
                type: 'error',
                title: 'Erro ao ativar/desativar profissional',
            });
        }
    }, [addToast]);

    const handleGetCidades = async (event: SelectProps) => {
        try {
            const uf = event.value.toString();

            api.get(`api/v1/painel/regiao/estados/cidades`, {
                params: {
                    uf
                }
            })
                .then(response => {
                    setUfAux(uf);
                    setCidades(
                        response.data.data.map((item: any) => ({
                            label: item,
                            value: item,
                        })),
                    );
                });
        } catch {
            console.log('erro')
        }
    }

    const handleGetBairros = async (event: SelectProps) => {
        try {
            const cidade = event.value.toString();

            api.get(`api/v1/painel/regiao/estados/cidades/bairros`, {
                params: {
                    uf: ufAux,
                    cidade
                }
            })
                .then(response => {
                    setBairroAux('');
                    setBairros(
                        response.data.data.map((item: any) => ({
                            label: item,
                            value: item,
                        })),
                    );
                });
        } catch {
            console.log('erro')
        }
    }

    const handleSetDivulgarVaga = useCallback(
        (e: number) => {
            const findProfissional = profissionaisIds.findIndex((prof) => prof === e);
            if (findProfissional < 0) {
                setProfissionaisIds((old) => [...old, e]);
            } else {
                setProfissionaisIds((old) => old.filter((prof) => prof !== e));
            }
        },
        [profissionaisIds],
    );

    const handleOpenDivulgarVaga = useCallback(async () => {

        console.log(profissionaisIds, 'profissionaisIds');
        if (profissionaisIds.length === 0) {
            addToast({
                type: 'error',
                title: 'Selecione pelo menos um profissional!',
            });
            return;
        }

        setSendVaga(true);
    }, [profissionaisIds, addToast]);


    const handleSendDivulgarVaga = useCallback(async ({ codigoVaga }) => {
        try {
            console.log(codigoVaga, `profissionaisIds`);
            if (profissionaisIds.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Selecione pelo menos um profissional!',
                });
                return;
            }
            if (codigoVaga.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Digite o código da vaga!',
                });
                return;
            }

            await api.post(`/api/v1/painel/profissional/enviar-vaga`, {
                CodigoVaga: codigoVaga,
                ProfissionalIds: profissionaisIds,
            });


            addToast({
                type: 'success',
                title: 'Vaga divulgada com sucesso!',
            });
            setSendVaga(false);
            getClientes();
        } catch (err) {

            const { errors } = err.response.data;

            errors.forEach((err: string) => {
                addToast({
                    type: 'error',
                    title: 'Ooops! Algo deu errado!',
                    description: err,
                });
            });


        }
    }, [addToast, profissionaisIds, getClientes]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            {profissional && (
                <Modal
                    ref={modalRef}
                    onDeleteProfissional={handleDeleteProfissional}
                    profissional={profissional}
                />
            )}

            <Card>
                <Card.Header>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <Row>
                            <Col md={10}>
                                <h5>Listagem de Profissionais</h5>
                            </Col>
                            <Col md={2}>
                                <Options>
                                    <OptionButton
                                        type="button"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        onClick={() =>
                                            history.push(
                                                '/profissionais/new',
                                            )
                                        }
                                    >
                                        <FiPlus color={colors.light} />
                                        Adicionar
                                    </OptionButton>
                                </Options>
                            </Col>
                        </Row>

                        <Form onSubmit={(e: React.FormEvent) => getClientes(1, e)}>
                            <Row>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="cpf"
                                        type="text"
                                        placeholder="CPF"
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        hideLabel
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="profissao"
                                        placeholder="Profissão"
                                        options={profissoes}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti={false}
                                        hideLabel
                                        name="unidade"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti
                                        hideLabel
                                        name="aptidao"
                                        placeholder="Aptidão"
                                        options={aptidoes}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        name="uf"
                                        hideLabel
                                        placeholder="Estado"
                                        handleChange={handleGetCidades}
                                        options={estados}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="cidade"
                                        placeholder="Cidade"
                                        handleChange={handleGetBairros}
                                        options={cidades}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        hideLabel
                                        name="bairro"
                                        placeholder="Bairro"
                                        options={bairros}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select
                                        defaultOpt="Selecione o status"
                                        name="ativo"
                                        type="text"
                                        placeholder=""
                                        options={[
                                            { label: 'Ativo', value: true },
                                            {
                                                label: 'Inativo',
                                                value: false,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select
                                        defaultOpt="Selecione o sexo"
                                        name="sexo"
                                        type="text"
                                        placeholder=""
                                        options={sexo}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.light}
                                        textcolor={colors.dark}
                                        style={{

                                            marginTop: '10px',
                                        }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                {sendVaga ? (
                                    <Col md={5}>
                                        <Form
                                            onSubmit={handleSendDivulgarVaga}
                                        >
                                            <Row>
                                                <Col md={8}>
                                                    <Input
                                                        name="codigoVaga"
                                                        type="text"
                                                        placeholder="Digite o código da vaga"
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        type="submit"
                                                        color={colors.primary}
                                                        textcolor={colors.light}
                                                        style={{ marginTop: '38px' }}
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                ) : (
                                    <Col md={2}>
                                        <Button
                                            type="button"
                                            color={colors.primary}
                                            textcolor={colors.light}
                                            style={{ marginTop: '38px' }}
                                            onClick={handleOpenDivulgarVaga}
                                        >
                                            Divulgar Vaga
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </div>
                </Card.Header>
                <Card.Body>
                    {profissionais.length > 0 ? (
                        <List responsive>
                            <Header>
                                <tr>
                                    <th>CPF</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th>Opções</th>
                                </tr>
                            </Header>
                            <Content>
                                {profissionais.map((profissional) => (
                                    <tr key={profissional.id}>
                                        <td>
                                            <CheckBox>
                                                <input
                                                    type="checkbox"
                                                    name={profissional.id}
                                                    checked={
                                                        !!profissionaisIds.find(
                                                            (fatura) =>
                                                                fatura ===
                                                                profissional.id,
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleSetDivulgarVaga(
                                                            profissional.id,
                                                        )
                                                    }
                                                />
                                                <span className="checkmark" />
                                            </CheckBox>
                                            {profissional.cpf}</td>
                                        <td>{profissional.nome}</td>
                                        <td>{profissional.isAtivo ? 'Ativo' : 'Inativo'}</td>
                                        <td style={{ width: '20%' }} >
                                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        title="Editar em nova aba"
                                                        type="button"
                                                        color={colors.secondary}
                                                        textcolor={colors.dark}
                                                        onClick={() => handleEditarNovaAba(profissional.id)}
                                                    >
                                                        <FaExternalLinkAlt
                                                            color={colors.dark}
                                                        />
                                                        Editar
                                                    </Button>
                                                </div>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>
                                                    <Button
                                                        title="Editar na aba atual"
                                                        type="button"
                                                        color={colors.primary}
                                                        textcolor={colors.light}
                                                        onClick={() =>
                                                            history.push(
                                                                `/profissionais/${profissional.id}/edit`,
                                                            )
                                                        }
                                                    >
                                                        <FiEdit
                                                            color={colors.light}
                                                        />
                                                        Editar
                                                    </Button>
                                                </div>
                                                <div className="col-md-4" style={{ padding: 0, margin: 0 }}>

                                                    {profissional.isAtivo ? (

                                                        <Button
                                                            type="button"
                                                            color={colors.danger}
                                                            textcolor={colors.light}
                                                            onClick={() => handleOpenModal(profissional)}
                                                        >
                                                            <FiTrash
                                                                color={colors.light}
                                                            />
                                                            Desativar

                                                        </Button>
                                                    ) : (

                                                        <Button
                                                            type="button"
                                                            color={colors.light}
                                                            textcolor={colors.dark}
                                                            onClick={() => handleOpenModal(profissional)}
                                                        >
                                                            <FiEdit
                                                                color={colors.dark}
                                                            />
                                                            Ativar

                                                        </Button>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Content>
                        </List>
                    ) : (
                        <h5 className="text-center">Nada encontrado</h5>
                    )}
                </Card.Body>
                <div className="d-flex justify-content-center">

                    {paginationData && (
                        <PaginationComponent
                            currentPage={paginationData.page}
                            totalPages={paginationData.perPage}
                            perPage={10}
                            onChangePage={getClientes}
                        />
                    )}
                </div>
            </Card>

        </Container>
    );
};

export default Profissional;
