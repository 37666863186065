import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const RoleHead = styled.div``;

export const RoleName = styled.span`
    font-weight: 800;
`;

export const RoleContent = styled.div`
  display: flex;
`;
