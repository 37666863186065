import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { MdLink } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import formatValue from '../../../helpers/formatValue';
import IFatura from '../../../interfaces/IFatura';
import IAgenda from '../../../interfaces/IAgenda';
import Breadcrumb from '../../../components/Breadcrumb';
import { Container, Card } from '../../../styles/components';
import api from '../../../services/api';
import { Box } from './styles';

const Show: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [fatura, setFatura] = useState<IFatura>();

    const handleGetFatura = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await api.get(`/api/v1/painel/faturamento/${id}`);
            const dados = data.data;
            console.log(dados);
            const faturaFormatted = {
                ...dados,
                responsavel: {
                    ...dados.responsavel,
                    nascimentoFormatted: format(
                        parseISO(dados.responsavel.dataNascimento),
                        'dd/MM/yyyy',
                    ),
                },
                agenda: dados.agenda.map((ag: IAgenda) => ({
                    ...ag,
                    valorTotalFormatted: formatValue(ag.valorTotal),
                    valorFormatted: formatValue(ag.valor),
                    valorEmpresaFormatted: formatValue(ag.valorEmpresa),
                    dias: ag.dias.map((dia) => ({
                        ...dia,
                        dataFormatted: format(parseISO(dia.data), 'dd/MM/yyyy'),
                    })),
                })),
            };
            setFatura(faturaFormatted);
            console.log(faturaFormatted);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            handleGetFatura();
        }
    }, [handleGetFatura, id]);

    return (
        <Container fluid>
            <Breadcrumb
                itens={[
                    {
                        title: 'Faturas',
                        link: '/faturas',
                        active: false,
                    },
                    {
                        title: 'Visualizar fatura',
                        link: `/faturas/${id}/show`,
                        active: true,
                    },
                ]}
            />
            {!loading && (
                <Card>
                    <Card.Header>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col md={10}>
                                    <h5>Fatura: {fatura?.codigo}</h5>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Box>
                                    {fatura?.url && (
                                        <Col md={2} xs={3} className="mb-3">
                                            <a
                                                className="btn btn-primary d-flex align-items-center justify-content-center"
                                                href={fatura.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <MdLink size={20} className="mr-1" />
                                                Link
                                            </a>
                                        </Col>
                                    )}
                                    <Row>
                                        <Col md={6}>
                                            <p><strong>Status: </strong>{fatura?.status}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p><strong>Criada por: </strong>{fatura?.funcionario?.nome}</p>
                                        </Col>
                                    </Row>
                                    <h6>Responsável</h6>
                                    <Row>
                                        <Col md={6}>
                                            <p>
                                                <strong>Nome:</strong>
                                                {fatura?.responsavel?.nome}
                                            </p>
                                            <p>
                                                <strong>Celular:</strong>
                                                {fatura?.responsavel?.celular}
                                            </p>
                                            <p>
                                                <strong>RG:</strong>
                                                {fatura?.responsavel?.rg}
                                            </p>
                                            <p>
                                                <strong>CPF:</strong>
                                                {fatura?.responsavel?.cpf}
                                            </p>

                                            <p>
                                                <strong>Cidade:</strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.endereco?.localidade
                                                }
                                            </p>
                                            <p>
                                                <strong>Logradouro:</strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.endereco?.logradouro
                                                }
                                            </p>
                                            <p>
                                                <strong>Complmento:</strong>
                                                {fatura?.responsavel?.endereco
                                                    ?.complemento ||
                                                    'Não informado'}
                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <p>
                                                <strong>
                                                    Data de nascimento:
                                                </strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.nascimentoFormatted
                                                }
                                            </p>
                                            <p>
                                                <strong>Email:</strong>
                                                {fatura?.responsavel?.email}
                                            </p>
                                            <p>
                                                <strong>Emissor:</strong>
                                                {fatura?.responsavel?.rgEmissor}
                                            </p>

                                            <p>
                                                <strong>CEP:</strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.endereco?.cep
                                                }
                                            </p>

                                            <p>
                                                <strong>UF:</strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.endereco?.uf
                                                }
                                            </p>
                                            <p>
                                                <strong>Número:</strong>
                                                {
                                                    fatura?.responsavel
                                                        ?.endereco?.numero
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                </Box>
                                <Box>
                                    <h6>Agenda</h6>

                                    {fatura?.agenda?.map((agenda) => (
                                        <>
                                            <Row key={agenda.id}>
                                                <Col md={6}>
                                                    <p>
                                                        <strong>
                                                            Profissional:
                                                        </strong>
                                                        {
                                                            agenda.profissional
                                                                .nome
                                                        }
                                                    </p>
                                                    <p>
                                                        <strong>CPF:</strong>
                                                        {
                                                            agenda.profissional
                                                                .cpf
                                                        }
                                                    </p>

                                                    <p>
                                                        <strong>
                                                            Qtd. de horas:
                                                        </strong>
                                                        {agenda.qtdHoras}
                                                    </p>
                                                </Col>
                                                <Col md={6}>
                                                    <p>
                                                        <strong>Taxa:</strong>
                                                        {agenda.taxa}
                                                    </p>
                                                    <p>
                                                        <strong>Valor:</strong>
                                                        {agenda.valorFormatted}
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Valor Empresa:
                                                        </strong>
                                                        {
                                                            agenda.valorEmpresaFormatted
                                                        }
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Valor total:
                                                        </strong>
                                                        {
                                                            agenda.valorTotalFormatted
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <strong className="ml-2">
                                                        Dias:
                                                    </strong>
                                                    <ul className="ml-2">
                                                        {agenda.dias.map(
                                                            (dia, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="mt-2"
                                                                >
                                                                    {
                                                                        dia.dataFormatted
                                                                    }
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </Box>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

export default Show;
