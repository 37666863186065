import React, { createContext, useContext, useCallback, useState } from 'react';
import { uuid } from 'uuidv4';
import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
    id: string;
    type?: 'success' | 'error' | 'info';
    title: string;
    description?: string;
}
interface ToastContextInterface {
    addToast(message: Omit<ToastMessage, 'id'>): void;
    removeToast(id: string): void;
}
const ToastContext = createContext<ToastContextInterface>(
    {} as ToastContextInterface,
);

export const ToastProvider: React.FC = ({ children }) => {
    const [messages, setMessages] = useState<ToastMessage[]>([]);
    const addToast = useCallback(
        ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
            const id = uuid();
            const toast = {
                id,
                type,
                title,
                description,
            };
            setMessages((state) => [...state, toast]);
        },
        [],
    );
    const removeToast = useCallback((id: string) => {
        setMessages((state) => state.filter((message) => message.id !== id));
    }, []);
    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer messages={messages} />
        </ToastContext.Provider>
    );
};

export function useToast(): ToastContextInterface {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be within a ToastProvider');
    }

    return context;
}
