import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal, Row, Col } from 'react-bootstrap';
import { colors } from '../../../styles/variables';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Loading from '../../../components/Loading';

export interface ModalNewGrupoHandles {
    handleShowModalNewGrupo: () => void;
}

interface ModalProps {
    onClose: () => void;
}

interface Grupo {
    nome: string;
}

const ModalNewGrupo = forwardRef<ModalNewGrupoHandles, ModalProps>((props, ref) => {
    const formRef = useRef<FormHandles>(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToast();

    const handleShowModal = useCallback(() => {
        setShow(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        props.onClose();
        setShow(false);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            handleShowModalNewGrupo: handleShowModal
        };
    });

    const handleSubmit = useCallback(
        async (data: Grupo) => {
            try {
                formRef.current?.setErrors({});

                const grupo = Yup.object().shape({
                    nome: Yup.string()
                        .required('Nome é obrigatório')
                });

                let dataToSend = parseObjectTypes(data, [
                    'nome',
                ]);

                await grupo.validate(dataToSend, { abortEarly: false });

                setLoading(true);

                await api.post('/api/v1/painel/permissao/grupo-acesso', dataToSend,);

                addToast({
                    type: 'success',
                    title: 'Grupo de acesso criado com sucesso!',
                });

                handleCloseModal();

                setLoading(false);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro',
                        description:
                            `Ocorreu um erro ao criar grupo de acesso: ${err}`,
                    });
                }
            }
        },
        [addToast],
    );

    return (
        <Modal show={show} onHide={handleCloseModal} size="sm">
            <Modal.Header closeButton>
                <h5>Inserir</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <span>Carregando...</span>
                    // <Loading />
                ) : (
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Row>
                            <Col md={12}>
                                <Input
                                    name="nome"
                                    type="text"
                                    placeholder="Nome"
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button
                                    type="submit"
                                    color={colors.primary}
                                    textcolor={colors.light}>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
});

export default ModalNewGrupo;
