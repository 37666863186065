import React, { useRef, useCallback, useEffect, useState } from 'react';
import cep from 'cep-promise';
import * as Yup from 'yup';
import { Row, Col, Table } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { format, parseISO, parse } from 'date-fns';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiPhone, FiMapPin, FiCheck } from 'react-icons/fi';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import parseObjectTypes from '../../../../helpers/parseObjectTypes';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import MaskInput from '../../../../components/MaskInput';
import { colors } from '../../../../styles/variables';
import { Container, Card } from './styles';
import Select, { SelectProps } from '../../../../components/SelectInput';
import ReactSelectInput from '../../../../components/ReactSelect';
import ILogStatus from '../../../../interfaces/ILogStatus';

interface IBeneficiario {
    dataNascimento: string;
    nome: string;
    id: number;
}

const FormResponsavel: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [responsavel, setResponsavel] = useState({});
    const { addToast } = useToast();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [unidades, setUnidades] = useState<SelectProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [beneficiarios, setBeneficiarios] = useState<IBeneficiario[]>([]);
    const [logStatus, setLogStatus] = useState<ILogStatus[]>([]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            api.get(`/api/v1/painel/cliente/responsavel/${id}`).then(({ data }) => {

                const tempResponsavel = data.data;
                //console.log(tempResponsavel);
                tempResponsavel.dataNascimento = format(parseISO(tempResponsavel.dataNascimento), 'dd/MM/yyyy');
                tempResponsavel.unidades = tempResponsavel.unidades.map(
                    (ap: { id: number; nome: string }) => ({
                        value: ap.id,
                        label: ap.nome,
                    }),
                );

                setBeneficiarios(tempResponsavel.beneficiarios);

                setResponsavel(tempResponsavel);
                setLogStatus(tempResponsavel.logsStatus);

                console.log(tempResponsavel);
                setLoading(false);
            });
            console.log(responsavel);
        }
    }, [id]);


    const handleCepSearch = useCallback(async (e) => {
        const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
        console.log(formRef, 'e');


        if (cepValue.length === 8) {
            console.log(cepValue);
            //const searchCep = async () => {
            try {
                const response = await cep(cepValue);
                console.log(response);
                if (response) {
                    formRef.current?.setFieldValue(
                        `endereco.logradouro`,
                        response.street,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.bairro`,
                        response.neighborhood,
                    );
                    formRef.current?.setFieldValue(
                        `endereco.localidade`,
                        response.city,
                    );
                    formRef.current?.setFieldValue(`endereco.uf`, response.state);
                }
            } catch (err) {
                switch (err.type) {
                    case "service_error":
                        addToast({
                            type: 'error',
                            title: 'CEP não encontrado',
                        });
                        break;
                    case "validation_error":
                        addToast({
                            type: 'error',
                            title: 'CEP possui um formato inválido',
                        });
                        break;
                    default:
                    // addToast({
                    //     type: 'error',
                    //     title: err,
                    // });
                    //setSearch(err);
                }

                formRef.current?.setFieldValue(
                    `endereco.logradouro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.bairro`, null
                );
                formRef.current?.setFieldValue(
                    `endereco.localidade`, null,
                );
                formRef.current?.setFieldValue(`endereco.uf`, null);
            }
        }
    }, []);

    const handleSubmit = useCallback(
        async (data) => {
            try {
                console.log(data, 'form');
                formRef.current?.setErrors({});

                const enderecoSchema = Yup.object().shape({
                    cep: Yup.string()
                        .required('CEP é obrigatório')
                        .min(8, 'CEP precisa ter pelo menos 8 números')
                        .max(8, 'CEP precisa ter no máximo 8 números'),
                    logradouro: Yup.string().required(
                        'Logradouro é obrigatório',
                    ),
                    complemento: Yup.string(),
                    bairro: Yup.string().required('Bairro é obrigatório'),
                    uf: Yup.string().required('UF é obrigatório'),
                    localidade: Yup.string().required('Cidade é obrigatório'),
                    numero: Yup.number()
                        .typeError('Digite o número')
                        .required('Número é obrigatório'),
                });
                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome é obrigatório'),
                    dataNascimento: Yup.string().required(
                        'Campo obrigatório',
                    ),
                    cpf: Yup.string()
                        .required('CPF é obrigatório')
                        .min(11, 'Mínimo 11 caracteres')
                        .max(14, 'Máximo 14 caracteres'),
                    rg: Yup.string().required('RG é obrigatório'),
                    rgEmissor: Yup.string().required('Emissor é obrigatório'),
                    email: Yup.string().required('Email é obrigatório'),
                    endereco: enderecoSchema,
                    //unidades: Yup.array().required('Unidades é obrigatório'),
                });

                const dataToSend = parseObjectTypes(data, [
                    'nome',
                    'cpf',
                    'rg',
                    'rgEmissor',
                    'endereco',
                    'email',
                    'unidades'
                ]);
                await schema.validate(dataToSend, { abortEarly: false });

                dataToSend.dataNascimento = parse(dataToSend.dataNascimento, 'dd/MM/yyyy', new Date());
                console.log(dataToSend, 'form2');
                if (id) {
                    dataToSend.id = Number(id);
                    await api.put(`/api/v1/painel/cliente/responsavel/${id}`, dataToSend);
                } else {
                    await api.post('/api/v1/painel/cliente/responsavel', dataToSend);
                }

                addToast({
                    type: 'success',
                    title: 'Responsável salvo com sucesso',
                });

                history.push('/clientes/responsaveis');
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    addToast({
                        type: 'error',
                        title: 'Erro ao validar dados',
                        description: 'Verifique o formulário',
                    });

                    formRef.current?.setErrors(errors);
                } else if (err.response) {
                    const { errors } = err.response.data;

                    errors.forEach((erro: string) => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar',
                            description: erro,
                        });
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description:
                            'Ocorreu um erro ao cadastrar o responsável',
                    });
                }
            }
        },
        [addToast, id, history],
    );

    useEffect(() => {
        async function getPreLoad() {
            api.get('api/v1/painel/unidade/ativas').then(({ data }) => {
                setUnidades(
                    data.data.map((item: any) => ({
                        label: item.nome,
                        value: item.id,
                    })),
                );
            });
        }
        getPreLoad();
    }, []);

    return (
        <Container fluid>
            <Breadcrumb
                itens={[
                    {
                        title: 'Responsaveis',
                        link: '/clientes/responsaveis',
                        active: false,
                    },
                    {
                        title: id ? 'Atualizar dados' : 'Novo responsável',
                        link: id
                            ? `/clientes/responsaveis${id}/edit`
                            : '/clientes/responsaveis/new',
                        active: true,
                    },
                ]}
            />
            {!loading && (
                <Form
                    ref={formRef}
                    initialData={responsavel}
                    onSubmit={handleSubmit}
                >
                    <Card>
                        <Card.Header>
                            <h3>
                                {id ? 'Atualizar dados' : 'Cadastro de Responsável'}
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={8}>
                                    <Input
                                        icon={FiUser}
                                        name="nome"
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Input
                                        name="cpf"
                                        type="text"
                                        placeholder="CPF ou CNPJ"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Input name="rg" type="text" placeholder="RG" />
                                </Col>
                                <Col lg={4}>
                                    <Input
                                        name="rgEmissor"
                                        type="text"
                                        placeholder="Emissor"
                                    />
                                </Col>

                                <Col lg={4}>
                                    <MaskInput
                                        mask="99/99/9999"
                                        name="dataNascimento"
                                        type="text"
                                        placeholder="Data de nascimento"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Input
                                        icon={FiMail}
                                        name="email"
                                        type="text"
                                        placeholder="E-mail"
                                    />
                                    <small>
                                        Para informar mais de um e-mail, separe-os ponto e vírgula (;)
                                    </small>
                                </Col>
                                <Col lg={4}>
                                    <MaskInput
                                        mask="(99)99999-9999"
                                        icon={FiPhone}
                                        name="telefone"
                                        type="tel"
                                        placeholder="Telefone"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <MaskInput
                                        mask="(99)99999-9999"
                                        icon={FiPhone}
                                        name="celular"
                                        type="tel"
                                        placeholder="Celular"
                                    />
                                </Col>
                            </Row>
                            <h5 style={{ marginTop: '20px' }}>Endereço</h5>
                            <hr />

                            <Scope path="endereco">
                                <Row>
                                    <Col lg={4}>
                                        <MaskInput
                                            icon={FiMapPin}
                                            name="cep"
                                            type="text"
                                            mask="99999999"
                                            placeholder="Digite seu Cep"
                                            onKeyUp={handleCepSearch}

                                        />
                                        <small>
                                            O endereço será preenchido
                                            automaticamente.
                                        </small>
                                    </Col>
                                    <Col lg={4}>
                                        <Input
                                            name="localidade"
                                            type="text"
                                            placeholder="Cidade"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="uf"
                                            type="text"
                                            placeholder="UF"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={2}>
                                        <Input
                                            name="numero"
                                            type="text"
                                            placeholder="N°"
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="bairro"
                                            type="text"
                                            placeholder="Bairro"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="logradouro"
                                            type="text"
                                            placeholder="Logradouro"
                                            readOnly
                                        />
                                    </Col>

                                    <Col lg={4}>
                                        <Input
                                            name="complemento"
                                            type="text"
                                            placeholder="Complemento"
                                        />
                                    </Col>
                                </Row>
                            </Scope>
                            <Row>
                                <Col lg={12}>
                                    <ReactSelectInput
                                        isMulti
                                        name="unidades"
                                        placeholder="Unidades"
                                        options={unidades}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <h5>Beneficiários</h5>
                                </Col>
                            </Row>
                            {beneficiarios.length > 0 ? (
                                <Row>
                                    <Col md={12}>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Data de Nascimento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {beneficiarios.map(
                                                    (c) => (
                                                        <tr
                                                            key={
                                                                c.id
                                                            }
                                                        >
                                                            <td className="md-10">
                                                                {
                                                                    c.nome
                                                                }
                                                            </td>

                                                            <td
                                                            >
                                                                {
                                                                    format(parseISO(c.dataNascimento), 'dd/MM/yyyy HH:mm')
                                                                }
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            ) : (
                                <h6>
                                    Nenhum Beneficiário encontrado!
                                </h6>
                            )}

                            <hr />
                            <Row>
                                <Col md={4}>
                                <h5>Log Status</h5>
                                </Col>
                            </Row>
                            {logStatus.length > 0 ? (
                                <Row>
                                <Col md={12}>
                                    <Table responsive>
                                    <thead>
                                        <tr>
                                        <th>Ação</th>
                                        <th>Descrição</th>
                                        <th>Funcionário</th>
                                        <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logStatus.map((comen) => (
                                        <tr key={comen.descricao}>
                                            <td>
                                            {comen.desativado
                                                ? 'Ativação'
                                                : 'Desativação'}
                                            </td>
                                            <td>
                                            {comen.descricao}
                                            </td>
                                            <td>
                                            {
                                                comen.nomeFuncionario
                                            }
                                            </td>
                                            <td>
                                            {format(
                                                parseISO(
                                                comen.data,
                                                ),
                                                'dd/MM/yyyy HH:mm',
                                            )}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </Table>
                                </Col>
                                </Row>
                            ) : (
                                <h6>Nenhum log encontrado!</h6>
                            )}

                            <Row style={{ marginTop: 30 }}>
                                <Col md={{ offset: 4, span: 4 }}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                    >
                                        <FiCheck />
                                        Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            )}
        </Container>
    );
};

export default FormResponsavel;
