import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { RiFileExcel2Line } from 'react-icons/ri';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useToast } from '../../../hooks/toast';
import formatValue from '../../../helpers/formatValue';
import Breadcrumb from '../../../components/Breadcrumb';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Pdf } from '../../../components/Pdf';
import { colors } from '../../../styles/variables';
import api from '../../../services/api';
import { Container, Card } from '../../../styles/components';
import AsyncReactSelectInput from '../../../components/AsyncReactSelect';
import { IRelatorio } from '../../../interfaces/IRelatorio';
import ReactSelectInput from '../../../components/ReactSelect';
import Select, { SelectProps } from '../../../components/SelectInput';

const breadcrumbItens = [
    {
        title: 'Listagem',
        link: '/clientes/beneficiarios',
        active: true,
    },
];

interface PreloaProps {
    id: number;
    nome: string;
}

const Relatorio: React.FC = () => {
    const { addToast } = useToast();
    const [relatorios, setRelatorios] = useState<IRelatorio[]>([]);
    const date = format(new Date(), "ddMMyyyyHHmm");
    const [unidadeAux, setUnidadeAux] = useState('');

    const [unidades, setUnidades] = useState<SelectProps[]>([]);

    const handleGetBeneficiarios = useCallback(
        async (event = { unidadeId: '', dtInicio: '', dtFim: '', profissionalId: '', responsavelId: '', status: "" }) => {
            try {
                const { unidadeId, dtInicio, dtFim, profissionalId, responsavelId, status } = event;

                const { data } = await api.get('/api/v1/painel/faturamento/relatorio/comissao', {
                    params: {
                        unidadeId,
                        profissionalId,
                        responsavelId,
                        dtInicio,
                        dtFim,
                        status,
                    }
                });
                console.log(data);
                setRelatorios([]);
                setRelatorios(data.data.map((item: any) => {
                    return {
                        ...item,
                        valorFormatado: formatValue(item.valor),
                    }
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os beneficiários',
                });
            }
        },
        [addToast],
    );

    const handleGetResponsaveis = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/cliente/responsaveis?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.responsaveis.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar responsáveis',
                });
            }
        },
        [addToast],
    );

    const handleGetUnidades = useCallback(async () => {
        try {
            const { data } = await api.get(`api/v1/painel/unidade/usuario`);
            setUnidades(
                data.data.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                })),
            );
        } catch {
            addToast({
                type: 'error',
                title: 'Ooops! Algo deu errado!',
                description: 'Erro ao buscar profissões',
            });
        }
    }, [addToast]);

    const handleGetProfissionais = useCallback(
        async (inputValue: string) => {
            try {
                const { data } = await api.get(
                    `/api/v1/painel/profissional?nome=${inputValue}`,
                );
                const dados = data.data;

                return dados.profissionais.map((item: PreloaProps) => ({
                    label: item.nome,
                    value: item.id,
                }));
            } catch {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar profissinal',
                });
            }
        },
        [addToast],
    );

    useEffect(() => {
        handleGetUnidades();
        handleGetBeneficiarios();
    }, [handleGetUnidades, handleGetBeneficiarios]);

    return (
        <Container fluid>
            <Breadcrumb itens={breadcrumbItens} />
            <Card>
                <Card.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col>
                                <h3>Relatório taxa de agenciamento</h3>
                            </Col>
                        </Row>
                        <Form
                            onSubmit={(e: React.FormEvent) =>
                                handleGetBeneficiarios(e)
                            }
                        >
                            <Row>
                                <Col md={2}>
                                    <Input
                                        name="dtInicio"
                                        type="date"
                                        placeholder="Data de início"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        name="dtFim"
                                        type="date"
                                        placeholder="Data final"
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="profissionalId"
                                        type="text"
                                        placeholder="Profissional"
                                        loadOptions={handleGetProfissionais}
                                        defaultOptions
                                        route="/api/v1/painel/profissional"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando profissional'
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <AsyncReactSelectInput
                                        name="responsavelId"
                                        type="text"
                                        placeholder="Responsável"
                                        loadOptions={handleGetResponsaveis}
                                        defaultOptions
                                        route="/api/v1/painel/cliente/responsavel"
                                        noOptionsMessage={() =>
                                            'Nenhum resultado encontrado'
                                        }
                                        loadingMessage={() =>
                                            'Buscando Responsável'
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactSelectInput
                                        isMulti={false}
                                        hideLabel={false}
                                        name="unidadeId"
                                        placeholder="Unidade"
                                        options={unidades}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Select
                                        defaultOpt="Selecione o status"
                                        name="status"
                                        type="text"
                                        placeholder="Status"
                                        options={[
                                            { label: 'Todos os status', value: "" },
                                            { label: 'Aguardando envio', value: "Aguardando envio" },
                                            { label: 'Enviado', value: "Enviado" },
                                            { label: 'Aguardando Pagamento', value: "Aguardando Pagamento" },
                                            { label: 'Pago', value: "Pago" },
                                            { label: 'Cancelada', value: "Cancelada" },
                                        ]}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        type="submit"
                                        color={colors.primary}
                                        textcolor={colors.light}
                                        style={{ marginTop: '38px' }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                        {relatorios && (
                            <>
                                <PDFDownloadLink
                                    document={<Pdf relatorios={relatorios} />}
                                    fileName={`relatorio-comissao-${date}.pdf`}
                                    className="btn btn-danger"
                                >
                                    {({ loading }) =>
                                        loading
                                            ? 'Carregando...'
                                            : <span className="d-flex align-items-center">
                                                <AiOutlineFilePdf className="mr-2" />PDF
                                            </span>
                                    }
                                </PDFDownloadLink>

                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success ml-2"
                                    table="table-to-comissao"
                                    filename={`relatorio-comissao-${date}`}
                                    sheet="tablexls"
                                    buttonText={
                                        <span className="d-flex align-items-center">
                                            <RiFileExcel2Line className="mr-2" />Excel
                                        </span>
                                    }
                                />
                            </>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table responsive id="table-to-comissao">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {relatorios.map((relatorio) => (
                                <tr key={relatorio.cpf}>
                                    <td>{relatorio.nome}</td>
                                    <td>{relatorio.cpf}</td>
                                    <td>{relatorio.valorFormatado}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Relatorio;
