import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';
import { colors } from '../../../styles/variables';

interface SideBarProps {
    active: boolean;
}

export const Sidebar = styled.nav<SideBarProps>`
    min-width: 250px;
    max-width: 250px;
    background: ${shade(0.2, colors.primary)};
    /* position: fixed;
    z-index: 11; */
    color: #fff;
    transition: all 0.3s;

    min-height: 100vh;

    a[data-toggle='collapse'] {
        position: relative;
    }

    ${(props) =>
        props.active &&
        css`
      margin-left: -250px;
  }
    `}

    @media (max-width: 768px) {
        & {
            margin-left: -250px;
        }

        ${(props) =>
        props.active &&
        css`
      margin-left: 0 !important;
  }
    `}
    }
`;

export const SidebarHeader = styled.div`
    padding: 5px 16px;
    background: ${colors.primary};
    img {
        height: 63px;
        width: auto;
    }
`;

export const SideMenu = styled.ul`
    list-style: none;
    padding: 20px 0;
`;

export const Item = styled.li`
    a, button {
        display: flex;
        background: transparent;
        border: 0;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        font-size: 14px;
        padding: 8px 16px;
        color: ${colors.light};
        text-decoration: none !important;
        font-weight: 500;

        svg {
            margin-right: 32px;
        }

        :hover {
            background: ${colors.primary};

            a > svg {
                color: blue;
            }
        }
    }
`;

export const MenuLink = styled(Link)`
`;
export const LeaveButton = styled.button`
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
        margin-right: 32px;
    }
    font-size: 16px;
    padding: 8px 16px;
    color: ${colors.light};
    text-decoration: none !important;
    font-weight: 500;
    &:hover {
    }
`;
