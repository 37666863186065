import React, { useRef, useCallback, useState } from 'react';
import cep from 'cep-promise';
import { Row, Col, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import {
    FiMail,
    FiUser,
    FiPhone,
    FiMapPin,
    FiTrash,
} from 'react-icons/fi';
import { useHistory } from 'react-router';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from '../../../utils/getValidationErrors';
import parseObjectTypes from '../../../helpers/parseObjectTypes';
import Modal, { ModalHandles } from './ModalAddBeneficiario';
import Button from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import logo from '../../../assets/logo-branca.png';
import IBeneficiario from '../../../interfaces/IBeneficiario';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import { colors } from '../../../styles/variables';
import { Container, Card, Header, ItemList } from './styles';

const CadastroSemLogin: React.FC = () => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const modalRef = useRef<ModalHandles>(null);
    const { addToast } = useToast();
    const [beneficiarios, setBeneficiarios] = useState<IBeneficiario[]>([]);
    console.log(beneficiarios);

    const handleOpenModal = useCallback(() => {
        modalRef.current?.handleShowModal();
    }, []);

    const handleSetBeneficiarios = useCallback((beneficiario: IBeneficiario): void => {
        setBeneficiarios(old => [...old, beneficiario])
    }, []);

    const handleSubmit = useCallback(async (data) => {
        try {
            formRef.current?.setErrors({});

            const enderecoSchema = Yup.object().shape({
                cep: Yup.string()
                    .required('CEP é obrigatório')
                    .min(8, 'CEP precisa ter pelo menos 8 números')
                    .max(8, 'CEP precisa ter no máximo 8 números'),
                logradouro: Yup.string().required(
                    'Logradouro é obrigatório',
                ),
                complemento: Yup.string(),
                bairro: Yup.string().required('Bairro é obrigatório'),
                uf: Yup.string().required('UF é obrigatório'),
                localidade: Yup.string().required('Cidade é obrigatório'),
                numero: Yup.number()
                    .typeError('Digite o número')
                    .required('Número é obrigatório'),
            });
            const schema = Yup.object().shape({
                nome: Yup.string().required('Nome é obrigatório'),
                cpf: Yup.string()
                    .required('CPF é obrigatório')
                    .min(11, 'Mínimo 11 caracteres')
                    .max(14, 'Máximo 14 caracteres'),
                rg: Yup.string().required('RG é obrigatório'),
                rgEmissor: Yup.string().required('Emissor é obrigatório'),
                email: Yup.string().required('Email é obrigatório'),
                endereco: enderecoSchema,
            });

            const dataToSend = parseObjectTypes(data, [
                'nome',
                'cpf',
                'rg',
                'rgEmissor',
                'endereco',
                'email',
            ]);

            await schema.validate(dataToSend, { abortEarly: false });

            if (beneficiarios.length <= 0) {
                addToast({
                    type: 'error',
                    title: 'Adicione beneficiário(s)',
                });
                return;
            }

            await api.post('/api/v1/painel/publico', {
                responsavel: dataToSend,
                beneficiarios: beneficiarios.map(item => {
                    delete item.id;
                    return item;
                }),
            });

            history.push('/agradecimento');

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                addToast({
                    type: 'error',
                    title: 'Erro ao validar dados',
                    description: 'Verifique o formulário',
                });

                formRef.current?.setErrors(errors);
            } else if (err.response) {
                const { errors } = err.response.data;

                errors.forEach((erro: string) => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao cadastrar',
                        description: erro,
                    });
                });
            } else {
                addToast({
                    type: 'error',
                    title: 'Erro ao cadastrar',
                    description:
                        'Ocorreu um erro ao cadastrar o responsável',
                });
            }
        }
    }, [addToast, beneficiarios, history]);

    const handleCepSearch = useCallback(async (e) => {
        const cepValue = e.target.value.replace(/[^_\w\s]/gi, '');
        if (cepValue.length === 8) {
            const response = await cep(cepValue);
            formRef.current?.setFieldValue(
                `endereco.logradouro`,
                response.street,
            );
            formRef.current?.setFieldValue(
                `endereco.bairro`,
                response.neighborhood,
            );
            formRef.current?.setFieldValue(
                `endereco.localidade`,
                response.city,
            );
            formRef.current?.setFieldValue(`endereco.uf`, response.state);
        }
    }, []);

    const handleRemoveBeneficiario = (id: number = 0) => {
        setBeneficiarios((old) => old.filter((item) => item.id !== id));

        addToast({
            type: 'error',
            title: 'Beneficiário removido.',
        });
    };

    return (
        <>
            <Header>
                <img src={logo} alt="Mais Enfermagem" />
            </Header>
            <Container fluid>
                <Row>
                    <Col md={{ span: 10, offset: 1 }}>
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Header>
                                    <h3>
                                        Cadastro
                                    </h3>
                                </Card.Header>
                                <Card.Body>
                                    <h6 style={{ marginTop: '20px' }}>Dados pessoais</h6>
                                    <hr />
                                    <Row>
                                        <Col lg={8}>
                                            <Input
                                                icon={FiUser}
                                                name="nome"
                                                type="text"
                                                placeholder="Nome"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Input
                                                name="cpf"
                                                type="text"
                                                placeholder="CPF ou CNPJ"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Input name="rg" type="text" placeholder="RG" />
                                        </Col>
                                        <Col lg={4}>
                                            <Input
                                                name="rgEmissor"
                                                type="text"
                                                placeholder="Emissor"
                                            />
                                        </Col>

                                        <Col lg={4}>
                                            <Input
                                                name="dataNascimento"
                                                type="date"
                                                placeholder="Data de Nascimento"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Input
                                                icon={FiMail}
                                                name="email"
                                                type="email"
                                                placeholder="E-mail"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <MaskInput
                                                mask="(99)99999-9999"
                                                icon={FiPhone}
                                                name="telefone"
                                                type="tel"
                                                placeholder="Telefone"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <MaskInput
                                                mask="(99)99999-9999"
                                                icon={FiPhone}
                                                name="celular"
                                                type="tel"
                                                placeholder="Celular"
                                            />
                                        </Col>
                                    </Row>
                                    <h6 style={{ marginTop: '20px' }}>Endereço</h6>
                                    <hr />
                                    <Scope path="endereco">
                                        <Row>
                                            <Col lg={4}>
                                                <MaskInput
                                                    icon={FiMapPin}
                                                    name="cep"
                                                    type="text"
                                                    mask="99999999"
                                                    placeholder="Digite seu Cep"
                                                    onChange={handleCepSearch}
                                                />
                                                <small>
                                                    O endereço será preenchido
                                                    automaticamente.
                                                </small>
                                            </Col>
                                            <Col lg={4}>
                                                <Input
                                                    name="localidade"
                                                    type="text"
                                                    placeholder="Cidade"
                                                    readOnly
                                                />
                                            </Col>

                                            <Col lg={2}>
                                                <Input
                                                    name="uf"
                                                    type="text"
                                                    placeholder="UF"
                                                    readOnly
                                                />
                                            </Col>

                                            <Col lg={2}>
                                                <Input
                                                    name="numero"
                                                    type="text"
                                                    placeholder="N°"
                                                />
                                            </Col>

                                            <Col lg={4}>
                                                <Input
                                                    name="bairro"
                                                    type="text"
                                                    placeholder="Bairro"
                                                    readOnly
                                                />
                                            </Col>

                                            <Col lg={4}>
                                                <Input
                                                    name="logradouro"
                                                    type="text"
                                                    placeholder="Logradouro"
                                                    readOnly
                                                />
                                            </Col>

                                            <Col lg={4}>
                                                <Input
                                                    name="complemento"
                                                    type="text"
                                                    placeholder="Complemento"
                                                />
                                            </Col>
                                        </Row>
                                    </Scope>

                                    <Row className="mt-4">
                                        <Col md={10} className="d-flex align-items-center">
                                            <h6>Benefiários</h6>
                                        </Col>
                                        <Col md={2}>
                                            <Button
                                                type="button"
                                                color={colors.primary}
                                                textcolor={colors.light}
                                                onClick={handleOpenModal}
                                            >
                                                Adicionar
                                            </Button>
                                        </Col>

                                        <Col md={12}>
                                            <hr />
                                            {beneficiarios.length > 0 ? (
                                                <ul className="mt-3">
                                                    {beneficiarios.map(beneficiario => (
                                                        <ItemList key={beneficiario.id} className="d-flex align-items-center justify-content-between">
                                                            <span>{beneficiario.nome}</span>

                                                            <Button
                                                                type="submit"
                                                                color={colors.danger}
                                                                onClick={() => handleRemoveBeneficiario(beneficiario.id)}
                                                                textcolor={colors.light}
                                                            >
                                                                <FiTrash />
                                                            </Button>
                                                        </ItemList>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <Alert variant="warning">
                                                    Nenhum beneficiário adicionado.
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={{ span: 2, offset: 5 }}>
                                            <Button
                                                type="submit"
                                                color={colors.primary}
                                                textcolor={colors.light}
                                            >
                                                Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Modal ref={modalRef} onSetBeneficiarios={handleSetBeneficiarios} />
        </>
    );
}

export default CadastroSemLogin;
